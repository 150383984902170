const CONTACT_PAGE_CONSTANTS = {
    pageTitle: 'Contact details',
    EmailAddress: {
        label: 'Email address',
        autocomplete: 'email',
        helpText: 'We\'ll send you a one-time code to this email address to verify it\'s yours.',
        verificationSuccess: 'Your email address has been verified.',
        sendCodeButtonId: 'verify-email-send-code-button',
        errorMessages: {
            verificationRequired: 'Verification required. Please request a code and verify access to your email address.',
            customerValidationStr1: "It looks like you've already provided your contact details some time back, "
                + "but the email you entered doesn't match with the one in our records. Try using a different email address or ",
            customerValidationStr2: ' for help.'
        }
    },
    Address: {
        label: 'Address',
        placeholder: 'Start typing to search',
        tipMessage: 'Just visiting? Enter the address of your accommodation.',
        assistiveText: 'Start typing a physical street address and select it from the list.',
        errorMessages: {}
    },
    SoleTraderCheck: {
        label: 'This service will be for business use'
    },
    BusinessName: {
        label: 'Business name',
        errorMessages: {}
    },
    soleTrader: {
        title: 'Business registration',
        subTitle: `You’ll need a valid ABN or ACN to continue. If you have an ACN, you'll need to be a registered officeholder of the company.`,
        verifyTitle: 'We need to verify your ABN or ACN',
        verifySubTitle: 'We’ll check your ABN or ACN and identifying information with the issuer or official record holder.',
        verifyButtonText: 'Verify my ABN or ACN'
    },
    traderBusinesses: {
        title: 'Choose a business name',
        brief: "Select the business name you'd like to add to your account. If you don't see the business name here, enter a different ABN/ACN."
    },
    businessNameSelect: {
        title: 'Choose a business name',
        brief: "Select the business name you'd like to add to your account. If you don't see the business name here, enter a different ABN/ACN.",
        errorMessages: {
            emptyField: 'Required selection. Please select a business.',
            useDiffAbnAcn: "You've reached the maximum number of services on your business account. Use a different ABN or ACN, or untick the checkbox to activate this service for personal use.",
            selectAnotherBusinessName: " You've reached the maximum number of services for this business. Please select another one."
        }
    },
    businessRegistrationFormFields: ['ABN', 'businessName', 'businessEmail', 'businessPhoneNumber', 'businessAddress'],
    businessContactForm: {
        title: 'Business contact details',
        SameAsPersonalContact: {
            label: 'Same as personal contact details',
            name: 'businessContactSameAsPersonalContact'
        },
        Email: {
            label: 'Email address',
            name: 'businessEmail',
            errorMessages: {
                emptyField: 'Required field. Please enter an email address.',
                invalidField: 'Invalid email domain. Please enter a valid email address.',
                updateFormEmptyMessage: 'Your email address needs to be added.',
                updateFormEmptyError: 'Required. Please add your email address.'
            },
            updateButtonLabel: 'Update email address',
            addButtonLabel: 'Add email address',
        },
        Phone: {
            label: 'Contact number',
            name: 'businessPhoneNumber',
            helpText: 'Any phone number must be in the international format, starting with a plus sign (+) and then the country code. Fixed phone lines should also include area code.',
            errorMessages: {
                emptyField: 'Required field. Enter a mobile or phone number.',
                invalidField: 'Invalid mobile or phone number. Please check the number and try again.',
                updateFormEmptyMessage: 'Your contact number needs to be added.',
                updateFormEmptyError: 'Required. Please add your contact number.'
            },
            updateButtonLabel: 'Update contact number',
            addButtonLabel: 'Add contact number'
        },
        Address: {
            label: 'Address',
            name: 'businessAddress',
            helpText: 'Start typing a physical street address and select it from the list.',
            errorMessages: {
                emptyField: 'Required field. Please search and select an address.',
                nonPhysicalAddress: 'Invalid entry. This must be a physical street address.',
                updateFormEmptyMessage: 'Your address needs to be added.',
                updateFormEmptyError: 'Required. Please add your address.'
            },
            updateButtonLabel: 'Update address',
            addButtonLabel: 'Add address'
        }
    },
    businessSelect: {
        title: 'Business details',
        brief: 'We found multiple accounts in your name. Select which business you’d like to add your new service to.',
        maxedOutServices: {
            oneService: "You've reached the maximum number of services on your business account, so we'll create a new one. Enter your current ABN or ACN if you have more than one business linked to it, or use a different one.",
            multipleService: 'If you don’t see the account name here, you may have reached the maximum number of services for the account.'
        },
        allNonVerifiedAccounts: "If you don't see the business account here, call us on 13 20 00, or create a new account."
    },
    ABN: {
        label: 'ABN or ACN',
        tipMessage: 'An ABN has 11 digits and an ACN has 9 digits.',
        errorMessages: {
            emptyField: 'Required field. Enter your ABN or ACN.',
            invalidField: 'Invalid ABN or ACN. Please check the number and try again.'
        }
    },
    ABNValidation: {
        success: "We've verified your ABN or ACN.",
        SMB_EX_NOTFOUND: 'The ABN or ACN entered is no longer active. Enter an active ABN or ACN to continue.',
        SMB_EX_NOTDIR: "The name on your ID doesn't match the name of a registered officeholder for the company associated with the ACN you entered. Enter a different ACN, or untick the checkbox to activate this service for personal use."
    },
    nonPhysicalAddressErrorMeta: {
        error: 'Invalid entry. This must be a physical street address.',
        touched: true,
        submitFailed: true
    },
    continueButton: {
        label: 'Continue'
    }
};

export default CONTACT_PAGE_CONSTANTS;

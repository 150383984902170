import { TOGGLE_DEBUGGER } from '../../routes/actions';
import { route } from '../../adapters/utils';

export const toggleDebugger = () => {
    return dispatch => {
        dispatch({ type: TOGGLE_DEBUGGER });
    };
};

export const navigate = url => {
    return dispatch => {
        route(url);
        dispatch({ type: TOGGLE_DEBUGGER });
    };
};

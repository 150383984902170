import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { IconButton, TextStyle } from '@able/react';
import SpinningButton from '../../../components/SpinningButton/SpinningButton';
import './editFieldModal.css';
import { focusOnModal, getAbleSpriteSheet } from '../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

function EditFieldModal({
    closeModal = () => { },
    onContinue = () => { },
    isOpen = false,
    shouldCloseOnEsc = true,
    id = '',
    modalTitle = '',
    modalDesc = '',
    children = '',
    loading = false
}) {
    const modalRef = useRef();

    useEffect(() => {
        if (isOpen && modalRef && modalRef.current) focusOnModal(modalRef, 0);
    }, [isOpen]);

    return (
        <Modal
            ref={modalRef}
            parentSelector={() => document.querySelector('#modal-ppv')}
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            className="modal-dialog modal-lg ppv-modal"
            onRequestClose={closeModal}
            contentLabel={modalTitle}
            aria={{ labelledby: id, modal: 'true', role: 'dialog' }}
            shouldCloseOnEsc={shouldCloseOnEsc}
        >
            <ContentWrapper
                id={id}
                title={modalTitle}
                modalDesc={modalDesc}
                onClose={closeModal}
            >
                {children}
                <div className="update-modal-footer mt-4">
                    <SpinningButton
                        isDisabled={loading}
                        isLoading={loading}
                        onSubmit={onContinue}
                        buttonText="Continue" />
                </div>
            </ContentWrapper>
        </Modal>
    );
}

function ContentWrapper({
    onClose,
    children = '',
    title = '',
    modalDesc,
    id = ''
}) {
    return (
        <div className="modal-content skip-recharge-content update-modal-content update-field-modal-content">
            <div className="modal-header-no-border ppv-header update-field-modal-header">
                <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onClose} />
            </div>
            <div className="px-5 pb-5">
                <div tabIndex="-1" className="update-field-title modal-heading mb-4" id={id}>
                    <TextStyle element="h2" alias="HeadingB">{title}</TextStyle>
                    {modalDesc && <TextStyle alias="TextBodyShort" className="mt-2">{modalDesc}</TextStyle>}
                </div>
                {children}
            </div>
        </div>
    );
}

export default EditFieldModal;

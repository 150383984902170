import { INCREASE_PROGRESS_BAR_STEP, DECREASE_PROGRESS_BAR_STEP, UPDATE_PROGRESS_BAR_STEP_CUSTOM_DATA } from '../../routes/actions';
import { getCurrentStepper } from '../../adapters/store-utils';

// Intial State
const initialState = {
    currentStep: 1,
    totalSteps: 7,
    isLastStepCompleted: true
};

// Reducer
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case INCREASE_PROGRESS_BAR_STEP:
            return {
                ...state,
                ...action.data
            };
        case DECREASE_PROGRESS_BAR_STEP:
            return {
                ...state,
                ...action.data
            };
        case UPDATE_PROGRESS_BAR_STEP_CUSTOM_DATA:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default reducer;

// Call this to Increase step in Steeeper Bar
export const increaseStepper = () => {
    const state = getCurrentStepper();
    const updatedStep = state.currentStep === state.totalSteps ? { ...state, isLastStepCompleted: true } : { ...state, currentStep: state.currentStep + 1 };
    return {
        type: INCREASE_PROGRESS_BAR_STEP,
        data: { ...updatedStep }
    };
};

// Call this to Decrease step in Progress Bar
export const decreaseStepper = () => {
    const state = getCurrentStepper();
    const updatedStep = state.currentStep === state.totalSteps ? { ...state, isLastStepCompleted: false, currentStep: state.currentStep - 1 } : { ...state, currentStep: state.currentStep - 1 };

    return {
        type: DECREASE_PROGRESS_BAR_STEP,
        data: { ...updatedStep }
    };
};

// Call this to Update step in Progress Bar
export const updateStepperCustomData = (data) => {
    return {
        type: UPDATE_PROGRESS_BAR_STEP_CUSTOM_DATA,
        data: { ...data }
    };
};

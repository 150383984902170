import React, { Component } from 'react';
import CancelModal from './modal';
import { getUiState } from '../../adapters/store-utils';
import { sumbitCancelPayloadToDevice } from '../../adapters/esim-utils';
import './cancel-activation.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false };
    }
    componentDidUpdate() {
        const modalHeading = document.querySelector('.modal-title.grey.sub-heading');
        if (modalHeading) {
            modalHeading.focus();
        }
    }
    render() {
        const { loading: { appLoading } } = this.props;
        if (appLoading) return false;

        const toggleModal = e => {
            e.preventDefault();
            const { isEsimActivation = false } = getUiState();

            if (isEsimActivation) {
                sumbitCancelPayloadToDevice();
            } else {
                this.setState({ modalOpen: !this.state.modalOpen });
            }
        };
        const { cancelActivation = {} } = this.props.locale || {};

        return (
            <span>
                {
                    <button className="button-as-link cancel-activation-link" onClick={toggleModal}>
                        {cancelActivation.cancelBtnText}
                    </button>
                }
                <CancelModal toggleModal={toggleModal} modalOpen={this.state.modalOpen} {...this.props} />
            </span>
        );
    }
}

export default Footer;

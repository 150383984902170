import React, { useEffect } from 'react';
import classNames from 'classnames';
import { MessageSection } from '@able/react';
import './globalBanner.css';
import { getAbleSpriteSheet, isOutageWindow } from '../../../adapters/utils';
const ableSpriteSheet = getAbleSpriteSheet();

const GlobalBanner = (props) => {
    const {
        //landing page
        PPA_INFORMATION_NOTIFICATION_DISPLAY = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE = '',
        PPA_INFORMATION_NOTIFICATION_TITLE = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT = '',
        PPA_INFORMATION_NOTIFICATION_LINK = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT = '',
        //idv
        PPA_INFORMATION_NOTIFICATION_DISPLAY_IDV = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_IDV = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_IDV = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_IDV = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_IDV = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_IDV = '',
        PPA_INFORMATION_NOTIFICATION_LINK_IDV = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_IDV = '',
        //contact details
        PPA_INFORMATION_NOTIFICATION_DISPLAY_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_LINK_CONTACT_DETAILS = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_CONTACT_DETAILS = '',
        //recharge
        PPA_INFORMATION_NOTIFICATION_DISPLAY_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_LINK_RECHARGE = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_RECHARGE = '',
        //payment
        PPA_INFORMATION_NOTIFICATION_DISPLAY_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_LINK_PAYMENT = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_PAYMENT = '',

        //success
        PPA_INFORMATION_NOTIFICATION_DISPLAY_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_LINK_SUCCESS = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_SUCCESS = '',

        //Review Details
        PPA_INFORMATION_NOTIFICATION_DISPLAY_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_LINK_REVIEW = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_REVIEW = '',
        //porting
        PPA_INFORMATION_NOTIFICATION_DISPLAY_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_TITLE_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_MESSAGE_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_START_TIME_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_END_TIME_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_VARIANT_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_LINK_PORTING = '',
        PPA_INFORMATION_NOTIFICATION_LINK_TEXT_PORTING = '',


    } = window.appConfig || {};

    const { onPage } = props || '';

    let informationalDisplay = false, informationMessage = '', bannerVariant = '', bannerTitle = '', additionalLink = '', linkText = '';

    if (PPA_INFORMATION_NOTIFICATION_DISPLAY === 'true' && onPage === 'landing') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME, PPA_INFORMATION_NOTIFICATION_END_TIME);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT;
    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_IDV === 'true' && onPage === 'idv') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_IDV, PPA_INFORMATION_NOTIFICATION_END_TIME_IDV);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_IDV;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_IDV;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_IDV;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_IDV;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_IDV;

    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_CONTACT_DETAILS === 'true' && onPage === 'contactdetails') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_CONTACT_DETAILS, PPA_INFORMATION_NOTIFICATION_END_TIME_CONTACT_DETAILS);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_CONTACT_DETAILS;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_CONTACT_DETAILS;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_CONTACT_DETAILS;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_CONTACT_DETAILS;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_CONTACT_DETAILS;
    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_RECHARGE === 'true' && onPage === 'recharge') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_RECHARGE, PPA_INFORMATION_NOTIFICATION_END_TIME_RECHARGE);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_RECHARGE;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_RECHARGE;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_RECHARGE;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_RECHARGE;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_RECHARGE;
    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_PAYMENT === 'true' && onPage === 'payments') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_PAYMENT, PPA_INFORMATION_NOTIFICATION_END_TIME_PAYMENT);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_PAYMENT;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_PAYMENT;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_PAYMENT;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_PAYMENT;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_PAYMENT;
    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_SUCCESS === 'true' && onPage === 'success') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_SUCCESS, PPA_INFORMATION_NOTIFICATION_END_TIME_SUCCESS);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_SUCCESS;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_SUCCESS;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_SUCCESS;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_SUCCESS;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_SUCCESS;
    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_REVIEW === 'true' && onPage === 'review') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_REVIEW, PPA_INFORMATION_NOTIFICATION_END_TIME_REVIEW);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_REVIEW;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_REVIEW;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_REVIEW;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_REVIEW;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_REVIEW;
    }
    else if (PPA_INFORMATION_NOTIFICATION_DISPLAY_PORTING === 'true' && onPage === 'portingflow') {
        informationalDisplay = isOutageWindow(PPA_INFORMATION_NOTIFICATION_START_TIME_PORTING, PPA_INFORMATION_NOTIFICATION_END_TIME_PORTING);
        informationMessage = PPA_INFORMATION_NOTIFICATION_MESSAGE_PORTING;
        bannerVariant = PPA_INFORMATION_NOTIFICATION_VARIANT_PORTING;
        bannerTitle = PPA_INFORMATION_NOTIFICATION_TITLE_PORTING;
        additionalLink = PPA_INFORMATION_NOTIFICATION_LINK_PORTING;
        linkText = PPA_INFORMATION_NOTIFICATION_LINK_TEXT_PORTING;
    }

    const actionId = 'globalBannerLink_' + onPage;
    useEffect(() => {
        const link = document.getElementById(actionId);
        if (link) link.setAttribute('target', '_blank');
    }, []);

    return informationalDisplay ? (
        <div id='globalMessageSection' className={classNames([props.className, 'row'])}>
            <div className='col-lg-5'>
                <MessageSection
                    titleText={bannerTitle}
                    description={informationMessage}
                    variant={bannerVariant || 'Attention'} //attention, warning, error, success, information
                    developmentUrl={ableSpriteSheet}
                    className='messageSection'
                    actionId={actionId}
                    actionLink={additionalLink}
                    actionText={linkText}
                />
            </div>
        </div>
    ) : '';
}

export default GlobalBanner;
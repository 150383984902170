import * as React from 'react';
import { generateUUID } from '../adapters/utils';
class Usabilla extends React.Component {
    componentDidMount() {
        this.loadUsabilla();
    }

    loadUsabilla() {
        if (window.usabilla_live) {
            const ppaUuid = generateUUID();
            window.usabilla_live('data', {
                'custom': {
                    ppaUuid
                }
            });
        } else {
            setTimeout(() => { this.loadUsabilla(); }, 2000);
        }
    }
    render() {
        return null;
    }
}

export default Usabilla;

import { isEmpty } from './validations';
import { getAppData } from './store-utils';
import { DEEPLINKS } from './constants';

export const sumbitCancelPayloadToDevice = () => {
    const { esimData: { transactionId, iccid } = {} } = getAppData();
    const purchaseResult = {
        userAccount: 'Bailed',
        purchaseInstrument: 'None',
        line: 'None',
        moDirectStatus: 'None',
        planName: ''
    };

    const notificationPayload = {
        ver: '1',
        success: false,
        activationCode: ''
    };

    if (!isEmpty(transactionId)) notificationPayload.transactionId = transactionId;
    if (!isEmpty(iccid)) notificationPayload.iccid = iccid;

    notificationPayload.purchaseResult = JSON.stringify(purchaseResult);
    const activationPayload = JSON.stringify(notificationPayload);
    notifyDevice(activationPayload);
};

export const sumbitSuccessPayloadToDevice = activationCode => {
    const { esimData: { transactionId, iccid } = {} } = getAppData();
    const purchaseResult = {
        userAccount: 'New',
        purchaseInstrument: 'New',
        line: 'New',
        moDirectStatus: 'Complete',
        planName: 'MyPlan'
    };

    const notificationPayload = {
        ver: '1',
        success: true,
        activationCode
    };

    if (!isEmpty(transactionId)) notificationPayload.transactionId = transactionId;
    if (!isEmpty(iccid)) notificationPayload.iccid = iccid;

    notificationPayload.purchaseResult = JSON.stringify(purchaseResult);
    const activationPayload = JSON.stringify(notificationPayload);

    notifyDevice(activationPayload);
};

export const notifyDevice = payload => {
    try {
        //eslint-disable-next-line
        DataMart.notifyPurchaseResult(payload);
    } catch (e) {
        //eslint-disable-next-line
        console.log(e);
    }
};

export const redirectToMyBoost = () => {
    const { esimData: { deepLinks = {} } = {} } = getAppData();
    let redirectUrl = deepLinks.error;

    // if retrieve-params call failed, we will use redirection url stored in FE constants
    if (!redirectUrl) {
        const esimSource = window.localStorage.getItem('source');
        const { esimData: { model, source } = {} } = getAppData();

        const iphoneStr = 'iphone';
        const isIphone = (
            (esimSource && esimSource.toLowerCase().includes(iphoneStr)) ||
            (source && source.toLowerCase().includes(iphoneStr)) ||
            (model && model.toLowerCase().includes(iphoneStr))
        );
        const env = window.appConfig.PPA_ENV_MODE;

        if (isIphone) {
            redirectUrl = env === 'prod' || env === 'staging' ? DEEPLINKS.IPHONE_PROD_ERROR : DEEPLINKS.IPHONE_DEV_ERROR;
        } else {
            redirectUrl = DEEPLINKS.ANDROID_ERROR;
        }
    }

    window.localStorage.removeItem('source');
    window.localStorage.removeItem('esimFlow');
    window.location.href = redirectUrl;
    return null;
};

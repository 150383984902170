import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AutoComplete } from "../../../../components";
import { getAddress } from "../../module";

export function View(props) {

    function getAddressList(input) {
        return new Promise(resolve => {
            if (!input) {
                return resolve([]);
            }
            const { actions: { getAddress } = {} } = props;
            getAddress(input).then((response) => {
                if (!response || !response.data) {
                    resolve([]);
                    return;
                }
                const address = [];
                response.data.results.forEach((value) => {
                    address.push({
                        label: value.name,
                        value: value.name
                    });
                });
                resolve(address);
            });
        });
    }

    return <AutoComplete
        selectedValue={props.input.value || { label: '', value: '' }}
        able {...props} loadOptions={getAddressList} />;
}

export const AddressAutoComplete = connect(undefined, dispatch => ({
    actions: bindActionCreators({ getAddress }, dispatch)
}))(View);

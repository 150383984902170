/* eslint-disable max-len */
const CONTACT_DETAILS_LOCALE = {
    pageBlockImage: '/img/contact-details.png',
    pageTitle: 'Contact details',
    emailIDElement: {
        label: 'Your email address',
        placeholder: 'Enter your email address',
        emptyError: 'This field is required',
        invalidError: 'Invalid email address'
    },
    nextButtonElement: {
        label: 'Next'
    },
    liveChat: {
        label: 'Live Chat',
        section: ['telstra', 'dtq', 'prepaid']
    },
    customerMatchingErrors: {
        serviceFailureError:
            'We’re experiencing technical difficulties progressing to the next step, but we’re working on it.  Please try again later or contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Details:Technical:Error” target=”_blank”>24x7 Chat</a>',
        multipleCustomersError: 'Multiple customers found'
    },
    validationError: 'There is an error in one or more of the fields above. Please check and try again.',
    createCartError: 'We are seeing Technical difficulties while creating cart with Dxp',
    addressField: {
        error: {
            generic: {
                service: 'We’re still experiencing difficulties recognising your address. Please contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Address:Max:Error” target=”_blank”>24x7 Chat</a>',
                assistance: 'We can’t seem to find your address. Please try again.',
                addressNotFound: 'If your address isn’t appearing, please contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Address:Found:Error” target=”_blank”>24x7 Chat</a>'
            },
            validation: {
                required: 'This field is required',
                pattern: 'Your address cannot include the following characters: < (less than), > (greater than), # (hash), & (ampersand), &quot; (double quote). Please remove these and try again.'
            },
            validatecustomer: {
                nameBlacklisted: 'The combination of the first and last name entered cannot be used. To continue, review and edit the details you’ve provided before trying again.',
                abnacnBlacklisted: 'This ABN or ACN cannot be used. To continue, review and edit the details you’ve provided before trying again. ',
                multipleCustomersFound:
                    'We’re having difficulty progressing with your activation as we think you currently have a service with us, or have had one in the past.  To continue, change your selection to indicate you’ve had a service with us, or contact <24x7 Chat>.   ',
                notAuthorized:
                    'The authorised representative details we have for the business name you’ve entered don’t match our records. Please ensure you’re an authorised representative before trying again, or contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Authorised:User:Error” target=”_blank”>24x7 Chat</a>',
                serverError:
                    'We’re experiencing technical difficulties progressing to the next step, but we’re working on it. Please try again later or contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Address:Technical:Error” target=”_blank”>24x7 Chat</a>',
                customerNotFoundError:
                    'We’re having difficulties finding you. For assistance, please chat online to a consultant <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Address:Technical:Error” target=”_blank”>24x7 Chat</a>'
            }
        },
        label: 'Your address ',
        placeholder: 'e.g. 10 Sample Street, Sampletown, VIC 3000'
    },
    addressList: {
        label: 'Please confirm address',
        placeholder: 'Please select an address',
        notListedOption: 'My address is not listed above'
    },
    confirmAddressButton: {
        label: 'Confirm Address'
    },
    visitorMessage: "If you're just visiting Australia, enter the address where you are staying.",
    fullNameLabel: 'Name',
    dobLabel: 'Date of birth',
    personalAddressLabel: 'Address',
    accountTypeLabel: 'Account'
};
export default CONTACT_DETAILS_LOCALE;

import './welcome.css';
import React, { Component } from 'react';
import { Loader, AppDisabled } from '../../components/';
import ServiceDetails from './ServiceDetails/servicedetails';
import { isEmpty } from '../../adapters/validations';
import { focusOnHeading, focusOnProgress } from '../../adapters/utils';
import { WELCOME_PAGE_TITLE } from '../../adapters/constants';
import WELCOME_LOCALE from '../../assets/locale/v1/welcome';
import { saveStateAndLoginWelcome } from '../../adapters/authentication-utils';
import $ from 'jquery';
import FullPageError from '../../components/FullPageError/FullPageError';
import { GlobalMessage } from './GlobalMessage';

class Welcome extends Component {
    /**
     * @param {*} props
     * @memberof Welcome
     */
    constructor(props) {
        super(props);
        this.state = {
            showServiceDetails: false,
            hasError: false
        };
        this.UpdateError = this.UpdateError.bind(this);
    }
    /**
     * when the component is mounted fetch AEM data
     * AND
     * if the customer logged in flag from is set to true, we want to
     * fetch the existing customer data at this stage.
     *
     * @memberof Welcome
     */
    componentWillMount() {
        const {
            loading: { isModuleLoaded }
        } = this.props;
        const {
            appData: {
                uiState: { isEsimActivation, isCaimanLogin, isEsimAuth }
            }
        } = this.props;
        if (!isModuleLoaded) {
            this.props.actions.fetchWelcomeLocale();
        }
        if (isEsimActivation && !isCaimanLogin && isEsimAuth) {
            saveStateAndLoginWelcome();
        }
    }

    componentDidMount() {
        document.title = WELCOME_PAGE_TITLE;
        const {
            appData: {
                uiState: { isEsimActivation }
            }
        } = this.props;
        focusOnHeading();
        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: 1,
            isLastStepCompleted: false
        };
        this.props.actions.updateStepperCustomData(stepperData);

        // Hide usabilla for eSIM activation
        if (isEsimActivation) {
            window.usabilla_live("hide");
        }
    }

    /**
     * Redirects to manage service page when PPA launched with iccid as parameter
     * @param {any} props
     */
    componentWillReceiveProps(props) {
        const {
            appData: { esimData: { iccid = '' } = {} },
            errors
        } = props;
        const {
            locale: { commonContent: { esimManageServicePage = '' } = {} }
        } = props;
        if (!isEmpty(iccid) && !isEmpty(esimManageServicePage)) {
            window.location = `${esimManageServicePage}?iccid=${iccid}`;
        }
        if (!errors.hasError) {
            this.setState({ hasError: false });
        }
        const elementView = document.getElementById('chkSimBtn2');
        if (elementView !== null) {
            elementView.scrollIntoView(true);
            $('#activationImage').on('load', () => {
                document.querySelector('body').scrollTop = elementView.offsetTop;
            });
        }
    }

    componentDidUpdate() {
        const {
            validations: { isNumberChecked },
            appData: {
                uiState: { selectedActivationFlow }
            }
        } = this.props;

        if (isNumberChecked && !selectedActivationFlow) {
            document.getElementById('radio-grp-title')?.focus();
        }

        if (this.state.hasError) {
            document.getElementById('server-error-description')?.focus();
        }
    }

    /**
     * Lock field state on unmount
     *
     * @param {any} props
     * @memberof CustomerDetails
     */

    render() {
        const {
            appData,
            app: { hasServiceError },
            loading: { appLoading, lockFields },
            appData: {
                esimData: { customerAccountId, eSIMhealthCheckFailed },
                uiState: { isEsimAuth }
            }
        } = this.props;

        // global
        if (appLoading) return <Loader />;

        const { locale: { disableApp = {} } = {} } = this.props;
        if (disableApp.enabled) return <AppDisabled modalOpen />;

        // errors
        const { errors } = this.props;
        // props
        const { deviceData, validations, showPortingModal, app } = this.props;
        const { msisdn } = appData;
        // actions
        const { actions } = this.props;

        if (this.state.showServiceDetails) {
            focusOnProgress();
        }

        // Fix for reload esim issue
        let reloadedEsimFlow = false;
        const isEsimFlow = window.localStorage.getItem('esimFlow');
        if (window.performance && isEsimFlow) {
            if (window.performance.navigation.type === 1) {
                reloadedEsimFlow = true;
            } else {
                console.log('page not refreshed');
            }
        }
        let errorProps = {};
        if (hasServiceError) {
            errorProps = {
                errorMessage: 'You’ve reached the maximum number of Pre-Paid services on your account.',
                errorMessage1: 'Currently we can only support the activation of 35 services on a single account.',
                errorText: 'Unable to process request',
                isEsimFlow
            };
        } else {
            errorProps = {
                errorText: 'Something went wrong',
                errorMessage: 'We’re unable to process the request at this time.',
                isEsimFlow
            };
        }


        return (
            <div className="container ppa-block mbxxl">
                {(reloadedEsimFlow || hasServiceError) ? <FullPageError {...errorProps} /> : (
                    (!eSIMhealthCheckFailed && ((isEsimAuth && customerAccountId) || !isEmpty(msisdn) || !isEsimAuth)) &&
                    <React.Fragment>
                        <GlobalMessage />
                        <ServiceDetails
                            appData={appData}
                            app={app}
                            lockFields={lockFields}
                            actions={actions}
                            {...WELCOME_LOCALE.serviceDetails}
                            validations={validations}
                            errors={errors}
                            deviceData={deviceData}
                            showPortingModal={showPortingModal}
                            UpdateError={this.UpdateError}
                            validationError={this.state.hasError}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }

    UpdateError() {
        this.setState({ hasError: true });
    }

}

export default Welcome;

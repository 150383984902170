import React from 'react';
import './FullPageError.css';
import { showWebMessenger, focusOnHeading } from '../../adapters/utils';
import { redirectToMyBoost } from '../../adapters/esim-utils';
import { isEmpty } from '../../adapters/validations';
import { ActionButton, TextStyle } from '@able/react';
import Parser from 'html-react-parser';
import defaultImgSrc from '../../assets/img/svg/something_went_wrong.svg';

const errorHeader = 'Something went wrong';
const deafultError1 = `We’re unable to process your request at this time. 
                       Please try again later.`;
const deafultError2 = '';

/**
 * Displays error messages
 * boldText prop adds 'strong' to class name to get bold effect.
 * @param {any} props
 */
const FullPageError = ({ boldText = false, isSoftStopError, ...props }) => {
    if (isEmpty(props.errorText)) return false;
    if (!isSoftStopError) {
        document.title = props.errorText;
    }
    focusOnHeading();
    return (
        <div className={'fullpage-error-container'}>
            <div aria-live="polite" aria-atomic="true" id="liveRegion">
                <img className="fullpage-error-img img-fluid" alt="Error" src={`${props.errorImg ? props.errorImg : defaultImgSrc}`} />
                <TextStyle tabIndex="-1" alias="HeadingA" element="h1" aria-describedby="liveRegion" className="error-header my-4">
                    {props.errorText || errorHeader}
                </TextStyle>
                <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{Parser(props.errorMessage || deafultError1)}</TextStyle>
                <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{props.errorMessage1 || deafultError2}</TextStyle>
                {props.children}
            </div>
            {!isSoftStopError && (props.isEsimActivation || props.isEsimFlow) ? (
                <ActionButton element="button" className="boost-able-button mt-4 startover-button" label="Start over" variant="HighEmphasis" events={{ onClick: redirectToMyBoost }} />
            ) : ''}
            {props.context && (
                <div className="chat-wrapper">
                    <p className="need-help">Need help?</p>
                    <ActionButton element="button" className="boost-able-button" variant="LowEmphasis" onClick={() => showWebMessenger(props.context || '')} label="Message Us" />
                </div>
            )}
        </div>
    );
};

export default FullPageError;

import React, { useState } from 'react';
import BirthDate from '../../../../components/BirthDate/BirthDate';

function EditDOB({
    modalFooter = () => { },
    input: {
        label, name: inputName, key: inputKey, validate, autocomplete
    } = {}
}) {

    const [DOB, setDOB] = useState({ value: '', error: '' });

    const onChangeDOB = (value) => {
        const newDOB = DOB;
        newDOB.value = value;
        if (DOB.touched) {
            newDOB.error = validate(DOB.value);
        }
        setDOB({ ...newDOB });
    }

    const validateDOB = ({ focus } = {}) => {
        const error = validate(DOB.value);
        setDOB({
            ...DOB,
            touched: true,
            error,
            isValid: !error
        });

        if (focus) {
            const input = document.querySelector(`input#${inputName}`);
            if (input) input.focus();
        }

        return {
            isValid: !error, error, data: {
                value: DOB.value,
                key: inputKey
            }
        };
    };

    const onBlur = (value) => {
        onChangeDOB(value);
        setTimeout(validateDOB, 100);
    }

    return (
        <React.Fragment>
            <div className="mb-4">
                <BirthDate
                    wrapperClassName='edit-dob able-TextField'
                    label={label}
                    required
                    meta={{
                        touched: DOB.touched,
                        error: DOB.error
                    }}
                    input={{
                        name: inputName,
                        value: DOB.value,
                        onChange: onChangeDOB,
                        onBlur: onBlur,
                        'aria-invalid': !!DOB.error,
                        autocomplete: autocomplete
                    }}
                />
            </div>
            {modalFooter(() => validateDOB({ focus: true }))}
        </React.Fragment>
    );
}

export default EditDOB; 
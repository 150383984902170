import React from 'react';
import { ActionButton, IconButton, TextStyle } from '@able/react';
import SomethingWentWrong from '../../../../assets/img/duplicate-sim.png';
import { getAbleSpriteSheet } from '../../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

export function FullModalError({ onCloseModal, errorContent: {
    errorText,
    errorHeading,
    errorTextLink
} = {} }) {
    return (
        <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content">
            <div className="modal-header-no-border ppv-header update-contact-modal-header">
                <img className="something-went-wrong-img" alt="error" src={SomethingWentWrong} />
                <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onCloseModal} />

            </div>
            <div tabIndex="-1" className="skip-recharge-content-container full-modal-error-content modal-heading" id="otc-maxedout">
                {errorHeading && (
                    <TextStyle tabIndex="-1" element="h1" alias="HeadingA">
                        {errorHeading}
                    </TextStyle>
                )}
                {errorText && (
                    <TextStyle alias="TextBodyShort" className="error-modal-text">
                        {errorText}
                    </TextStyle>
                )}
                {errorTextLink && (
                    <ActionButton
                        developmentUrl={ableSpriteSheet}
                        element="button"
                        label={errorTextLink}
                        variant="LowEmphasis"
                        onClick={onCloseModal}
                        className="update-inline boost-able-button" />
                )}
            </div>
        </div>
    );
}

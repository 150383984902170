import View from './session-timeout';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return {
        locale: state.welcome.locale,
        loading: state.welcome.loading,
        appData: state.app.appData
    };
}

export function mapDispatchToProps() {
    return {};
}

const SessionTimeout = connect(mapStateToProps, mapDispatchToProps)(View);

export default SessionTimeout;

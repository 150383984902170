import axios from 'axios';

const loadConfig = async () => {
    const response = await axios.get('/app-config.json');

    try {
        return response.data;
    } catch (error) {
        // Console.error('Could not load config.json!!');
        return Promise.reject('Could not load telstra-app-config.json!!');
    }
};

export default loadConfig;

import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';
import Logger from '../../components/TelstraAuthLogin/logger';

import {
    LOCK_REVIEW_DETAILS_STATE,
    REVIEW_DETAILS_INIT_DONE,
    REVIEW_DETAILS_INIT_ERROR,
    SERVICE_ACTIVATION_ERROR,
    SERVICE_ACTIVATION_INIT,
    SERVICE_ACTIVATION_SUCCESS,
    CART_SUBMISSION_INIT,
    CART_SUBMISSION_SUCCESS,
    CART_SUBMISSION_ERROR
} from '../actions';

Logger.init(null, 'INFO', '[PREPAID_ACTIVATION]');
// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false, activationInProgress: false, formProcessed: false, lockFields: false },
    locale: {},
    errors: { hasError: false, errorCode: null, hasServerError: false },
    activationData: {}
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case REVIEW_DETAILS_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true },
                locale: action.data
            };
        case REVIEW_DETAILS_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case SERVICE_ACTIVATION_INIT:
            return {
                ...state,
                loading: { ...state.loading, activationInProgress: true }
            };
        case SERVICE_ACTIVATION_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, activationInProgress: false },
                activationData: action.data
            };
        case CART_SUBMISSION_INIT:
            return {
                ...state,
                loading: { ...state.loading, activationInProgress: true }
            };
        case CART_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, activationInProgress: false },
                activationData: {
                    ...state.activationData,
                    activationDataDetails: { customerInteractionId: action.data.id, orderId: action.data.externalId, isAutoRechargeSuccessful: action.data.autoRechargeSuccessful }
                }
            };
        case CART_SUBMISSION_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === errorCodeMeta.SERVER_ERROR },
                loading: { ...state.loading, activationInProgress: false, lockFields: action.errorCode === errorCodeMeta.SERVER_ERROR }
            };
        case SERVICE_ACTIVATION_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === errorCodeMeta.SERVER_ERROR },
                loading: { ...state.loading, activationInProgress: false, lockFields: action.errorCode === errorCodeMeta.SERVER_ERROR }
            };
        case LOCK_REVIEW_DETAILS_STATE:
            return {
                ...state,
                loading: { ...state.loading, lockFields: true }
            };

        default:
            return state;
    }
};

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'reviewdetails';
    return (dispatch) => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = (data) => {
    return { type: REVIEW_DETAILS_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: REVIEW_DETAILS_INIT_ERROR, data: httpStatus };
}

/**
 * Lock persisting form state
 *
 * @export
 * @returns
 */
export function lockState() {
    return { type: LOCK_REVIEW_DETAILS_STATE };
}

import React from 'react';
import './SpinningButton.css';
import AbleActionButton from '@mytelstra/mt-design-system/lib/AbleActionButton';
import Button from '@mytelstra/mt-design-system/lib/Button';
import Spinner from '@mytelstra/mt-design-system/lib/Spinner';

export const SpinningButton = ({ ...props }) => {
    return (
        <div className="SpinningButton-container">
            {props.onSubmit && (
                <AbleActionButton
                    preventOmniture
                    variant={props.variant ? props.variant : 'HighEmphasis'}
                    className={props.stylingClass}
                    id={props.id !== undefined ? props.id : 'spinning-able-button'}
                    type={props.type !== undefined ? props.type : 'button'}
                    onClick={props.onSubmit ? props.onSubmit : null}
                    disabled={props.isDisabled}
                >
                    {props.isLoading && props.isDisabled ? <Spinner size="small" label="loading" /> : props.buttonText}
                </AbleActionButton>
            )}
            {!props.onSubmit && (
                <Button
                    preventOmniture
                    variant={props.variant ? props.variant : 'HighEmphasis'}
                    className={props.stylingClass}
                    id={props.id !== undefined ? props.id : 'spinning-able-button'}
                    type={props.type !== undefined ? props.type : 'button'}
                    disabled={props.isDisabled}
                >
                    {props.isLoading && props.isDisabled ? <Spinner size="small" label="loading" /> : props.buttonText}
                </Button>
            )}
        </div>
    );
};

export default SpinningButton;

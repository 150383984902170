import React, { useEffect } from 'react';
import { TextStyle } from '@able/react';
import { Field, getFormValues } from 'redux-form';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import { AbleTextField } from '../../../../components/form/AbleTextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AbleCheckBox } from '../../../../components/form/AbleCheckBox';
import { AddressAutoComplete } from '../addressAutoComplete/addressAutoComplete';
import { NONPHYSICAL_ADDRESS_ERROR } from '../../../../adapters/errorCode';
import { resetVerifyBusinessMeta } from '../../module';
import ReadOnlyField from '../readonlyField/readOnlyField';

const { businessContactForm } = CONTACT_PAGE_CONSTANTS;

export function BusinessContactDetailsForm({
    formData = {},
    disabled,
    addressVerificationMeta = {},
    isReadOnlyForm,
    actions: { resetVerifyBusinessMetaAction },
    personalContactData = {},
    ...props
}) {
    const { Address, Email, Phone, SameAsPersonalContact } = businessContactForm;
    const contactSameAsPersonal = formData[SameAsPersonalContact.name];

    function onChangeAddress() {
        if (addressVerificationMeta.errorCode) {
            resetVerifyBusinessMetaAction();
        }
    }

    useEffect(() => {
        if (addressVerificationMeta.errorCode === NONPHYSICAL_ADDRESS_ERROR) {
            const addressDiv = document.querySelector(`#autosuggest-${Address.name}`);
            if (addressDiv && addressDiv.focus) addressDiv.focus();
            props.change('businessAddress', '');
        }
    }, [addressVerificationMeta.errorCode]);

    useEffect(() => {
        if (isReadOnlyForm && contactSameAsPersonal) {
            // since the sameAsPersonalContact checkbox is not showing in the back navigation, we have to display the
            // personal contact values in the business contact form if user ticked the same checkbox
            props.change('businessContactSameAsPersonalContact', false);
            props.change('businessAddress', personalContactData.address);
            props.change('businessEmail', personalContactData.emailAddress);
        }
    }, [isReadOnlyForm]);

    return (
        <fieldset className="mt-3" disabled={disabled}>
            <TextStyle element="legend" alias="HeadingC" className="mb-2 mt-3 business-subheading">{businessContactForm.title}</TextStyle>
            {!isReadOnlyForm
                && <Field
                    aria-required
                    className="mb-2"
                    label={SameAsPersonalContact.label}
                    checked={contactSameAsPersonal}
                    component={AbleCheckBox}
                    id={SameAsPersonalContact.name}
                    name={SameAsPersonalContact.name}
                />
            }
            {!contactSameAsPersonal && (
                isReadOnlyForm
                    ? <ReadOnlyField label={Email.label} value={formData[Email.name]} />
                    : <Field
                        aria-required
                        className="mb-3"
                        label={Email.label}
                        name={Email.name}
                        id={Email.name}
                        component={AbleTextField}
                    />
            )}
            {
                isReadOnlyForm
                    ? <ReadOnlyField label={Phone.label} value={formData[Phone.name]} />
                    : <Field
                        maxLength={20}
                        className="mb-3"
                        aria-required
                        label={Phone.label}
                        name={Phone.name}
                        helpText={Phone.helpText}
                        component={AbleTextField}
                        id={Phone.name}
                    />
            }
            {!contactSameAsPersonal && (
                isReadOnlyForm
                    ? <ReadOnlyField label={Address.label} value={formData[Address.name].label} />
                    : <Field
                        aria-required
                        label={Address.label}
                        name={Address.name}
                        onChange={onChangeAddress}
                        formSubmitError={addressVerificationMeta.errorCode === NONPHYSICAL_ADDRESS_ERROR}
                        assistiveText={Address.helpText}
                        component={AddressAutoComplete}
                        id={Address.name}
                    />
            )}
        </fieldset>
    );
}

export const BusinessContactDetails = connect(state => ({
    formData: getFormValues('businessRegistrationForm')(state),
    personalContactData: getFormValues('yourContactDetailsForm')(state),
    addressVerificationMeta: state.yourContactDetails.addressVerificationMeta,
    existingBusinessAccount: state.yourContactDetails.selectedBusiness.selectedBusiness,
    loggedIn: state.app.appData.uiState.loggedIn
}), dispatch => ({
    actions: bindActionCreators({ resetVerifyBusinessMetaAction: resetVerifyBusinessMeta }, dispatch)
}))(BusinessContactDetailsForm);

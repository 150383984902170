import { UPDATE_EMAIL_SUCCESS } from './../../../actions';

export const getIsMobileUpdated = (state) => {
    return state.yourContactDetails.updateMobileStatus.isMobileUpdated;
};

export const getIsMobileUpdateInProgress = (state) => {
    return state.yourContactDetails.updateMobileStatus.isMobileUpdateInProgress;
};

export const updateEmailSuccess = (payload) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_EMAIL_SUCCESS, payload });
    };
};

import * as client from '../../adapters/client';
import { PORTING_TERMS_INIT_DONE, PORTING_TERMS_INIT_ERROR } from '../actions';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false },
    locale: {}
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case PORTING_TERMS_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true },
                locale: action.data
            };
        case PORTING_TERMS_INIT_ERROR:
            return {
                ...state,
                loading: { appLoading: false, isModuleLoaded: false }
            };

        default:
            return state;
    }
};

export default reducer;

/**
 * Returns dipatch object when page is initialized
 * @param {any} data
 */
export const moduleInitialised = data => {
    return { type: PORTING_TERMS_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 * @export
 * @param {any} httpStatus
 */
export function moduleInitialisationError(httpStatus) {
    return { type: PORTING_TERMS_INIT_ERROR, data: httpStatus };
}

// Action Creators
/**
 * Fetches AEM authorable content
 * @param {any} [dataFactory=client.fetchLocale]
 */
export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'portingterms';
    return dispatch => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

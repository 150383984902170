import React from "react";
import SpinningButton from "../../../../components/SpinningButton/SpinningButton";
import FullPageError from "../../../../components/FullPageError/FullPageError";

export function LimitReachedSoftStopError({ errorDetails = {}, loading, onContinue, buttonText }) {
    return (
        <div className="update-limit-reach-full-error">
            <FullPageError isSoftStopError
                errorText={errorDetails.errorHeading}
                errorMessage={errorDetails.errorText}
                errorMessage1={errorDetails.errorText1}>
                {buttonText && <SpinningButton
                    stylingClass="mt-4"
                    isLoading={loading}
                    isDisabled={loading}
                    buttonText={buttonText}
                    onSubmit={onContinue} />}
            </FullPageError>
        </div>
    );
}
import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';
import {
    CART_SUBMISSION_INIT,
    CART_SUBMISSION_SUCCESS,
    CART_SUBMISSION_ERROR,
} from '../actions';
import { nextPage } from '../../adapters/utils';
import { errorDataLayerAddition } from '../../adapters/analytics-utils';
import store from '../../store';
import { PAYMENT_ERROR } from '../../adapters/constants';

export const activateSim = ({
    id = null,
    customerUUID = null,
    identityProfileId = null,
    paymentAmount = null,
    paymentType = null,
    paymentMethodType = null,
    paymentNonce = null,
    deviceData = null,
    registerInVault = false
} = {}) => {

    const { app: { appData: { loading: { activationInProgress }, activationData } } } = store.getState();
    if (activationData || activationInProgress) {
        // ACTIVATION INPROGRESS OR ALREADY ACTIVATED
        console.log('::ACTIVATION INPROGRESS OR ALREADY ACTIVATED::');
        return { type: '' };
    }

    const payload = {
        id,
        customerUUID,
        identityProfileId,
        paymentAmount,
        paymentType,
        paymentMethodType,
        paymentNonce,
        deviceData,
        registerInVault
    };

    if (!payload.customerUUID) {
        const { app: { appData: { customerInfo: { customerAccountUUID } = {} } } = {} } = store.getState();
        payload.customerUUID = customerAccountUUID;
    }

    return async (dispatch) => {
        dispatch({ type: CART_SUBMISSION_INIT });
        return client.payAndActivateSim(dispatch, (data) => activationSuccess(data, dispatch), activationFailed, { data: payload });
    };
}

const activationSuccess = (activationDetails, dispatch) => {
    const { hasError, isBillPayError, errorCode, errorHeading, errorBody, data = {} } = processActivationResponse(activationDetails);
    if (hasError) {
        if (isBillPayError) {
            return paymentFailed(errorCode, errorHeading, errorBody);
        }
        // data is looked up only to get the paymentid incase refund is failed.
        if (data && data.paymentId) {
            return activationFailed(errorCode, data.paymentId);
        }
        return activationFailed(errorCode);
    }
    nextPage();
    return { type: CART_SUBMISSION_SUCCESS, data, serviceAuthToken: data.token };
};

const paymentFailed = (errorCode, errorHeading, errorBody) => {
    return { type: PAYMENT_ERROR, errorCode, errorHeading, errorBody };
};

const activationFailed = (httpStatus, PaymentId) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 417:
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 5004:
            errorCode = errorCodeMeta.BLACKLIST_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    return { type: CART_SUBMISSION_ERROR, errorCode, PaymentId };
};

const processActivationResponse = (activationData) => {
    let errorCode = '';
    let analyticsErrorCode = '';
    let isBillPayError = false;
    let errorHeading = '';
    let errorBody = '';
    const submitCartStatus = activationData.status;
    const paymentType = store.getState().payment.selectedPaymentType;

    if (typeof submitCartStatus === 'string' && submitCartStatus.toLowerCase() !== 'success') {
        if (submitCartStatus.includes('ADHOC-')) {
            ({ errorCode, errorHeading, errorBody } = processBillPayError(activationData));
            isBillPayError = true;
        }
        else if (submitCartStatus.indexOf('REFUND-FATAL') >= 0) {
            errorCode = errorCodeMeta.SUBMIT_CART_FATAL_REFUND;
            if (paymentType === 'CREDIT_CARD') {
                analyticsErrorCode = errorCodeMeta.CARD_PAYMENT_REFUND_FAILURE;
                errorCode = errorCodeMeta.CARD_PAYMENT_REFUND_FAILURE;
            } else if (paymentType === 'PAYPAL') {
                analyticsErrorCode = errorCodeMeta.PAYPAL_PAYMENT_REFUND_FAILURE;
                errorCode = errorCodeMeta.PAYPAL_PAYMENT_REFUND_FAILURE;
            }
        } else if (submitCartStatus.indexOf('DXPCCI-FATAL') >= 0 || submitCartStatus.indexOf('SDC-FATAL') >= 0) {
            errorCode = errorCodeMeta.SUBMIT_CART_FATAL;
            if (paymentType === 'CREDIT_CARD') {
                analyticsErrorCode = errorCodeMeta.CARD_PAYMENT_REFUND_SUCCESS;
                errorCode = errorCodeMeta.CARD_PAYMENT_REFUND_SUCCESS;
            } else if (paymentType === 'PAYPAL') {
                analyticsErrorCode = errorCodeMeta.PAYPAL_PAYMENT_REFUND_SUCCESS;
                errorCode = errorCodeMeta.PAYPAL_PAYMENT_REFUND_SUCCESS;
            }
        } else {
            errorCode = errorCodeMeta.FATAL_ERROR;
        }
        if (!isBillPayError) {
            errorDataLayerAddition(analyticsErrorCode);
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, errorHeading, errorBody, data: activationData.data, isBillPayError };
};

const processBillPayError = (paymentResponse) => {
    let errorCode = '';
    let analyticsErrorCode = '';
    let errorHeading = paymentResponse.errorHeading;
    let errorBody = paymentResponse.errorBody;
    const paymentError = paymentResponse.status;
    if (paymentError.includes('ADHOC-EX-')) {
        errorCode = errorCodeMeta.PAYMENT_UNEXPECTED_ERROR;
        analyticsErrorCode = errorCodeMeta.TECHNICAL_ERROR;
    } else if (paymentError.includes('ADHOC-FATAL-')) {
        errorCode = errorCodeMeta.FATAL_ERROR;
        analyticsErrorCode = errorCode;
    } else if (paymentError.includes('ADHOC-ERROR-')) {
        errorCode = errorCodeMeta.PAYMENT_ERROR;
        analyticsErrorCode = errorCode;
    } else {
        errorCode = errorCodeMeta.FATAL_ERROR;
        analyticsErrorCode = errorCode;
    }
    errorDataLayerAddition(analyticsErrorCode, 'Validation', 'unavailable');
    return { errorCode, errorHeading, errorBody };
};
import './globalHeader.css';
import SignOut from './signOut';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return {
        loading: state.app.loading,
        isAppLoaded: state.app.isAppLoaded,
        loginCheckInProgress: state.app.loginCheckInProgress,
        appData: state.app.appData
    };
}

const GlobalHeader = connect(mapStateToProps)(SignOut);

export default GlobalHeader;

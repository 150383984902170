/* eslint-disable camelcase */
import React, { Component } from 'react';
import Loader from '../../components/Loader/loader';
import findNextRoute from '../../adapters/findNextRoute';
// import { REACT_BASE_PATH } from '../../adapters/constants';
import { resetPersistor } from '../../adapters/authentication-utils';
import { resumeFlow, route } from '../../adapters/utils';
import { resetDataLayerObject, updateDataLayerObject, createDataLayerPage, addDataLayerEventInfo, addDataLayerUser } from '../../adapters/analytics-utils';
import { userProfileInfoMeta } from '../../adapters/constants';
import store from "../../store";

class App extends Component {
    constructor(props) {
        super(props);
        this.pathname = findNextRoute();
        const { appData: { uiState: { persistState = false, isEsimAuth } = {} } = {}, isAppLoaded = false, loginCheckInProgress } = props;
        if (isAppLoaded && loginCheckInProgress && persistState && !isEsimAuth) {
            const perfEntries = performance && performance.getEntriesByType('navigation');
            const navigationType = perfEntries.length && perfEntries[0].type;
            if (navigationType === 'back_forward' || navigationType === 'reload') {
                resetPersistor();
                window.location.reload();
            }
        }
    }

    UNSAFE_componentWillMount() {
        resetDataLayerObject();
        const { appData: { uiState: { persistState = false } = {} } = {}, isAppLoaded = false } = this.props;
        if (!persistState && !isAppLoaded) {
            this.props.actions.storeLandingLocation(this.props.location);
            this.props.actions.initApp(this.props.location);
        }
    }

    componentDidMount() {
        const { appData: { esimData: { hasRetrievedParams }, uiState: { isCaimanLogin, isEsimActivation, isEsimAuth, persistState = false } = {} } = {} } = this.props;
        const { recheckAuthentication, getCustomerData } = this.props.actions;
        let isCustomerValid = false;
        let telstraAuthCustomerProfile = {};
        const { completeInit, storeUserAccessToken, storeAcrClaim } = this.props.actions;
        const paramEsim = window.localStorage.getItem('parameters');
        const payload = {
            data: {
                token: paramEsim
            }
        };
        if (isCaimanLogin) {
            window.TelstraAuth.subscribe('auth:ready', (user) => {
                if (user) {
                    window.TelstraAuth.isUserValid().then((valid) => {
                        if (valid) {
                            const {
                                profile: {
                                    given_name = '',
                                    acr // = 'LOA1' // uncomment for local // LOA2 to open popup
                                } = {}
                            } = user;
                            user.profile = { given_name, acr };
                            isCustomerValid = true;
                            telstraAuthCustomerProfile = user;
                            window.TelstraAuth.subscribe('auth:token_renew', (token) => storeUserAccessToken(token));
                            localStorage.setItem('loggedIN', valid);
                            storeAcrClaim(acr);
                            localStorage.setItem('acrValue', acr);
                        }
                        // PPAI-1 If the store is persistant and the state is not restored
                        // by redux-persist, recheck authentication and get customer data
                        const { app: { appData: { uiState: { stateRestored } = {} } = {} } = {} } = store.getState();
                        if (persistState && !stateRestored) {
                            // Here stateRestored should be a fresh copy since after recheckAuthentication() the flag is set so that
                            // this condition never hits.
                            // Here persistState should be a stale copy since resetPersistor will make it false since this callback
                            // is called asynchronously.
                            recheckAuthentication();
                            completeInit(isCustomerValid, telstraAuthCustomerProfile);
                            isEsimAuth && !hasRetrievedParams && this.props.actions.retrieveEsimParams(payload);
                            !isEsimAuth && getCustomerData();
                        } else {
                            completeInit(isCustomerValid, telstraAuthCustomerProfile);
                        }

                        updateDataLayerObject(createDataLayerPage('SIM Details', isEsimActivation));
                        // addDataLayerEventInfo('detailsPg');
                        addDataLayerUser({ type: userProfileInfoMeta.LOGGED_IN_USING, value: 'Telstra ID' });
                    });
                }
            });
        }
    }

    componentDidUpdate() {
        // return url to navigate unless id token is passed by okapi auth
        // OR url is hashed to view static pages.
        const url = this.pathname;
        const { appData: { uiState: { persistState = false, lastRoute = undefined, isMfafromEdit
        } = {} } = {} } = this.props;
        const levelOfAuthentication = this.props.appData.levelOfAuthentication;
        const { loginCheckInProgress, isAppLoaded } = this.props;
        if (isAppLoaded && !loginCheckInProgress) {
            if (persistState) {
                // PPAI-1: purge the saved state here when the restore is completed.
                resetPersistor();
                if (isMfafromEdit) {
                    if (levelOfAuthentication === 'LOA2') {
                        resumeFlow(lastRoute, true);
                    } else {
                        resumeFlow(lastRoute, true);
                    }
                } else resumeFlow(url, true);
            } else {
                route(url);
            }
        }
    }
    render() {
        return <Loader />;
    }
}

export default App;

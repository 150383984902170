import Parser from 'html-react-parser';
import React from 'react';
import { isEmpty } from '../../adapters/validations';
import './error.css';
import ErrorSvg from '../../assets/img/svg/error-32.svg';

/**
 * Displays error messages
 * boldText prop adds 'strong' to class name to get bold effect.
 * @param {any} props
 */
const Error = ({ boldText = false, isValidationError = false, ...props }) => {
    if (isEmpty(props.errorText)) return false;
    return (
        <div>
            {isValidationError && (
                <div
                    id={`${props.errorDescriptionId}-error-description`}
                    className={`${!isValidationError ? 'ppa-service-error' : 'validation-error'} ${props.isMedicareDate ? 'medicare-g-date-error' : ''} error-msg ${boldText ? 'strong' : ''}`}
                >
                    <img className="custom-icon" aria-hidden="true" src={ErrorSvg} alt="alert" />
                    {props.errorIconClassName && <i className={`td-icon ${props.errorIconClassName}`} />}
                    {Parser(props.errorText)}
                </div>
            )}
            {!isValidationError && (
                <div
                    id={`${props.errorDescriptionId}-error-description`}
                    className={`${!isValidationError ? 'ppa-service-error' : 'validation-error'} ${props.isMedicareDate ? 'medicare-g-date-error' : ''} error-msg ${boldText ? 'strong' : ''}`}
                    aria-live="polite"
                    aria-atomic="true"
                >
                    <img className="custom-icon" aria-hidden="true" src={ErrorSvg} alt="alert" />
                    {props.errorIconClassName && <i className={`td-icon ${props.errorIconClassName}`} />}
                    {Parser(props.errorText)}
                </div>
            )}
        </div>
    );
};

export default Error;

import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import app from './routes/app/module';
import welcome from './routes/welcome/module';
import existingNumber from './routes/existing-number/module';
import portingTerms from './routes/porting-terms/module';
import authorisedRep from './routes/authorised-representative/module';
import addressDetails from './routes/address-details/module';
import offerDetails from './routes/recharge-plans/module';
import payment from './routes/payment/module';
import reviewDetails from './routes/review-details/module';
import activationGuide from './routes/activation-guide/module';
import register from './routes/register/module';
import yourIdDetails from './routes/your-id-details/module';
import yourContactDetails from './routes/your-contact-details/module';
import stepperBar from './components/Progress/module';

const rootReducer = routerReducer => combineReducers({
    form,
    app,
    welcome,
    existingNumber,
    portingTerms,
    authorisedRep,
    addressDetails,
    offerDetails,
    payment,
    reviewDetails,
    activationGuide,
    register,
    yourIdDetails,
    yourContactDetails,
    stepperBar,
    router: routerReducer
});

export default rootReducer;

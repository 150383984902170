import './ableAutoComplete.css';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import searchIcon from '../../../../assets/img/svg/iconSearch.svg';
import Spinner from '@mytelstra/mt-design-system/lib/Spinner';
import { TextField } from '@able/react';
import classNames from 'classnames';
import { getAbleSpriteSheet } from '../../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../../adapters/analytics-utils';

const ableSpriteSheet = getAbleSpriteSheet();
class AbleAutoComplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '', options: [], isLoading: false, itemSelected: false, errDivHeight: null };
        this.handleSearch = this.handleSearch.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    }

    componentDidMount() {
        this.setState({ value: this.props.selectedValue.value ? this.props.selectedValue.value : '' });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.itemSelected && nextProps.formSubmitError) {
            this.setState({ value: '', itemSelected: false });
        }
    }

    componentDidUpdate() {
        setTimeout(() => {
            const { input = {}, } = this.props;
            const errDiv = document.getElementById(`autosuggest-${input.name}__error-text`);
            const errDivHeight = errDiv && errDiv.clientHeight;
            if (errDivHeight !== null && this.state.errDivHeight !== errDivHeight) {
                this.setState({ errDivHeight });
            }
        }, 0);
        const { meta: { touched = false, error, submitFailed = false } = {} } = this.props;
        if (submitFailed && touched && error) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
        }
    }

    render() {
        const { placeholder, label, input = {}, className = '', disabled, assistiveText, customErrorMessage = '' } = this.props;
        const { touched = false, error } = this.props.meta;
        const hasError = !!customErrorMessage || touched && error;
        const { value, options } = this.state;

        const inputProps = {
            id: `autosuggest-${input.name}`,
            className: `react-autosuggest__input`,
            placeholder,
            value,
            ref: input.ref,
            label,
            onChange: (event, { newValue }) => {
                if (event.type === 'change' && input.value !== '') input.onChange('');
                if (touched && event.type === 'change' && error !== '') input.onChange('');
                this.setState({
                    itemSelected: event.type === 'click' || event.key === 'Enter',
                    value: newValue
                });
            },
            disabled,
            invalidInputText: customErrorMessage || error,
            invalid: !!hasError,
            defaultValue: '',
            helpText: assistiveText
        };

        return (
            <div className={classNames({
                'able-autosuggest': true,
                [className]: !!className
            })}>
                <Autosuggest
                    suggestions={options}
                    onSuggestionsFetchRequested={(props) => this.handleSearch(props)}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={(option) => {
                        input.onChange(option);
                        return option.label;
                    }}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    highlightFirstSuggestion
                    focusInputOnSuggestionClick={false}
                    renderInputComponent={this.renderInputComponent}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                />
            </div>
        );
    }

    renderSuggestionsContainer = ({ containerProps: { style = {}, ...containerProps }, children }) => {
        if (this.state.errDivHeight !== null) {
            style.marginTop = `-${this.state.errDivHeight}px`;
        }
        return (
            <div {...containerProps} style={style}>
                {children && (
                    <span className="aria-style" role="status" aria-live="assertive" aria-atomic="true">
                        {children.props.items.length} list items available, press enter to choose or use arrows to select another option.
                    </span>
                )}
                {children}
            </div>
        );
    }

    onSuggestionsClearRequested() {
        this.setState({
            options: []
        });
    }

    renderInputComponent(inputProps) {
        const { isLoading, errDivHeight } = this.state;
        let imgPositionBottom;
        if (errDivHeight !== null) {
            if (isLoading) imgPositionBottom = errDivHeight + 31;
            else imgPositionBottom = errDivHeight + 24;
        }
        return (
            <div className="able-autosuggest-input">
                <TextField {...inputProps} developmentUrl={ableSpriteSheet} />
                {isLoading ? (
                    <span style={{ bottom: imgPositionBottom }} className='able-autosuggest-input__spinner'>
                        <Spinner size="mini" label="loading" />
                    </span>
                ) : (
                    <span style={{ bottom: imgPositionBottom }} className='able-autosuggest-input__img'>
                        <img aria-hidden="true" src={searchIcon} alt="" />
                    </span>
                )}
            </div>
        );
    }

    renderSuggestion(option) {
        return <span>{option.label}</span>;
    }

    handleSearch({ value }) {
        if (value.trim().length < 5 || (value.trim().length - 5) % 3 !== 0) return;
        this.setState({
            isLoading: true
        });
        this.props.loadOptions(value.trim()).then((response) => {
            this.setState({
                isLoading: false,
                options: response.length > 0 ? response : []
            });
        });
    }
}

export default AbleAutoComplete;

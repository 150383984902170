import View from './footer';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return {
        isCoreLoaded: state.app.isAppLoaded && state.app.loading,
        locale: state.welcome.locale,
        loading: state.welcome.loading,
        appData: state.app.appData || {}
    };
}

export function mapDispatchToProps() {
    return {};
}

const Footer = connect(mapStateToProps, mapDispatchToProps)(View);

export default Footer;

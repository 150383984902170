import * as client from '../../adapters/client';
import { REGISTER_INIT_DONE, REGISTER_INIT_ERROR, RESET_REGISTRATION_FORM, REGISTER_CUSTOMER_ERROR, REGISTER_CUSTOMER_SUCCESS } from '../actions';
import { SERVER_ERROR, REGISTER_CUSTOMER_FALIURE } from '../../adapters/errorCode';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { dataLayerEventActions } from '../../adapters/constants';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false, formProcessed: false, lockFields: false },
    locale: {},
    errors: { hasError: false, errorCode: null }
};

function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case REGISTER_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true },
                locale: action.data
            };
        case REGISTER_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case RESET_REGISTRATION_FORM:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false }
            };
        case REGISTER_CUSTOMER_ERROR:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, lockFields: true },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode }
            };
        case REGISTER_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, lockFields: true }
            };
        default:
            return state;
    }
}

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'register';
    return dispatch => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

// DISPATCH OBJECTS

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = data => {
    return { type: REGISTER_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: REGISTER_INIT_ERROR, data: httpStatus };
}

/**
 * Reset registration form
 *
 * @export
 * @returns
 */
export function resetForm() {
    return { type: RESET_REGISTRATION_FORM };
}

export const registerCustomer = (payload, fetcher = client.registerTelstraId) => {
    return dispatch => {
        return fetcher(dispatch, receiveRegisterCustomer, receiveRegisterCustomerError, payload);
    };
};

export const receiveRegisterCustomer = packageData => {
    const { hasError, errorCode, data } = processregisterCustomerData(packageData.data);

    if (hasError) {
        return receiveRegisterCustomerError(errorCode);
    }
    // addDataLayerEventInfo(dataLayerEventActions.ORDER_CONFIRM_PAGE);
    return {
        type: REGISTER_CUSTOMER_SUCCESS,
        data
    };
};

export const receiveRegisterCustomerError = httpStatus => {
    addDataLayerEventInfo(dataLayerEventActions.ERROR);
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 404:
            errorCode = REGISTER_CUSTOMER_FALIURE;
            break;
        case 500:
            errorCode = SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    return { type: REGISTER_CUSTOMER_ERROR, errorCode };
};

const processregisterCustomerData = packageData => {
    const errorCode = '';
    const { data = {} } = packageData || {};
    return { hasError: Boolean(errorCode.length), errorCode, data };
};

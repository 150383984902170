import View from './app-disabled';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return {
        locale: state.welcome.locale,
        loading: state.welcome.loading
    };
}

export function mapDispatchToProps() {
    return {};
}

const AppDisabled = connect(mapStateToProps, mapDispatchToProps)(View);

export default AppDisabled;

import React, { useEffect } from 'react';
import { RadioGroup, RadioButton } from '@able/react';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSelectedBusiness, setSelectedBusinessError, setHasMaxedOutServices } from '../../module';
import { registerField } from 'redux-form';
import { getAbleSpriteSheet } from '../../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

const { businessSelect, businessSelect: { maxedOutServices } } = CONTACT_PAGE_CONSTANTS;

export function SelectBusiness({
    businessDetails,
    selectedBusiness,
    hasMaxedOutServices,
    error,
    actions: { setSelectedBusinessAction, setSelectedBusinessErrorAction, setHasMaxedOutServicesAction, registerFieldAction }
}) {
    useEffect(() => {
        registerFieldAction('businessRegistrationForm', 'selectedBusiness', 'Field');
        const count = businessDetails.reduce((acc, item) => (item.maxedOutService ? acc + 1 : acc), 0);
        if (count) {
            setHasMaxedOutServicesAction(count);
            if (count === businessDetails.length) {
                setSelectedBusinessAction('createNew');
            }
        } else if (businessDetails.length === 0) {
            setSelectedBusinessAction('createNew');
        }
    }, []);

    const onBusinessDetailsChange = (event) => {
        const { currentTarget: { value } } = event;
        if (value !== selectedBusiness) {
            setSelectedBusinessErrorAction(undefined);
            setSelectedBusinessAction(value);
        }
    };

    const getDescription = ({ abn = '', acn = '', accountNumber = '' } = {}) => {
        const accNumber = `Account Number: ${accountNumber}`;
        if (abn) {
            return `ABN: ${abn} ${accNumber}`;
        }
        return `ACN: ${acn} ${accNumber}`;
    };

    return (
        <div className="contact-details-select-business mb-4">
            {
                businessDetails && businessDetails.length !== hasMaxedOutServices && (
                    <div id="traderBusinessDetails">
                        <RadioGroup
                            groupLabel={businessSelect.title}
                            helpText={businessSelect.brief + (
                                (hasMaxedOutServices > 0 && businessDetails && businessDetails.length !== hasMaxedOutServices)
                                    ? ` ${maxedOutServices.multipleService}`
                                    : '')}
                            id="businessDetailsSelect"
                            name="businessDetailsSelect"
                            variant="Comfortable"
                            invalid={!!error}
                            errorText={error}
                            legendId="selectedBusinessLegend"
                            developmentUrl={ableSpriteSheet}
                        >
                            {
                                businessDetails.map((item) => {
                                    if (item.maxedOutService) {
                                        return false;
                                    }
                                    return (
                                        <RadioButton
                                            key={item.accountNumber}
                                            className="able-boost-radio"
                                            id={item.accountNumber}
                                            checked={item.accountNumber === selectedBusiness}
                                            value={item.accountNumber}
                                            label={item.accountName}
                                            description={getDescription(item)}
                                            events={{ onClick: onBusinessDetailsChange }}
                                        />);
                                }).filter(item => item)
                            }
                            <RadioButton
                                id="createNew"
                                name="createNew"
                                checked={selectedBusiness === 'createNew'}
                                value="createNew"
                                className="able-boost-radio"
                                label="Create new business account"
                                events={{ onClick: onBusinessDetailsChange }}
                            />
                        </RadioGroup>
                    </div>
                )
            }
        </div>
    );
}

const ConnectedSelectBusiness = connect(state => ({
    app: state.app,
    businessDetails: state.app.loggedInCustomerData.businessDetails,
    selectedBusiness: state.yourContactDetails.selectedBusiness.selectedBusiness,
    hasMaxedOutServices: state.yourContactDetails.selectedBusiness.hasMaxedOutServices,
    error: state.yourContactDetails.selectedBusiness.error
}), dispatch => ({
    actions: bindActionCreators({
        setSelectedBusinessAction: setSelectedBusiness,
        setSelectedBusinessErrorAction: setSelectedBusinessError,
        setHasMaxedOutServicesAction: setHasMaxedOutServices,
        registerFieldAction: registerField
    }, dispatch)
}))(SelectBusiness);

export default ConnectedSelectBusiness;

import { isEmpty } from './validations';
import { getAppData, getLoggedInCustomerData } from './store-utils';
import { getRoutesFromPathName } from './utils';
import { dataLayerEventActions } from './constants';

export const renderExistingNumber = () => {
    const {
        uiState: { selectedActivationFlow = null }
    } = getAppData();
    return selectedActivationFlow === 'portNumber';
};

export const renderCustomerDetails = () => {
    const {
        uiState: { loggedIn }
    } = getAppData();

    const { adborAddressId = '', customerAccountUUID = '' } = getLoggedInCustomerData();
    let render = false;
    if (!loggedIn) {
        render = true;
    }
    if (loggedIn) {
        render = isEmpty(adborAddressId) || isEmpty(customerAccountUUID);
    }
    return render;
};

export const renderAuthorisedRep = () => {
    const {
        uiState: { loggedIn, selectedCustomerType },
        customer: { found: customerFound }
    } = getAppData();
    const isBusinessCustomer = selectedCustomerType === 'business';
    return !(loggedIn && customerFound) && isBusinessCustomer;
};

export const renderAddressDetails = () => {
    const {
        customer: { found: customerFound }
    } = getAppData();
    return !customerFound;
};

export const renderIdentityDetails = () => {
    const {
        customer: { dvsCheckRequired = true }
    } = getAppData();
    return dvsCheckRequired;
};

export const renderActivationGuide = () => {
    const {
        uiState: { isEsimActivation = false }
    } = getAppData();
    return !isEsimActivation;
};

export const renderOfferDetails = () => {
    const {
        uiState: { isEsimActivation = false }
    } = getAppData();
    return !isEsimActivation;
};

export const renderRegistration = () => {
    const {
        telstraId: { hasExistingTelstraId = false },
        uiState: { loggedIn, isEsimActivation }
    } = getAppData();
    return isEsimActivation && !hasExistingTelstraId && !loggedIn;
};

export const renderPayment = () => {
    let starterCreditWithAutoRecharge = true;

    const { anonymous = true } = getLoggedInCustomerData();
    const {
        serviceNumber: { starterCredit },
        skipRecharge,
        customer: {
            data: { stepUpSuccessful = false }
        }
    } = getAppData();

    if (starterCredit) {
        // disable payment page for starterCredit anonymous user
        starterCreditWithAutoRecharge = false;

        // enable payment page for starterCredit user with TDI(known user)
        if (!anonymous || stepUpSuccessful) {
            starterCreditWithAutoRecharge = true;
        }
    }

    return starterCreditWithAutoRecharge && !skipRecharge;
};

export const renderConfirm = () => {
    // const {
    //     uiState: { isEsimActivation = false },
    //     esimData: { isEligibleForFreeOffer = false }
    // } = getAppData();
    // return isEsimActivation && !isEligibleForFreeOffer;
    const {
        serviceNumber: { starterCredit },
        skipRecharge
    } = getAppData();

    return starterCredit || skipRecharge;
};

/**
 * This will be the placeholder for the wizard which will handle the
 * global page navigation logic.
 * @export
 * @returns
 */
export const applicationFlow = [
    {
        pageName: 'Welcome',
        path: '/welcome',
        render: () => true,
        progressBar: { authenticatedStep: 1, unAuthenticatedStep: 1 },
        addBrandDetails: false,
        saveStateAndLogin: true,
        events: [dataLayerEventActions.WELCOME_PG]
    },
    {
        pageName: 'Existing Number',
        path: '/existing-number',
        render: renderExistingNumber,
        progressBar: { authenticatedStep: 2, unAuthenticatedStep: 2 },
        addBrandDetails: true,
        saveStateAndLogin: true,
        events: [dataLayerEventActions.EXISTING_NUMBER_PG]
    },
    {
        pageName: 'Your ID Details',
        path: '/your-id-details',
        render: renderIdentityDetails, // TODO Needs to be rendered based on dvsCheckRequired flag
        progressBar: { authenticatedStep: 3, unAuthenticatedStep: 3 },
        addBrandDetails: true,
        saveStateAndLogin: true,
        events: [dataLayerEventActions.CREDITCHECK_PG]
    },
    // {
    //     pageName: 'Authorised Representative',
    //     path: '/authorised-representative',
    //     render: renderAuthorisedRep,
    //     progressBar: { authenticatedStep: 1, unAuthenticatedStep: 2 },
    //     saveStateAndLogin: true
    // },
    // {
    //     pageName: 'Address Details',
    //     path: '/address-details',
    //     render: () => false,
    //     progressBar: { authenticatedStep: 1, unAuthenticatedStep: 3 },
    //     saveStateAndLogin: true,
    //     events: [dataLayerEventActions.ADDRESS_DETAILS_PG]
    // },
    {
        pageName: 'Contact Details',
        path: '/your-contact-details',
        render: () => true,
        progressBar: { authenticatedStep: 4, unAuthenticatedStep: 4 },
        addBrandDetails: true,
        saveStateAndLogin: true,
        events: [dataLayerEventActions.CONTACT_DETAILS_PG]
    },
    {
        pageName: 'Offer Details',
        path: '/recharge',
        render: () => true,
        progressBar: { authenticatedStep: 5, unAuthenticatedStep: 5 },
        addBrandDetails: true,
        events: [dataLayerEventActions.OFFERDETAILS_PG]
    },
    // {
    //     pageName: 'Register',
    //     path: '/register',
    //     render: renderRegistration,
    //     progressBar: { authenticatedStep: 2, unAuthenticatedStep: 5 }
    // },
    // {
    //     pageName: 'Offer Details',
    //     path: '/offer-details',
    //     render: renderOfferDetails,
    //     progressBar: { authenticatedStep: 4, unAuthenticatedStep: 4 },
    //     saveStateAndLogin: false,
    //     events: [dataLayerEventActions.OFFERDETAILS_PG, dataLayerEventActions.PROD_VIEW]
    // },
    {
        pageName: 'Payment',
        path: '/payment',
        render: renderPayment,
        progressBar: { authenticatedStep: 6, unAuthenticatedStep: 6 },
        addBrandDetails: true,
        saveStateAndLogin: false,
        events: [dataLayerEventActions.PAYMENT_PG]
    },
    {
        pageName: 'Review & Confirm',
        path: '/review-details',
        render: renderConfirm,
        progressBar: { authenticatedStep: 6, unAuthenticatedStep: 6 },
        addBrandDetails: true,
        saveStateAndLogin: false,
        events: [dataLayerEventActions.ORDER_CONFIRM_PAGE]
    },
    {
        pageName: 'Confirmation',
        path: '/activation-guide',
        // render: renderActivationGuide,
        render: () => true,
        progressBar: { authenticatedStep: 7, unAuthenticatedStep: 7 },
        addBrandDetails: true,
        saveStateAndLogin: false,
        events: [dataLayerEventActions.PREPAIDACTIVATION]
    }
];

const findNextRoute = (currentRouteName = undefined) => {
    if (isEmpty(currentRouteName)) {
        const firstRoute = 0;
        return applicationFlow[firstRoute].path;
    }

    const nextRouteIndex = applicationFlow
        .map((e) => {
            return e.path;
        })
        .indexOf(currentRouteName);

    // const route = skip ? applicationFlow[nextRouteIndex] : applicationFlow[nextRouteIndex + 1];
    const route = applicationFlow[nextRouteIndex + 1];

    if (route.render()) {
        return { nextRoute: route, index: nextRouteIndex };
    }
    return findNextRoute(route.path);
};

export default findNextRoute;

export const getProgressBarSteps = () => {
    const {
        uiState: { loggedIn },
        customer: { found: customerFound }
    } = getAppData();
    const authenticatedUser = loggedIn && customerFound;
    const totalSteps = 6;
    const { location: { pathname = '' } = {} } = window;

    if (isEmpty(pathname)) return {};

    const routes = getRoutesFromPathName(pathname);
    if (routes.length === 0) return {};
    const unAuthenticatedStep = routes[0].progressBar.unAuthenticatedStep;
    return {
        totalSteps,
        currentStep: authenticatedUser ? routes[0].progressBar.authenticatedStep : unAuthenticatedStep
    };
};

export const getRouteConfig = (routeName = undefined) => {
    if (isEmpty(routeName)) {
        return {};
    }

    let route = {};
    const routeIndex = applicationFlow
        .map((e) => {
            return e.path;
        })
        .indexOf(routeName);
    if (routeIndex !== -1) {
        route = applicationFlow[routeIndex];
    }

    return route;
};

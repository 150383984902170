import React from 'react';
import Modal from 'react-modal';
import './app-disabled.css';
import Parser from 'html-react-parser';
import { withRouter } from 'react-router-dom';

// Element setting not needed in testing mode
if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#ppa');
}

const AppDisabledModal = ({ toggleModal, modalOpen, ...props }) => {
    const { disableApp: { title = '', description = '', button: { link = '', label = '' } } } = props.locale || {};

    const redirectToTelstraHomePage = () => {
        window.location.href = link;
    };

    return (
        <Modal isOpen={modalOpen} contentLabel="Application Disabled" onRequestClose={toggleModal} className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header-no-border">
                    <button className="close" onClick={toggleModal} />
                    <h4 className="modal-title grey sub-heading">{Parser(title)}</h4>
                </div>
                <div className="modal-body">
                    <p className="negative-msg">{Parser(description)}</p>
                    <p>
                        <button className="btn primary" onClick={redirectToTelstraHomePage}>
                            {label}
                        </button>
                    </p>
                </div>
            </div>
        </Modal>
    );
};

export default withRouter(AppDisabledModal);

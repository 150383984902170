import React, { Component } from 'react';
import SessionTimeoutModal from './session-timeout-modal';

class SessionTimeout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        };
        this.triggerTimmer.bind(this);
    }

    componentWillMount() {
        this.triggerTimmer();
    }
    render() {
        const { loading: { appLoading } } = this.props;
        if (appLoading) return false;

        const toggleModal = () => {
            this.setState({
                modalOpen: !this.state.modalOpen
            });
        };
        return <SessionTimeoutModal toggleModal={toggleModal} modalOpen={this.state.modalOpen} permitSessionRefresh={this.props.permitRefresh} {...this.props} />;
    }

    triggerTimmer() {
        setTimeout(() => {
            this.setState(
                {
                    modalOpen: true
                },
                () => {
                    const sessionHeading = document.querySelector('h4.modal-title.grey.sub-heading');
                    if (sessionHeading) {
                        sessionHeading.focus();
                    }
                }
            );
        }, this.props.timeout);
    }
}

export default SessionTimeout;

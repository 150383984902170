import CONTACT_PAGE_CONSTANTS from "../../constants";

export const getContactDetailsErrors = (inputs = {}, { isMobileNumberMandatory } = {}) => {
    const errors = {};

    errors.phoneNumber = false;
    if (!inputs.phoneNumber && isMobileNumberMandatory) {
        errors.phoneNumber = true;
    }
    errors.contactEmail = false;
    if (!inputs.contactEmail && !inputs.phoneNumber) {
        errors.contactEmail = true;
    }
    errors.address = false;
    if (!inputs.addressline1) {
        errors.address = true;
    }
    return errors;
};

export const getUpdateBusinessContactDetailsErrors = (inputs = {}, sameAsPersonalContacts = false) => {
    const errors = {};
    const { Address, Email, Phone } = CONTACT_PAGE_CONSTANTS.businessContactForm;

    if (!inputs.businessPhoneNumber) {
        errors.businessPhoneNumber = Phone.errorMessages.updateFormEmptyError;
    }
    if (!sameAsPersonalContacts) {
        if (!inputs.businessEmail) {
            errors.businessEmail = Email.errorMessages.updateFormEmptyError;
        }
        if (!inputs.businessAddressId) {
            errors.businessAddressId = Address.errorMessages.updateFormEmptyError;
        }
    }
    return errors;
};

export const hasContactDetailsErrors = (errors = {}) => {
    return Object.keys(errors).find((item) => errors[item]);
    // return false;
};

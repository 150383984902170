import './DateInput.css';
import { Input, Error } from '../';
import moment from 'moment';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { isEmpty } from '../../adapters/validations';

class DateInput extends Component {
    /**
     * Creates an instance of DateInput.
     *
     * @param {any} props
     * @memberof DateInput
     */
    constructor(props) {
        super(props);
        const { isExpiryDate = false, isMonthEnums = false } = props;
        this.state = { day: '', month: '', year: '' };
        let monthNames = [];
        if (isExpiryDate) {
            monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        } else if (isMonthEnums) {
            monthNames = moment.monthsShort();
        } else {
            monthNames = moment.months();
        }
        this.months = [];
        // Build months dropdown
        for (let month = 1; month <= 12; month += 1) {
            if (month <= 12) {
                this.months.push({ label: monthNames[month - 1], value: month - 1 });
            }
        }
    }
    render() {
        const { label, disabled, resetErrors, names, hideDay = false, ...custom } = this.props;
        const buttonProps = Object.assign({}, custom);
        delete buttonProps.hide;
        const dayName = names[0];
        const monthName = names[1];
        const yearName = names[2];

        return (
            <div className="form-element">
                <label className="text-grey-dark" htmlFor={label}>
                    {label}
                </label>
                <div className="telstra-date clearfix">
                    <div className="date-dropdown">
                        {!hideDay && (
                            <div className="date-input-control">
                                <Field
                                    name={dayName}
                                    component={Input}
                                    type="text"
                                    arialabel={`${label} Day`}
                                    placeholder="Day"
                                    onChange={resetErrors}
                                    fieldType="date"
                                    aria-required="true"
                                    maxLength={2}
                                    aria-describedby="dayDesc"
                                />
                                <span id="dayDesc" className="sr-only" tabIndex="-1">
                                    DD
                                </span>
                            </div>
                        )}
                        <Dropdown {...this.props[monthName]} arialabel={`${label} Month`} resetErrors={resetErrors} name={monthName} options={this.months} placeholder="Month" />
                        <div className="date-input-control">
                            <Field
                                name={yearName}
                                component={Input}
                                type="text"
                                arialabel={`${label} year`}
                                placeholder="Year"
                                onChange={resetErrors}
                                fieldType="date"
                                aria-required="true"
                                maxLength={4}
                                aria-describedby="yearDesc"
                            />
                            <span id="yearDesc" className="sr-only" tabIndex="-1">
                                YYYY
                            </span>
                        </div>
                    </div>
                    {this.renderErrors()}
                </div>
            </div>
        );
    }

    renderErrors() {
        const { names } = this.props;
        const dayName = names[0];
        const monthName = names[1];
        const yearName = names[2];
        const { meta: { touched: dayNameTouched = false, error: dayNameError = '' } = {} } = this.props[dayName] || {};
        const { meta: { touched: monthNameTouched = false, error: monthNameError = '' } = {} } = this.props[monthName] || {};
        const { meta: { touched: yearNameTouched = false, error: yearNameError = '' } = {} } = this.props[yearName] || {};
        let errorText = '';
        if (dayNameTouched && !isEmpty(dayNameError)) {
            errorText = dayNameError;
        } else if (monthNameTouched && !isEmpty(monthNameError)) {
            errorText = monthNameError;
        } else if (yearNameTouched && !isEmpty(yearNameError)) {
            errorText = yearNameError;
        }
        return <Error isValidationError errorText={errorText} isMedicareDate={this.props.isMedicareDate} errorIconClassName={'icon-alert-solid-round'} />;
    }
}

/**
 * Dropdown component for Date input parts
 *
 * @param {any} {options=[], value = '', placeholder='', customClass=''}
 * @returns
 */
const Dropdown = ({ input = {}, meta = {}, name = '', ...props }) => {
    const { options = [], value = '', placeholder = '', arialabel = '', resetErrors = false, ...custom } = props;
    const { onChange } = input;
    const { touched = false, error } = meta;
    const changeDate = selectedValue => {
        if (resetErrors) {
            resetErrors();
        }
        onChange(selectedValue);
    };
    let inputClassName = custom.className ? custom.className : '';
    inputClassName = touched && error ? `${inputClassName} has-error` : inputClassName;
    return (
        <div className={`date-input-control ${value}`}>
            <select name={name} className={`date-input ${inputClassName}`} onChange={selectedValue => changeDate(selectedValue)} value={input.value} aria-label={arialabel} aria-required>
                <option value="" disabled>
                    {placeholder}
                </option>
                {options.map(option => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
            <i className="td-icon icon-ui-down-arrow-thick" />
        </div>
    );
};

export default DateInput;

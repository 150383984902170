import './RadioBar.css';
import React from 'react';
import { TextStyle } from '@able/react';
import { addDataLayerEventInfo, addDataLayerCartPrice, addCartLayer } from '../../adapters/analytics-utils';

class RadioBar extends React.Component {
    static parse(event) {
        return JSON.parse(event.target.value);
    }
    static stringifyObject(value) {
        return JSON.stringify(value);
    }

    componentDidMount() {
        const { input } = this.props;
        const radioProvidedNumber = document.getElementById(`${input.value}`);

        radioProvidedNumber &&
            radioProvidedNumber.addEventListener('focus', () => {
                radioProvidedNumber.parentElement.classList.add('radio-tab-focus');
            });
        radioProvidedNumber &&
            radioProvidedNumber.addEventListener('blur', () => {
                radioProvidedNumber.parentElement.classList.remove('radio-tab-focus');
            });
    }

    componentWillReceiveProps(nextProps) {
        const { meta: { error: latestError, submitFailed = false } = {}, UpdateError, input = {}, id = '' } = nextProps;
        if (submitFailed && latestError) {
            if (typeof UpdateError === 'function') {
                UpdateError();
                addDataLayerEventInfo('error', 'Inline', '', latestError);
            }
        }
        if (input.name === 'savedCardRadio') {
            if (input.checked) {
                if (id === 'savedCardId') {
                    addCartLayer();
                    addDataLayerCartPrice('paymentType', 'Saved Card');
                    addDataLayerEventInfo('cardPaymentMethod');
                } else {
                    addDataLayerCartPrice('paymentType', 'New Card');
                    addDataLayerEventInfo('cardPaymentMethod');
                }
            }
        }
    }

    render() {
        const { input, label, isActive } = this.props;
        return (
            <TextStyle element="label" alias="TextBodyShort" className={`radio-tab ${isActive ? 'active' : ''}`} htmlFor={input.value || ''}>
                {label}
                <input
                    type="radio"
                    name={input.name}
                    // id={RadioBar.stringifyObject(input.value) || ''}
                    id={input.value || ''}
                    onChange={(event) => input.onChange(RadioBar.parse(event))}
                    value={RadioBar.stringifyObject(input.value)}
                    aria-describedby={`${input.name}-description`}
                />
                <span className="checkmark" />
            </TextStyle>
        );
    }
}

export default RadioBar;

import './activationguide.css';
import React, { Component, Fragment } from 'react';
import { getCustomerState, focusOnHeading, getCurrentRouteName, scrollToBody, getAbleSpriteSheet } from '../../adapters/utils';
import { getUiState } from '../../adapters/store-utils';
import { addDataLayerEventInfo, addToTransactionLayer } from '../../adapters/analytics-utils';
import boostAppImage from '../../assets/img/image-boost-app.jpg';
import tickHeader from '../../assets/img/svg/boost-confirm-done.svg';
import simCard from '../../assets/img/svg/boost-sim.svg';
import boostProfile from '../../assets/img/svg/boost-profile.svg';
import boostAppIcon from '../../assets/img/boost-app-icon.jpg';
import OfferCard from './../../routes/review-details/components/offerCard';
import store from '../../store';
import { updateStepperCustomData } from '../../components/Progress/module';
import { GET_THE_APP, DOWNLOAD_APP_IOS, DOWNLOAD_APP_ANDROID, SIGN_IN } from '../../adapters/constants';
import AutoRechargeEnabledCard from './AutoRechargeEnabled/autoRechargeEnabled';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import { ActionButton, Icon, MessageSection, Spacing, TextStyle } from '@able/react';
import ACTIVATION_GUIDE_CONSTANTS from './constants';

const step2image = require('../../assets/img/Step2-image.jpg');
const step3image = require('../../assets/img/Step3-image.jpg');
const ableSpriteSheet = getAbleSpriteSheet();

class ActivationGuide extends Component {
    componentWillMount() {
        this.setState({
            showSurvey: false
        });
    }

    componentDidMount() {
        const state = store.getState();
        const {
            customer: { data: { hasTdiLogin = true } = {} } = {},
            esimData: { hasCompletedEsimProfileDownload }
        } = state.app.appData;
        const {
            activationDataDetails: { customerInteractionId }
        } = state.app.appData.activationData;
        if (!hasTdiLogin) {
            addDataLayerEventInfo('impression', 'createTDI');
        } else {
            addDataLayerEventInfo('impression', 'existingTDI');
        }
        const skipRecharge = this.props.appData.skipRecharge;
        if (skipRecharge === false) {
            addDataLayerEventInfo('prepaidRecharge', '', '', '');
        }
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        focusOnHeading();
        scrollToBody('body');
        // const { rechargeAmount, offerCode } = getCustomerState();
        // const data = {
        //     productID: offerCode,
        //     price: rechargeAmount,
        //     quantity: 1
        // };
        addToTransactionLayer(customerInteractionId);
        const { isEsimActivation = false } = getUiState();
        const { downloadEsimProfile } = this.props.actions;
        isEsimActivation && !hasCompletedEsimProfileDownload && downloadEsimProfile();
        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: isEsimActivation ? 7 : 5,
            isLastStepCompleted: true
        };
        store.dispatch(updateStepperCustomData(stepperData));
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, document.title, window.location.href);
        });
    }

    render() {
        const { selectedActivationFlow = '', loggedIn = false, isEsimActivation = false } = getUiState();
        const isPortingFlow = selectedActivationFlow === 'portNumber';
        const skipRecharge = this.props.appData.skipRecharge;
        const hasEsimProfileError = this.props.appData.esimData.hasEsimProfileError;
        const isProcessingProfile = this.props.appData.esimData.isProcessingProfile;
        // Get Service details
        const state = store.getState();
        let actualAmount;
        // hasTdiLogin is true when Unauthenticated customer is recognised as having existing TDI
        const { customer: { data: { hasTdiLogin = true } = {} } = {}, updateCart: { data: { cartTotalPrice = [] } = {} } = {} } = state.app.appData;
        if (cartTotalPrice.length !== 0) {
            const { price: { dutyFreeAmount: { value = '' } = {} } = {} } = cartTotalPrice[0];
            actualAmount = value;
        }
        const newOfferSelected = state.offerDetails.selectedOffer ? state.offerDetails.selectedOffer : {};
        const { yourContactDetailsForm: { values: { emailAddress: userEmail } = {} } = {} } = state.form || {};
        const { portingNumber = '' } = state.welcome;
        const { starterCredit, msisdn } = getCustomerState();
        const { activationDataDetails: { isAutoRechargeSuccessful } = {} } = state.reviewDetails.activationData || {};
        // const { loggedInCustomerData: { anonymous = true } = {}, appData: { customer: { data: { stepUpSuccessful = false } = {} } = {} } = {} } = state.app;
        const serviceCount = this.props.appData.customerInfo.serviceCount;
        const businessDetails = this.props.app.loggedInCustomerData && this.props.app.loggedInCustomerData.businessDetails;
        const selectedBusinessDetails = (businessDetails && businessDetails.find(item => item.accountNumber === this.props.selectedBusiness)) || {};

        // Get confirmation page locale
        const {
            title: { esimTitle, activationTitle },
            porting: { esimPorting, physicalSimPorting, portingSimHeader, portingSimContent },
            getNewNumber: { esimTitleMessage, numTitleMessage, numSimHeader, numSimContent }
        } = ACTIVATION_GUIDE_CONSTANTS;

        // Dynamic contents for esim 'porting/getNewNumber' and Physical sim 'porting/getNewNumber'
        const serviceNumber = isPortingFlow ? portingNumber : msisdn;
        let titleMessage;
        let simHeader;
        let simContent;
        const ConfirmHeader = isEsimActivation ? esimTitle : activationTitle;
        if (selectedActivationFlow === 'portNumber') {
            if (isEsimActivation) {
                titleMessage = esimPorting;
            } else {
                titleMessage = physicalSimPorting;
                simHeader = portingSimHeader;
                simContent = portingSimContent;
            }
        } else if (isEsimActivation) {
            titleMessage = esimTitleMessage;
        } else {
            titleMessage = numTitleMessage;
            simHeader = numSimHeader;
            simContent = numSimContent;
        }

        const autoRechargeCardProps = {
            loggedIn,
            hasTdiLogin,
            newOfferSelected,
            actualAmount,
            starterCredit
        };

        if (isAutoRechargeSuccessful === false) {
            addDataLayerEventInfo('error', 'auto recharge module', '', 'Auto recharge setup unsuccessful');
            if (!hasTdiLogin) {
                addDataLayerEventInfo('impression', 'auto recharge module', 'fail', 'Auto recharge setup unsuccessful - New TDI');
            } else {
                addDataLayerEventInfo('impression', 'auto recharge module', 'fail', 'Auto recharge setup unsuccessful - Existing TDI');
            }
        }

        return (
            <div className="container confirm-block">
                <GlobalBanner onPage="success" className="infobanner-success" />
                {!isEsimActivation && <img className="tick-header" src={tickHeader} alt="tick" />}
                <div className="row">
                    <Fragment>
                        {isEsimActivation ? (
                            isProcessingProfile ? null : !hasEsimProfileError ? (
                                <div className="col-md-5">
                                    <TextStyle tabIndex="-1" alias="HeadingA" element="h1">{ConfirmHeader}</TextStyle>
                                    <TextStyle alias="FinePrint" className="mt-4 mb-3" element="p">{titleMessage}</TextStyle>
                                    <SpinningButton id="Download" stylingClass="mt-3 w-100 mb-4" variant="HighEmphasis" buttonText={'Download'} onSubmit={() => this.profileDownloadRedirect()} />
                                </div>
                            ) : (
                                this.renderProfileErrorGuide(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)
                            )
                        ) : (
                            <div className="col-md-5">
                                <TextStyle tabIndex="-1" alias="HeadingA" element="h1">{ConfirmHeader}</TextStyle>
                                <TextStyle alias="FinePrint" className="mt-4 mb-3" element="p">{titleMessage}</TextStyle>
                            </div>
                        )}
                    </Fragment>
                </div>
                {((!loggedIn && serviceCount === 34) || (loggedIn && selectedBusinessDetails.lastService)) &&
                    <div className="row max-limit-reached-alert">
                        <div className="col-md-5">
                            <MessageSection
                                developmentUrl={ableSpriteSheet}
                                variant="Information"
                                description="You've almost reached the maximum number of Pre-Paid services on your account. This will be the last service you can activate." />
                        </div>
                    </div>
                }

                {isEsimActivation && (hasEsimProfileError || isProcessingProfile)
                    ? null
                    : (
                        <Fragment>
                            <div className="row">
                                {this.renderAutoRechargeEnabledCardAndOthers(
                                    serviceNumber,
                                    starterCredit,
                                    actualAmount,
                                    newOfferSelected,
                                    skipRecharge,
                                    isAutoRechargeSuccessful,
                                    autoRechargeCardProps,
                                    isEsimActivation,
                                    loggedIn,
                                    hasTdiLogin,
                                    simHeader,
                                    simContent,
                                    userEmail
                                )}
                                {!isEsimActivation && this.renderDownloadContent()}
                            </div>
                            <div className="row mt-5 mb-large">
                                <div className="col-md-5">{this.renderDirectoryListing()}</div>
                            </div>
                        </Fragment>
                    )
                }

            </div>
        );
    }

    getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge) {
        return <OfferCard {...this.props} plan={newOfferSelected} msisdn={serviceNumber} starterCredit={starterCredit} price={actualAmount} confirm={'true'} skipRecharge={skipRecharge} />;
    }

    renderRegistrationContent(simHeader, simContent) {
        return (
            <div className="mt-5">
                <TextStyle element="h2" alias="HeadingC" className="mbl">Next steps</TextStyle>
                <img className="sim-profile mbl" src={simCard} alt="" />
                <TextStyle element="h3" alias="HeadingD" className="mbm">{simHeader}</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">{simContent}</TextStyle>
                <img className="sim-profile mbl" src={boostProfile} alt="" />
                <TextStyle element="h3" alias="HeadingD" className="mbm">2. Manage your service online</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">Use your Boost ID to manage your service online.</TextStyle>
                <ActionButton element="a" href={SIGN_IN} label="Sign in" variant="LowEmphasis" className="boost-able-button" />
            </div>
        );
    }

    renderUnauthContent(simHeader, simContent, userEmail) {
        const detailMessage = '. We’ve just sent you a verification email so you can activate it. You can then access your account and manage your payment preferences.';
        return (
            <div className="mt-5">
                <TextStyle element="h2" alias="HeadingC" className="mbl">Next steps</TextStyle>
                <img className="sim-profile mbl" src={simCard} alt="" />
                <TextStyle element="h3" alias="HeadingD" className="mbm">{simHeader}</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">{simContent}</TextStyle>
                <img className="sim-profile mbl" src={boostProfile} alt="" />
                <TextStyle element="h3" alias="HeadingD" className="mbm">2. Verify your Boost ID</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">
                    {'Your Boost ID is '}
                    {userEmail}
                    {detailMessage}
                </TextStyle>

            </div>
        );
    }

    renderDownloadContent() {
        let downloadUrl = '';
        let downloadButton = 'Download';
        if (window.navigator.userAgent.indexOf('Mac') !== -1 && window.navigator.userAgent.indexOf('Mobile') !== -1) {
            downloadUrl = DOWNLOAD_APP_IOS;
        } else if (window.navigator.userAgent.indexOf('Android') !== -1 && window.navigator.userAgent.indexOf('Mobile') !== -1) {
            downloadUrl = DOWNLOAD_APP_ANDROID;
        } else {
            downloadButton = 'Download app';
            downloadUrl = GET_THE_APP;
        }
        return (
            <div className="col-md-4 col-xs-12 download-card">
                <div className="download-content">
                    <img className="my-telstra-image" src={boostAppIcon} alt="" />
                    <div className="download-myboost">
                        <TextStyle alias="HeadingD" className="heading">Download the My Boost Mobile app</TextStyle>
                    </div>
                    <div>
                        <img className="boost-image" src={boostAppImage} alt="" />
                    </div>
                    <div className="manage-text-div">
                        <TextStyle alias="TextBodyShort" className="manage-text-style">
                            Download the My Boost Mobile app to easily recharge and manage your service. Plus, log in with your Boost ID to:
                        </TextStyle>
                    </div>

                    <div role="list" className="download-list-items">
                        <TextStyle element="p" role="listitem" alias="Label" className="mbm">
                            <span className="able-boost-icon">
                                <Icon icon="Tick" aria-hidden developmentUrl={ableSpriteSheet} />
                            </span>
                            Manage your payments
                        </TextStyle>
                        <TextStyle element="p" role="listitem" alias="Label" className="mbm">
                            <span className="able-boost-icon">
                                <Icon icon="Tick" aria-hidden developmentUrl={ableSpriteSheet} />
                            </span>
                            Recharge on the go
                        </TextStyle>
                    </div>
                    <ActionButton
                        variant="MediumEmphasis"
                        label={downloadButton}
                        element="a"
                        href={downloadUrl}
                        className="w-100 mob-button downlad-app-btn boost-able-button" />
                </div>
            </div>
        );
    }

    renderDirectoryListing() {
        const header = 'Directory listing';
        // eslint-disable-next-line max-len
        const content = 'Your name, address and phone number will not be listed in the directory assistance or the printed and online public directories. You can change this in the My Boost Mobile app once your service activation is complete.';
        return (
            <div id="directory-listing">
                <MessageSection
                    developmentUrl={ableSpriteSheet}
                    variant="Information"
                    description={content}
                    titleText={header}
                    titleElement="h2"
                />
            </div>
        );
    }

    renderAutoRechargeEnabledCardAndOthers(
        serviceNumber,
        starterCredit,
        actualAmount,
        newOfferSelected,
        skipRecharge,
        isAutoRechargeSuccessful,
        autoRechargeCardProps,
        isEsimActivation,
        loggedIn,
        hasTdiLogin,
        simHeader,
        simContent,
        userEmail
    ) {
        return (
            <div className="col-md-5">
                {isEsimActivation && <TextStyle className="mb-4 mt-lg-3" element="h2" alias="HeadingC">Recharge details</TextStyle>}
                {this.getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)}

                {isAutoRechargeSuccessful && <AutoRechargeEnabledCard {...autoRechargeCardProps} />}
                {isAutoRechargeSuccessful === false && (
                    <div id="auto-recharge-fail">
                        <MessageSection
                            developmentUrl={ableSpriteSheet}
                            variant="Error"
                            description={'Please try again later on the My Boost Mobile app.'}
                            titleText={'Auto recharge setup unsuccessful'}
                            titleElement="h2"
                        />
                    </div>
                )}
                {!isEsimActivation && (loggedIn || hasTdiLogin) ? this.renderRegistrationContent(simHeader, simContent) : !isEsimActivation && this.renderUnauthContent(simHeader, simContent, userEmail)}
            </div>
        );
    }

    profileDownloadRedirect() {
        addDataLayerEventInfo('click', 'clickTrack', 'buttonClick', 'eSIM download');
        const state = store.getState();
        const {
            esimData: { activationCode, deepLinks = {} },
            msisdn
        } = state.app.appData;
        const regex = /^\+61|^61/;
        const serviceId = msisdn.replace(regex, 0);
        const redirectUrl = `${deepLinks.download}?activationCode=${activationCode}&serviceId=${serviceId}`;
        window.localStorage.removeItem('source');
        window.localStorage.removeItem('esimFlow');
        window.location.href = redirectUrl;
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    renderProfileErrorGuide(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge) {
        const {
            esimProfileError: {
                esimProfileErrortitle,
                esimProfileErrorTitleMessage,
                step1,
                step1Message,
                step2,
                step2Message,
                step3,
                step3Message,
                step4,
                step4Message,
                step5,
                step5Message,
                step6,
                step6Message
            }
        } = ACTIVATION_GUIDE_CONSTANTS;

        return (
            <div className="row m-0 esim-profile-error-guide w-100">

                <div className="col-md-6 col-sm-12">
                    <div className="error-guide-section">
                        <Spacing bottom="spacing5x" topVLG="spacing2x" topVMD="spacing2x" topVSM="spacing2x">
                            <TextStyle element="h1" alias="HeadingA">
                                {esimProfileErrortitle}
                            </TextStyle>
                        </Spacing>
                        <Spacing bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">
                                {esimProfileErrorTitleMessage}
                            </TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">
                            {step1}
                        </TextStyle>
                        <Spacing top="spacing1x" bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">
                                {step1Message}
                            </TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">
                            {step2}
                        </TextStyle>
                        <Spacing top="spacing1x" bottom="spacing2x">
                            <TextStyle alias="TextBodyShort">
                                {step2Message}
                            </TextStyle>
                        </Spacing>
                        <Spacing bottom="spacing4x">
                            <div className="esim-error-step-image">
                                <img alt="" src={step2image} />
                            </div>
                        </Spacing>

                        <TextStyle alias="HeadingC">
                            {step3}
                        </TextStyle>
                        <Spacing top="spacing1x" bottom="spacing2x">
                            <TextStyle alias="TextBodyShort">
                                {step3Message}
                            </TextStyle>
                        </Spacing>
                        <Spacing bottom="spacing4x">
                            <div className="esim-error-step-image">
                                <img alt="" src={step3image} />
                            </div>
                        </Spacing>

                        <TextStyle alias="HeadingC">
                            {step4}
                        </TextStyle>
                        <Spacing top="spacing1x" bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">
                                {step4Message}
                            </TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">
                            {step5}
                        </TextStyle>
                        <Spacing top="spacing1x" bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">
                                {step5Message}
                            </TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">
                            {step6}
                        </TextStyle>
                        <Spacing top="spacing1x" bottom="spacing15x" bottomVXS="spacing7x">
                            <TextStyle alias="TextBodyShort">
                                {step6Message}
                            </TextStyle>
                        </Spacing>

                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="error-guide-section error-guide-section--right">
                        <Spacing topVXS="spacing3x" bottom="spacing4x" bottomVXS="spacing5x" topVLG="spacing2x" topVMD="spacing2x" topVSM="spacing2x">
                            <TextStyle alias="HeadingC">
                                Recharge details
                            </TextStyle>
                        </Spacing>
                        {this.getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)}

                        <Spacing top="spacing4x" topVXS="spacing5x" bottom="spacing10x">
                            {this.renderDirectoryListing()}
                        </Spacing>
                    </div>
                </div>
            </div>
        );
    }
}
export default ActivationGuide;

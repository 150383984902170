import View from './banner';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return {
        isCoreLoaded: state.app.isAppLoaded,
        locale: state.welcome.locale,
        loading: state.welcome.loading
    };
}

export function mapDispatchToProps() {
    return {};
}

const Banner = connect(mapStateToProps, mapDispatchToProps)(View);

export default Banner;

const error = { error: 'Required field. Please search and select an address.', touched: true, submitFailed: true };

export const validateAddress = (value) => {
    if (!value) {
        return error;
    }

    if (!value.label || !value.value) {
        return error;
    }
};

import * as client from '../../../adapters/client';
import * as errorCodeMeta from '../../../adapters/errorCode';

import { VISACHECKOUT_INIT_DONE, VISACHECKOUT_PAYMENT_ERROR, VISACHECKOUT_PAYMENT_SUCCESS } from '../../../adapters/constants';
import { notifyAnalytics, requestCodeMeta } from '../../../adapters/analytics-utils';

export const initaliseVisaCheckout = data => {
    return { type: VISACHECKOUT_INIT_DONE, data };
};

export const authoriseVisaPayment = (payload, dataFactory = client.visaCheckoutRequest) => {
    return dispatch => {
        return dataFactory(dispatch, paymentAuthorised, declineVisaPayment, { payload });
    };
};

export const paymentAuthorised = data => {
    return { type: VISACHECKOUT_PAYMENT_SUCCESS, data };
};

export function declineVisaPayment(httpStatus) {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = errorCodeMeta.VISA_CHECKOUT_PAYMENT_FALIURE;
            break;
    }
    notifyAnalytics(requestCodeMeta.visaCheckoutFaliure, errorCode);
    return { type: VISACHECKOUT_PAYMENT_ERROR, errorCode };
}

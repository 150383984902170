import React from 'react';
import { Message } from '../';
import './banner.css';

/**
 * PPA Application wide Banner. This component will only work
 * if the variavle appBanner is passed in the welcome route.
 *
 * @param {any} { ...props }
 * @returns
 */
const Banner = ({ ...props }) => {
    const { loading: { appLoading }, isCoreLoaded = true } = props;
    if (appLoading && !isCoreLoaded) return false;

    const { locale: { appBanner = {} } = {} } = props;
    return (
        <div className="banner clearfix">
            <Message type={'info'} message={appBanner.message} show={appBanner.enabled || false} />
        </div>
    );
};

export default Banner;

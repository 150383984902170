import store from '../store';
import { isEmpty } from './validations';

/**
 * Returns current appData from the store
 *
 * @returns
 */
export const getAppData = () => {
    const state = store.getState();
    const {
        app: { appData = {} }
    } = state;
    return appData;
};

export const getLoggedInCustomerData = () => {
    const state = store.getState();
    const {
        app: { loggedInCustomerData = {} }
    } = state;
    return loggedInCustomerData;
};
/**
 * Returns the current UI state for application
 *
 * @returns
 */
export const getUiState = () => {
    const state = store.getState();
    const {
        app: {
            appData: { uiState = {} }
        }
    } = state;
    return uiState;
};

/**
 * Return if customer is found or not.
 *
 * @returns
 */
export const isCustomerFound = () => {
    const state = store.getState();
    const {
        app: {
            appData: { customer: { found = false } = {} }
        }
    } = state;
    return found;
};

export const getPassedEsimProps = () => {
    const state = store.getState();
    const {
        app: {
            appData: { esimData: { eid, iccid, transactionId } = {} }
        }
    } = state;
    let props = `?eid=${eid}`;
    if (!isEmpty(iccid)) props += `&iccid=${iccid}`;
    if (!isEmpty(transactionId)) props += `&transactionId=${transactionId}`;
    return props;
};

export const setEsimJourneyItem = () => {
    if (typeof Storage !== 'undefined') {
        sessionStorage.setItem('esimJourney', 'Y');
    }
};

export const removeEsimJourneyItem = () => {
    if (typeof Storage !== 'undefined') {
        sessionStorage.removeItem('esimJourney');
    }
};

export const getCurrentStepper = () => {
    const state = store.getState();
    const { stepperBar = {} } = state;
    return stepperBar;
};

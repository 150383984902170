import { UPDATE_ADDRESS_SUCCESS } from './../../../actions';

export const getIsMobileUpdated = (state) => {
    return state.yourContactDetails.updateMobileStatus.isMobileUpdated;
};

export const getIsMobileUpdateInProgress = (state) => {
    return state.yourContactDetails.updateMobileStatus.isMobileUpdateInProgress;
};

export const getErrors = (state) => {
    return state.yourContactDetails.errors;
};

export const getVerificationInProgress = (state) => {
    return state.yourContactDetails.loading.verificationInProgress;
};

export const getAddressVerificationData = (state) => {
    return state.yourContactDetails.address.verificationData;
};

export const updateAddressSuccess = (payload) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_ADDRESS_SUCCESS, payload });
    };
};

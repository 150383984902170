import React from 'react';
import { TextStyle } from '@able/react';
import GLOBAL_FOOTER from './GlobalFooterConstants';
import './globalFooter.css';

const GlobalFooter = () => {
    return (
        <div className="telstra-footer">
            <TextStyle alias="FinePrint" className="footer-container">
                <div>
                    Copyright&copy; 2020 Boost Mobile
                </div>
                <ul className="footer-list">
                    <li>
                        <a className="foot-height" href={GLOBAL_FOOTER.CRITICAL_INFORMATION_SUMMARY} target="_blank" rel="noreferrer">
                            Critical Information Summary
                        </a>
                    </li>
                    <li>
                        <a className="foot-height" href={GLOBAL_FOOTER.PRIVACY} target="_blank" rel="noreferrer">
                            Privacy
                        </a>
                    </li>
                    <li>
                        <a className="foot-height" href={GLOBAL_FOOTER.HELP_AND_SUPPORT} target="_blank" rel="noreferrer">
                            Help & Support
                        </a>
                    </li>
                </ul>
            </TextStyle>
        </div>
    );
};

export default GlobalFooter;

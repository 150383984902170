import * as client from '../../adapters/client';
import { ACTIVATION_GUIDE_INIT_DONE, ACTIVATION_GUIDE_INIT_ERROR } from '../actions';
import { getCustomerState } from '../../adapters/utils';
import { isEmpty } from '../../adapters/validations';
import { getUiState } from '../../adapters/store-utils';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false },
    locale: {}
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTIVATION_GUIDE_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true },
                locale: action.data
            };
        case ACTIVATION_GUIDE_INIT_ERROR:
            return {
                ...state,
                loading: { appLoading: false, isModuleLoaded: false }
            };

        default:
            return state;
    }
};

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 * @param {any} [dataFactory=client.fetchLocale]
 */

export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'activationguide';
    return dispatch => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

/**
 * Returns dipatch object and locale data based on
 * offer code selected by the user.
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = data => {
    const { offerCode = 'PREPAID_BEYOND_TALK', starterCredit } = getCustomerState();
    const { selectedActivationFlow = '' } = getUiState();
    const locales = Object.keys(data).map(key => data[key]);
    const viewPortType = window.innerWidth > 767 ? 'desktop' : 'mobile';
    const isPortingFlow = selectedActivationFlow === 'portNumber';
    // gets all locale scenarios based on customer state.
    // applicable items to get scenarions: offer code, view port and started credit
    const applicableScenarios = locales.filter(
        scenario =>
            scenario.rule &&
            scenario.rule.isPortingFlow === isPortingFlow &&
            scenario.rule.offerCode.indexOf(offerCode) !== -1 &&
            scenario.rule.deviceType.indexOf(viewPortType) !== -1 &&
            scenario.rule.simValue.indexOf(isEmpty(starterCredit) ? 0 : starterCredit) !== -1
    );

    const firstScenario = applicableScenarios[0];
    return { type: ACTIVATION_GUIDE_INIT_DONE, data: firstScenario.content };
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: ACTIVATION_GUIDE_INIT_ERROR, data: httpStatus };
}

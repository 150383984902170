import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export function mapStateToProps(state) {
    return { state };
}

export function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(actionCreators, dispatch) };
}

const VisaCheckout = connect(mapStateToProps, mapDispatchToProps)(View);

export default VisaCheckout;

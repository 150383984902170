import React from 'react';
import Spinner from '@mytelstra/mt-design-system/lib/Spinner';

const SpinnerCustom = (props) => {
    // const isLoading = props.isLoading;
    const size = props.size;
    const label = props.label;
    // if (!isLoading) {
    return (
        <div className="custom-spinner">
            <Spinner size={size} label={label} />
        </div>
    );
    // }
    // return null;
};

export default SpinnerCustom;

import * as client from '../../adapters/client';
import { EXISTING_NUMBER_INIT_DONE, EXISTING_NUMBER_INIT_ERROR, PORTING_VALIDATION_ERROR, PORTING_VALIDATION_SUCCESS, LOCK_STATE, FORM_FIELD_CHANGE, PORTING_RESET_BACK_NAVIGATION } from '../actions';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false, formProcessed: false, lockFields: false },
    locale: {},
    errors: { hasError: false, errorCode: null }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case EXISTING_NUMBER_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true, formProcessed: false, lockFields: false },
                locale: action.data
            };
        case EXISTING_NUMBER_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case PORTING_VALIDATION_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode }
            };
        case PORTING_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true }
            };
        case LOCK_STATE:
            return {
                ...state,
                loading: { ...state.loading, lockFields: true }
            };

        case FORM_FIELD_CHANGE:
            return action.meta.form === 'existingNumberForm' ? { ...state, errors: initialState.errors } : state;

        case PORTING_RESET_BACK_NAVIGATION:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false }
            };

        default:
            return state;
    }
};

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'existingnumber';
    return (dispatch) => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = (data) => {
    return { type: EXISTING_NUMBER_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: EXISTING_NUMBER_INIT_ERROR, data: httpStatus };
}

export function portingResetForBack() {
    return { type: PORTING_RESET_BACK_NAVIGATION };
}

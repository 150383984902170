import * as apiFetch from './apiFetch';
import { addressEndpoint, verifyAddressEndpoint, generateUUID, checkExistingTdiEndpoint, registerTdiEndpoint } from '../adapters/utils';
import { isEmpty } from '../adapters/validations';
import store from '../store';

/**
 * Fetch locale from AEM.
 *
 * @export
 * @param {any} dispatch -> redux dispatch
 * @param {any} receiveLocale -> success action
 * @param {any} receiveLocaleError -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 */
export function fetchLocale(dispatch, receiveLocale, receiveLocaleError, resourcePath, fetcher = apiFetch) {
    fetcher.fetchFromLocale(dispatch, `${resourcePath}`, receiveLocale, receiveLocaleError);
}

/**
 * Fetch package details service call middleware.
 *
 * @export
 * @param {any} dispatch
 * @param {any} receivePackageDetails -> success action
 * @param {any} receivePackageDetailsError -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function fetchPackageDetails(dispatch, receivePackageDetails, receivePackageDetailsError, resourcePath, fetcher = apiFetch) {
    return fetcher.getFromApi(dispatch, `/packages/${resourcePath}`, receivePackageDetails, receivePackageDetailsError);
}

/**
 * Check if telstra id exist or not
 *
 * @export
 * @param {*} dispatch
 * @param {*} receiveExistingCustomer
 * @param {*} receiveExistingCustomerError
 * @param {*} params
 * @param {*} [fetcher=apiFetch]
 */
export function fetchTelstraIdDetails(dispatch, receiveExistingCustomer, receiveExistingCustomerError, params, fetcher = apiFetch) {
    const basePath = checkExistingTdiEndpoint();
    const resourcePath = '/wifi/msisdn-email-validation';
    const { email = '', msisdn = '' } = params;
    let queryParam = `?email=${email}`;
    if (!isEmpty(msisdn)) queryParam += `&msisdn=${msisdn}`;
    const options = { useDefaultApi: false };
    fetcher.getFromApi(dispatch, basePath + resourcePath + queryParam, receiveExistingCustomer, receiveExistingCustomerError, options);
}

/**
 * Address Details service call middleware
 *
 * @export
 * @param {any} dispatch
 * @param {any} receiveAddressDetails -> success action
 * @param {any} receivePackageDetailsError -> error action
 * @param {any} receiveAddressDetailsError -> path
 * @param {any} [params={}] -> service call params
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function fetchAddressDetails(dispatch, receiveAddressDetails, receiveAddressDetailsError, params = {}, fetcher = apiFetch) {
    const resourcePath = addressEndpoint();
    const { addressInput = '' } = params;
    return fetcher.getFromApi(dispatch, resourcePath + addressInput, receiveAddressDetails, receiveAddressDetailsError);
}

/**
 * Address Details service call middleware
 *
 * @export
 * @param {any} dispatch
 * @param {any} receiveVerificationDetails -> success action
 * @param {any} receiveVerificationDetailsError -> error action
 * @param {any} params -> address in payload
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function fetchAddressVerification(dispatch, receiveVerificationDetails, receiveVerificationDetailsError, params, fetcher = apiFetch) {
    const resourcePath = verifyAddressEndpoint();
    return fetcher.postToApi(dispatch, resourcePath, receiveVerificationDetails, receiveVerificationDetailsError, { params });
}

/**
 * Porting Eligibility service call middleware
 *
 * @export
 * @param {any} dispatch
 * @param {any} receivePortingDetails -> success action
 * @param {any} receivePortingDetailsError -> error action
 * @param {any} params -> porting payload
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 */
export function fetchPortingEligibility(dispatch, receivePortingDetails, receivePortingDetailsError, params, fetcher = apiFetch) {
    fetcher.postToApi(dispatch, '/packages/porting', receivePortingDetails, receivePortingDetailsError, { params });
}

/**
 * Offer details service call middleware
 *
 * @export
 * @param {any} dispatch
 * @param {any} receiveOfferDetails -> success action
 * @param {any} receiveOfferDetailsError -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 */
export function fetchOfferDetails(dispatch, receiveOfferDetails, receiveOfferDetailsError, deviceType, fetcher = apiFetch) {
    fetcher.getFromApi(dispatch, `/offers/${deviceType}`, receiveOfferDetails, receiveOfferDetailsError);
}

/**
 * User Info middleware
 *
 * @export
 * @param {any} dispatch
 * @param {any} receiveUserInfo -> success action
 * @param {any} receiveUserInfoError -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function fetchUserInfo(dispatch, receiveUserInfo, receiveUserInfoError, token, fetcher = apiFetch) {
    return fetcher.fetchFromApi(dispatch, `/user/${token}`, receiveUserInfo, receiveUserInfoError);
}

/**
 * Customer validation service call middle ware
 *
 * @export
 * @param {any} dispatch
 * @param {any} receiveCustomerValidation -> success action
 * @param {any} receiveCustomerValidationError -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function fetchCustomerValidation(dispatch, receiveCustomerValidation, receiveCustomerValidationError, params, fetcher = apiFetch) {
    return fetcher.postToApi(dispatch, '/customers', receiveCustomerValidation, receiveCustomerValidationError, { params });
}

/**
 * Document validation service call middleware.
 *
 * @export
 * @param {any} dispatch
 * @param {any} receiveDocumentValidation -> success action
 * @param {any} receiveDocumentValidationError -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function fetchDocumentValidation(dispatch, receiveDocumentValidation, receiveDocumentValidationError, params, fetcher = apiFetch) {
    return fetcher.postToApi(dispatch, '/customers/document', receiveDocumentValidation, receiveDocumentValidationError, { params });
}

/**
 * Service number activation call middleware.
 *
 * @export
 * @param {any} dispatch - redux dispatch
 * @param {any} serviceActivation - success action
 * @param {any} serviceActivationError - error action
 * @param {any} params - activation params
 * @param {any} [fetcher=apiFetch] - function from apiFetch
 * @returns
 */
export function fetchServiceActivation(dispatch, serviceActivation, serviceActivationError, params, fetcher = apiFetch) {
    return fetcher.postToApi(dispatch, '/packages/activate', serviceActivation, serviceActivationError, { params });
}

export function payAndActivateSim(dispatch, activationSuccess, activationFailure, params, fetcher = apiFetch) {
    const resourcePath = `/payandactivate`;
    return fetcher.postToApi(dispatch, resourcePath, activationSuccess, activationFailure, { params });
}

export function fetchCartSubmissionDetails(dispatch, cartSubmissionSuccess, cartSubmissionFailure, params, fetcher = apiFetch) {
    const {
        data: { shoppingCartId }
    } = params;
    const resourcePath = `/shoppingcart/submitcart/${shoppingCartId}`;
    // return fetcher.postToApi(dispatch, `/shoppingcart/submitcart/${shoppingCartId}`, cartSubmissionSuccess, cartSubmissionFailure, { params });
    return fetcher.postToApi(dispatch, resourcePath, cartSubmissionSuccess, cartSubmissionFailure, { params });
}
/**
 * Service call to fetch current customer data
 * @export
 * @param {any} dispatch - redux dispatch
 * @param {any} customerDataSuccess - success action
 * @param {any} customerDataError - error action
 * @param {any} [fetcher=apiFetch]  - function from apiFetch
 * @returns
 */
export function fetchCurrentCustomer(dispatch, customerDataSuccess, customerDataError, fetcher = apiFetch) {
    const state = store.getState();
    /* eslint-disable camelcase */
    const { appData: { userAccessToken } = {} } = state.app || {};
    const headers = { Authorization: `Bearer ${userAccessToken}`, access_token_str: `${userAccessToken}` };
    return fetcher.getFromApi(dispatch, '/customers/CURRENT', customerDataSuccess, customerDataError, { headers });
}

/**
 * Service call to fetch offers based on selected device type

 */
export function fetchOffers(dispatch, fetchOfferSuccess, fetchOfferError, fetcher = apiFetch) {
    // const state = store.getState();
    // const { appData: { uiState: { selectedDevice = '', isEsimActivation } = {} } = {} } = state.app || {};
    // let searchDevice = !isEmpty(selectedDevice) ? selectedDevice.replace(/\s+/g, '-') : 'Prepaid-Mobile-3G-Handset';
    // if (isEsimActivation) {
    //     searchDevice = 'Esim';
    // }

    // replace spaces and underscores
    // const resourcePath = `/offers/${searchDevice}`;
    const resourcePath = '/rechargePlans'; // TODO-PCF: Add the base url to config
    return fetcher.getFromApi(dispatch, resourcePath, fetchOfferSuccess, fetchOfferError);
}

export function initaitePaypalRequest(dispatch, successAction, errorAction, paymentJson, path = '', fetcher = apiFetch) {
    const resourcePath = `/payments/payment${path}`;
    const headers = { 'PayPal-Client-Metadata-Id': generateUUID().replace(/[-]/g, '') };
    const options = { params: paymentJson, headers };
    return fetcher.postFromPaypal(dispatch, resourcePath, successAction, errorAction, options);
}

export function billPayRequest(dispatch, successAction, errorAction, config, fetcher = apiFetch) {
    const resourcePath = 'billpay/payments/payment';
    const { headers, payload } = config;
    const options = { params: payload, headers };
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, options);
}

export function visaCheckoutRequest(dispatch, successAction, errorAction, config, fetcher = apiFetch) {
    const resourcePath = 'billpay/payments/visacheckout';
    const { payload } = config;
    const options = { params: payload };
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, options);
}

export function registerTelstraId(dispatch, successAction, errorAction, params, fetcher = apiFetch) {
    const basePath = registerTdiEndpoint();
    const resourcePath = '/user-registration/users';
    const options = { params, useDefaultApi: false, headers: { 'ds-source-system': 'telstra-ppa' } };
    return fetcher.postToApi(dispatch, basePath + resourcePath, successAction, errorAction, options);
}

export function checkEsimFreeOfferEligibility(dispatch, successAction, errorAction, params, fetcher = apiFetch) {
    const { eid = '', iccid = '', transactionId = '', imei } = params;
    const resourcePath = '/esim/eligibility';
    const queryParam = `?eid=${eid}&iccid=${iccid}&transactionId=${transactionId}&imei=${imei}`;
    return fetcher.getFromApi(dispatch, resourcePath + queryParam, successAction, errorAction);
}

export function reserveMsisdn(dispatch, successAction, errorAction, fetcher = apiFetch) {
    const resourcePath = '/esim/reservation';
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction);
}

/**
 * Create Shopping Cart
 *
 * @export
 * @param {*} dispatch
 * @param {*} shoppingCartCreationSuccess
 * @param {*} shoppingCartCreationError
 * @param {*} params
 * @param {*} [fetcher=apiFetch]
 */
export function createShoppingCartData(dispatch, shoppingCartCreationSuccess, shoppingCartCreationError, params, fetcher = apiFetch) {
    const options = { params };
    fetcher.postToApi(dispatch, '/shoppingcart/createcart', shoppingCartCreationSuccess, shoppingCartCreationError, options);
}

/**
 * Fetch Branintree Authorization Code
 *
 * @export
 * @param {*} dispatch
 * @param {*} successAction
 * @param {*} errorAction
 * @param {*} params
 * @param {*} [fetcher=apiFetch]
 */
export async function fetchBraintreeAuthorizationToken(dispatch, successAction, errorAction, fetcher = apiFetch) {
    return fetcher.getFromApi(dispatch, '/payment/token', successAction, errorAction);
}

export function adhocPayBraintreeRequest(dispatch, successAction, errorAction, data, fetcher = apiFetch) {
    const resourcePath = '/payment/adhoc';
    // const { headers, payload } ;
    const options = { params: { data } };
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, options);
}
export function fetchSavedCardList(dispatch, successAction, errorAction, fetcher = apiFetch) {
    const resourcePath = '/payment/savedCard';
    fetcher.getFromApi(dispatch, resourcePath, successAction, errorAction);
}

export function updateCartData(dispatch, successAction, errorAction, payload, fetcher = apiFetch) {
    const {
        data: { shoppingCartId }
    } = payload;
    // const { headers, payload } ;
    const options = { params: payload };
    return fetcher.postToApi(dispatch, `shoppingcart/submitcart/cart/${shoppingCartId}`, successAction, errorAction, options);
}

/**
 * Fetch preport verification service call middleware.
 *
 * @export
 * @param {any} dispatch
 * @param {any} successAction -> success action
 * @param {any} errorAction -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function prePortVerification(dispatch, successAction, errorAction, mobileNumber, fetcher = apiFetch) {
    const resourcePath = '/numberportability/msisdn?query=';
    return fetcher.getFromApi(dispatch, resourcePath + mobileNumber, successAction, errorAction);
}
/**
 * Fetch preport verification service call middleware.
 *
 * @export
 * @param {any} dispatch
 * @param {any} successAction -> success action
 * @param {any} errorAction -> error action
 * @param {any} resourcePath -> path
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function verifySmsCode(dispatch, successAction, errorAction, params, fetcher = apiFetch) {
    const resourcePath = '/ppv/verify-code';
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, { params });
}

/**
 * Save Auto Recharge for value sim/Create payment wallet
 *
 * @export
 * @param {any} dispatch
 * @param {any}  -> success action
 * @param {any}  -> error action
 * @param {any} params -> payment token in payload
 * @param {any} [fetcher=apiFetch] -> function from apiFetch
 * @returns
 */
export function saveAutoRechargeforValueSim(dispatch, successAction, errorAction, data, fetcher = apiFetch) {
    const resourcePath = '/payment/wallet';
    const options = { params: { data } };
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, options);
}

export function retrieveParams(dispatch, successAction, errorAction, params, fetcher = apiFetch) {
    const resourcePath = '/esim/retrieve-parameters';
    const headers = { 'x-request-application': 'BPPA-ESIM' };
    const options = { params, headers };
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, options);
}

export function requestMsisdn(dispatch, successAction, errorAction, fetcher = apiFetch) {
    const resourcePath = '/esim/request-msisdn';
    return fetcher.getFromApi(dispatch, resourcePath, successAction, errorAction);
}

export function esimProfileDownload(dispatch, successAction, errorAction, fetcher = apiFetch) {
    const resourcePath = '/esim/profile';
    return fetcher.getFromApi(dispatch, resourcePath, successAction, errorAction);
}

export function sendOneTimeCode(dispatch, successAction, errorAction, data, fetcher = apiFetch) {
    const resourcePath = '/otc/send';
    const options = { params: { data } };
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, options);
}

export function verifyOneTimeCode(dispatch, successAction, errorAction, params, fetcher = apiFetch) {
    const resourcePath = '/otc/verify';
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, { params });
}

export function updateContactMedium(dispatch, successAction, errorAction, params, fetcher = apiFetch) {
    const resourcePath = '/contactmedia';
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, { params });
}

export function verifyABNOrACN(dispatch, successAction, errorAction, param, fetcher = apiFetch) {
    const resourcePath = '/customers/business';
    const queryParam = `?acnOrAbn=${param}`;
    return fetcher.getFromApi(dispatch, resourcePath + queryParam, successAction, errorAction, param);
}

export function createSMBAccount(dispatch, successAction, errorAction, payload, fetcher = apiFetch) {
    const resourcePath = '/customers/business/abn';
    return fetcher.postToApi(dispatch, resourcePath, successAction, errorAction, { params: payload });
}

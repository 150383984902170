import { updateStepperCustomData } from '../../../../components/Progress/module';

const getState = (state) => {
    return state.app.appData;
};

export const getAddressId = (state) => {
    return getState(state).address.adborAddressId;
};

export const getAddressLine1 = (state) => {
    return getState(state).address.addressline1;
};

export const getAddressLine2 = (state) => {
    return getState(state).address.addressline2;
};

export const getAddressLine3 = (state) => {
    return getState(state).address.addressline3;
};

export const getLocalityName = (state) => {
    return getState(state).address.localityName;
};

export const getCustomerState = (state) => {
    return getState(state).address.customerState;
};

export const getPostCode = (state) => {
    return getState(state).address.postcode;
};

export const getPhoneNumber = (state) => {
    return getState(state).customerInfo.phoneNumber;
};

export const getContactEmail = (state) => {
    return getState(state).customerInfo.contactEmail;
};

export const getLevelOfAuthentication = (state) => {
    return getState(state).levelOfAuthentication;
};

export const getSelectedBusiness = (state) => {
    return state.yourContactDetails.selectedBusiness.selectedBusiness;
};

export const getSubmitSMBAccountMeta = (state) => {
    return state.yourContactDetails.submitSMBAccountMeta;
};

export const getAddressSMBVerificationMeta = (state) => {
    return state.yourContactDetails.addressVerificationMeta;
};

export const getUpdateBusinessContactDetailsErrorState = (state) => {
    return state.yourContactDetails.updateBusinessContactsFormErrors;
};

export const getSelectedBusinessDetails = state =>
    state.app.loggedInCustomerData
    && state.app.loggedInCustomerData.businessDetails
        .find(item => item.accountNumber === state.yourContactDetails.selectedBusiness.selectedBusiness);


export { updateStepperCustomData };

import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validatePortingDetails } from '../app/actions';
import { updateStepperCustomData } from '../../components/Progress/module';

export function mapStateToProps(state) {
    return {
        appData: state.app.appData,
        loading: state.existingNumber.loading,
        locale: state.existingNumber.locale,
        errors: state.existingNumber.errors,
        loggedInCustomerData: state.app.loggedInCustomerData
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { validatePortingDetails, updateStepperCustomData });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const existingNumber = connect(mapStateToProps, mapDispatchToProps)(View);

export default existingNumber;

import './progress.css';
import React, { useState, useEffect } from 'react';
import GoBackButton from '../GoBackButton';

const activationSteps = [
    {
        id: '1',
        name: 'SIM details'
    },
    {
        id: '2',
        name: 'Service details'
    },
    {
        id: '3',
        name: 'ID details'
    },
    {
        id: '4',
        name: 'Contact details'
    },
    {
        id: '5',
        name: 'Recharge'
    },
    {
        id: '6',
        name: 'Review & payment'
    },
    {
        id: '7',
        name: 'Download profile'
    }
];

const Progress = (props) => {
    const {
        stepperBar: { totalSteps = '', currentStep = '', isLastStepCompleted = true },
        hasErrorOccurred = false, hasServiceError = false,
        appData: {
            uiState: { isEsimActivation, isEsimAuth, isMfafromEdit },
            esimData: { customerAccountId = '' },
            customer: {
                data: { matchedContact }
            }
        },
        yourContactDetails: {
            phoneNumVerificationLimitInfo: { limitReached },
            updateMobileStatus: {
                didUserUpdateMobile,
                unAuthUpdateAddNumberSkip
            },
            errors: {
                hasErrorOccurred: ycdHasErrorOccurred
            } = {}
        },
        router: {
            location: {
                pathname
            }
        }
    } = props;
    const selectedStep = currentStep;
    const steps = [];
    let currentStepDescription;
    let step;
    const hideCondition = hasErrorOccurred || hasServiceError || (!customerAccountId && isEsimAuth);
    const hideBackBtnCondition = isMfafromEdit && currentStep === 4;
    const hideBackBtn = hideBackBtnCondition ? 'hide' : '';
    const hideMe = hideCondition ? 'hide' : isLastStepCompleted ? (isEsimActivation ? '' : 'hide') : '';

    const [screenWidth, setWindowWidth] = useState(0);
    const resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener('resize', resizeWindow);
        return () => window.removeEventListener('resize', resizeWindow);
    }, []);

    const shouldShowProgress = () => {
        if (pathname === '/your-contact-details' && matchedContact && !didUserUpdateMobile && !unAuthUpdateAddNumberSkip) {
            return false;
        }
        if (pathname === '/your-contact-details' && limitReached) {
            return false;
        }
        if (ycdHasErrorOccurred) {
            return false;
        }
        return true;
    };

    // Check screen Size: <<<Desktop>>>
    if (window.screen.width > 768) {
        if (!isLastStepCompleted) {
            for (let i = 0; i < totalSteps; i += 1) {
                const titleMargin = `titleMargin-${i}`;
                if (i < selectedStep - 1) {
                    // completed
                    steps.push(
                        <div className="step" role="listitem" key={activationSteps[i].id}>
                            <div className="step-wrapp">
                                <div className="step-number step-number-selected visited">
                                    <svg className="tick" role="img" aria-label="Step completed" />
                                </div>
                                {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                            </div>
                            <span className={`step-name ${titleMargin}`}>{activationSteps[i].name}</span>
                        </div>
                    );
                } else if (i === selectedStep - 1) {
                    // current
                    step = i + 1;
                    const titleName = activationSteps[i].name === 'Download profile' ? 'Download' : activationSteps[i].name;
                    currentStepDescription = `Step ${String(step)} of ${String(totalSteps)} ${String(activationSteps[i].name)}`;
                    steps.push(
                        <div className="step" role="listitem" key={activationSteps[i].id} aria-live="polite" aria-atomic="true" aria-label={currentStepDescription}>
                            <div className="step-wrapp">
                                <div className="current step-number step-number-selected">{activationSteps[i].id}</div>
                                {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                            </div>
                            <span className={`step-name ${titleName === 'Review' || titleName === 'Download' ? `titleMargin-${i}_1` : `titleMargin-${i}`}`}>{titleName}</span>
                        </div>
                    );
                } else {
                    // next
                    steps.push(
                        <div className="step" role="listitem" key={activationSteps[i].id}>
                            <div className="step-wrapp">
                                <div className="step-number">{activationSteps[i].id}</div>
                                {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                            </div>
                            <span className={`step-name ${titleMargin}`}>{activationSteps[i].name}</span>
                        </div>
                    );
                }
            }
        } else {
            for (let i = 0; i < totalSteps; i += 1) {
                const titleMargin = `titleMargin-${i}`;
                steps.push(
                    <div className="step" role="listitem" key={activationSteps[i].id}>
                        <div className="step-wrapp">
                            {isEsimActivation && activationSteps[i].name === 'Download profile' ? (
                                <div className="current step-number step-number-selected">{activationSteps[i].id}</div>
                            ) : (
                                <div className="step-number step-number-selected visited">
                                    <svg className="tick" role="img" aria-label="Step completed" />
                                </div>
                            )}
                            {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                        </div>
                        <span className={`step-name ${titleMargin}`}>{activationSteps[i].name}</span>
                    </div>
                );
            }
        }
    }

    if (!shouldShowProgress()) {
        return false;
    }

    return (
        <div className={`${hideMe}`}>
            <div className={`${hideMe} ppa-progress`}>
                <div className={`${screenWidth > 768 ? 'container' : 'row'} `}>
                    <div role="list" className="progress-info">
                        {screenWidth > 768 ? steps : renderMobileDevice(selectedStep, totalSteps, isLastStepCompleted, isEsimActivation)}{' '}
                    </div>
                </div>
            </div>
            <div className={`${hideBackBtn}`}>
                {selectedStep > 1 && selectedStep < 7 && <GoBackButton goPrevious />}
            </div>

        </div>
    );
};

export default Progress;

const renderMobileDevice = (selectedStep, totalSteps, isLastStepCompleted, isEsimActivation) => {
    const steps = [];
    let currentStepDescription;
    let selected = selectedStep;
    let updatedtotalStep = totalSteps;

    // 1st and 2nd element
    if (selectedStep === 1 || selectedStep === 2) {
        selected = 0;
        updatedtotalStep = isEsimActivation ? totalSteps - 4 : totalSteps - 3;
    } else {
        selected -= 2;
        updatedtotalStep = selectedStep + 1;
    }

    // last and 2nd last element
    if (selectedStep === 5 || selectedStep === 6) {
        selected = 3;
        updatedtotalStep = isEsimActivation ? totalSteps - 1 : totalSteps;
    }

    // To create Dummy div before step-1
    if (selectedStep === 1 || selectedStep === 2) {
        steps.push(<div className="dummy-horizontal-line" key={`${selectedStep}-line`} />);
    } else {
        steps.push(<div className="horizontal-line" key={`${selectedStep}-line`} />);
    }

    if (!isLastStepCompleted) {
        for (let i = selected; i < updatedtotalStep; i += 1) {
            const titleMargin = `titleMargin-${i}`;
            const titleName = activationSteps[i].name === 'Download profile' ? 'Download' : activationSteps[i].name;
            if (i < selectedStep - 1) {
                steps.push(
                    <div className="step" role="listitem" key={activationSteps[i].id}>
                        <div className="step-wrapp">
                            <div className="step-number step-number-selected visited">
                                <svg className="tick" role="img" aria-label="Step completed" />
                            </div>
                            {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                        </div>
                        <span className={`step-name ${titleMargin}`}>{titleName}</span>
                    </div>
                );
            } else if (i === selectedStep - 1) {
                steps.push(
                    <div className="step" role="listitem" key={activationSteps[i].id} aria-live="polite" aria-atomic="true" aria-label={currentStepDescription}>
                        <div className="step-wrapp">
                            <div className="step-number step-number-selected current">{activationSteps[i].id}</div>
                            {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                        </div>
                        <span className={`step-name ${titleMargin}`}>{titleName}</span>
                    </div>
                );
            } else {
                steps.push(
                    <div className="step" role="listitem" key={activationSteps[i].id}>
                        <div className="step-wrapp">
                            {i !== 1 && i === selected ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                            <div className="step-number">{activationSteps[i].id}</div>
                            {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                        </div>
                        <span className={`step-name ${titleMargin}`}>{titleName}</span>
                    </div>
                );
            }
        }
    } else {
        const startFrom = isEsimActivation ? 4 : 3;
        for (let i = startFrom; i < totalSteps; i += 1) {
            const titleMargin = `titleMargin-${i}`;
            const titleName = activationSteps[i].name === 'Download profile' ? 'Download' : activationSteps[i].name;
            steps.push(
                <div className="step" role="listitem" key={activationSteps[i].id}>
                    <div className="step-wrapp">
                        {isEsimActivation && activationSteps[i].name === 'Download profile' ? (
                            <div className="current step-number step-number-selected">{activationSteps[i].id}</div>
                        ) : (
                            <div className="step-number step-number-selected visited">
                                <svg className="tick" role="img" aria-label="Step completed" />
                            </div>
                        )}
                        {i < totalSteps - 1 ? <div className="horizontal-line" key={`${i}-line`} /> : null}
                    </div>
                    <span className={`step-name ${titleMargin}`}>{titleName}</span>
                </div>
            );
        }
    }
    return steps;
};

import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { activateSim } from '../app/actions';
import { updateStepperCustomData } from '../../components/Progress/module';

export function mapStateToProps(state) {
    return {
        app: state.app,
        appData: state.app.appData,
        loading: state.reviewDetails.loading,
        locale: state.reviewDetails.locale,
        errors: state.reviewDetails.errors,
        activationData: state.reviewDetails.activationData
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { updateStepperCustomData, activateSim });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const reviewDetails = connect(mapStateToProps, mapDispatchToProps)(View);

export default reviewDetails;

export const getOtcError = (verificationCode) => {
    let error = '';
    const num = Number(verificationCode);
    if (isNaN(num) || !verificationCode) {
        error = 'Required field, please enter code.';
    } else if (num < 100000 || num > 999999) {
        error = 'Not a valid code. Please try again.';
    }
    return error;
};

import React, { Fragment, useRef, useState } from 'react';
import AUTORECHARGE_LOCALE from '../../assets/locale/autorecharge-locale';
import { ActionButton, Icon, ModalContent, TextStyle } from '@able/react';
import './learmoremodal.css';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { getAbleSpriteSheet } from '../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();
export default function LearnMoreModal() {

    const [modalIsOpen, setModalVisibility] = useState(false);
    const learnMoreLinkRef = useRef(null);

    const openModal = () => {
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'learn more');
        addDataLayerEventInfo('impression', 'auto recharge module', '', 'What is auto recharge?');
        setModalVisibility(true);
    }

    const closeModal = () => {
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'Got it');
        setModalVisibility(false);
        learnMoreLinkRef.current.focus?.();
    }

    const {
        LearnMoreModal: { para1 = '', para2 = '', para3 = '', learnMoreText = '', btnText = '', title = '' }
    } = AUTORECHARGE_LOCALE;

    const BodyContent = () => {
        return (
            <Fragment>
                <TextStyle className="mtxxl" alias="TextBodyShort">
                    {para1}
                </TextStyle>
                <TextStyle className="mtxxl" alias="TextBodyShort">
                    {para2}
                </TextStyle>
                <TextStyle className="mtxxl mbxxl" alias="TextBodyShort">
                    {para3}
                </TextStyle>
            </Fragment>
        );
    };

    const FooterContent = () => {
        return (
            <ActionButton
                label={btnText}
                variant='MediumEmphasis'
                element='button'
                className='ml-auto boost-able-button'
                onClick={closeModal} />
        );
    };

    return (
        <Fragment>
            <a id="learn-more-link" ref={learnMoreLinkRef} tabIndex="0" role="button" onClick={openModal} target="_blank">
                {learnMoreText}
            </a>
            <span>.</span>
            <ModalContent
                className='learn-more-modal'
                title={title}
                bodyContent={<BodyContent />}
                footerContent={<FooterContent />}
                picto={<Icon icon='RecurringPayment' developmentUrl={ableSpriteSheet} />}
                variant='Comfortable'
                isShowing={modalIsOpen}
                focusOnCloseRef={learnMoreLinkRef}
                developmentUrl={ableSpriteSheet}
                setHideDialog={closeModal}
            />
        </Fragment>
    );
}

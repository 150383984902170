import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from './module';
import View from './presenter';
import { requestEsimMsisdn } from '../app/esim-actions';
import { activateSim, undoRechargeAndActivate } from '../app/actions';

export function mapStateToProps(state) {
    return {
        appData: state.app.appData,
        loading: state.payment.loading,
        locale: state.payment.locale,
        errors: state.payment.errors,
        hostedFields: state.payment.hostedFields,
        braintree: state.payment.braintree,
        deviceData: state.welcome.deviceData
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { requestEsimMsisdn, activateSim, undoRechargeAndActivate });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const payment = connect(mapStateToProps, mapDispatchToProps)(View);

export default payment;

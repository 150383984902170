import React, { Fragment, useEffect, useRef, useState } from 'react';
import { TextStyle, ActionButton, MessageInline, RadioGroup, RadioButton, MessageSection } from '@able/react';
import './businessRegistration.css';
import { Field, reduxForm, getFormMeta, getFormValues, getFormSyncErrors, registerField, unregisterField } from 'redux-form';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import { AbleTextField } from '../../../../components/form/AbleTextField';
import { getAbleSpriteSheet, submitFailure } from '../../../../adapters/utils';
import { isEmpty, isValidAbnOrAcn, email as validateEmail, isValidBusinessPhoneNumber } from '../../../../adapters/validations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verifyABNOrACN, verifyABNOrACNReset, verifyABNOrACNLimitExceeded, removeInvalidABNMessage } from '../../module';
import { Error } from '../../../../components';
import { BusinessContactDetails } from '../businessContactDetails/businessContactDetails';
import { EditBusinessContactDetails } from '../editBusinessContactDetails/editBusinessContactDetails';
import { NONPHYSICAL_ADDRESS_ERROR } from '../../../../adapters/errorCode';
import BusinessRegistrationReadOnly from '../businessRegistrationReadOnly/businessRegistrationReadOnly';
import reactDom from 'react-dom';
import { addDataLayerEventInfo } from '../../../../adapters/analytics-utils';

const ableSpriteSheet = getAbleSpriteSheet();

const { ABN, soleTrader, ABNValidation, businessNameSelect, businessSelect: { maxedOutServices, allNonVerifiedAccounts }, businessRegistrationFormFields, businessContactForm } = CONTACT_PAGE_CONSTANTS;

export function BusinessRegistrationForm({
    businessReigstrationMeta: {
        showVerifyFormErrorMessage,
        isABNOrACNValid,
        abnOrAcnError,
        businessDetails
    } = {},
    hasMaxedOutServices,
    initialBusinessDetails = [],
    showUnverfiedBusinessError,
    formData = {},
    formDataErrors = {},
    disabled,
    isReadOnlyForm,
    handleSubmit,
    selectedExistingBusiness,
    createdAccountNumber,
    actions: {
        verifyABNOrACNAction, verifyABNOrACNResetAction,
        verifyABNOrACNLimitExceededAction,
        registerFieldAction, unregisterFieldAction
    },
    ...props
}) {
    const [timesVerifyClicked, setTimesVerifyClicked] = useState(0);
    const domReadyRef = useRef();

    const verify = () => {
        setTimesVerifyClicked(state => state + 1);
        if (timesVerifyClicked >= 3) {
            // set error message
            verifyABNOrACNLimitExceededAction();
            return;
        }
        verifyABNOrACNAction(formData.ABN);
    };

    React.useEffect(() => {
        domReadyRef.current = true;
    }, []);

    React.useEffect(() => {
        if (showVerifyFormErrorMessage) {
            if(!formData.ABN) {
                handleSubmit(() => { })();
            }
            addDataLayerEventInfo('error', 'Inline', 'unavailable', 'Required selection. Please verify to continue.' );
        }
    }, [showVerifyFormErrorMessage]);

    const { businessNames } = businessDetails || {};

    if (selectedExistingBusiness) {
        return (
            <div className="contact-details-business-registration">
                <EditBusinessContactDetails disabled={disabled} {...props} />
            </div>
        );
    }

    if (isReadOnlyForm) {
        return (
            <Fragment>
                <BusinessRegistrationReadOnly
                    businessName={formData.businessName}
                    abn={formData.ABN}
                    accountNumber={createdAccountNumber}
                    legalName={businessDetails.legalName}
                />
                <BusinessContactDetails {...props} disabled={disabled} isReadOnlyForm={isReadOnlyForm} />
            </Fragment>
        );
    }

    return (
        <div className="contact-details-business-registration">
            {(isABNOrACNValid === false || abnOrAcnError) &&
                <div tabIndex="-1" id="invalidAbnError">
                    <MessageInline className="mb-3" variant="Error" description={abnOrAcnError} developmentUrl={ableSpriteSheet} />
                </div>
            }
            {hasMaxedOutServices > 0 && initialBusinessDetails.length === hasMaxedOutServices &&
                <MessageSection className="mb-3" variant="Information" developmentUrl={ableSpriteSheet} description={maxedOutServices.oneService} />
            }
            {
                showUnverfiedBusinessError &&
                <MessageSection className="mb-3" variant="Information" developmentUrl={ableSpriteSheet} description={allNonVerifiedAccounts} />
            }
            <TextStyle element="h2" alias="HeadingC" className="business-subheading mb-2">{soleTrader.title}</TextStyle>
            <TextStyle className="mb-3" alias="TextBodyShort">{soleTrader.subTitle}</TextStyle>
            {
                isABNOrACNValid && (
                    <div className="abn-acn-success-container">
                        <TextStyle alias="Label">ABN or ACN</TextStyle>
                        <TextStyle alias="TextBodyShort">{formData.ABN}</TextStyle>
                        <ActionButton element="button" variant="LowEmphasis" className="boost-able-button" id="verifyDifferentABNButton" label="Verify a different ABN or ACN" onClick={verifyABNOrACNResetAction} />
                        {domReadyRef.current && reactDom.createPortal(ABNValidation.success, document.getElementById('ycd-ariaLive-slot2'))}
                        <MessageInline variant="Success" description={ABNValidation.success} developmentUrl={ableSpriteSheet} />
                        <TextStyle alias="HeadingC" className="business-subheading">Business name</TextStyle>
                        <TextStyle alias="Label">Entity (legal) name</TextStyle>
                        <TextStyle alias="TextBodyShort">{businessDetails.legalName}</TextStyle>
                        {
                            businessNames && businessNames.length === 1 &&
                            <React.Fragment>
                                <TextStyle className="mt-2" alias="Label">Business name</TextStyle>
                                <TextStyle alias="TextBodyShort">{formData.businessName}</TextStyle>
                            </React.Fragment>
                        }
                        {
                            businessNames && businessNames.length > 1 &&
                            <SelectBusinessName disabled={disabled} {...{ formDataErrors, registerFieldAction, unregisterFieldAction, businessNames, formData, ...props }} />
                        }
                        {formData.businessName ? <BusinessContactDetails disabled={disabled} {...props} /> : ''}
                    </div>
                )
            }
            {
                !isABNOrACNValid && (<div className="mb-1">
                    <Field
                        name="ABN"
                        maxLength={11}
                        component={AbleTextField}
                        disabled={disabled}
                        id="ABN"
                        helpText={ABN.tipMessage}
                        label={ABN.label}
                        required
                    />
                    <TextStyle element="h2" className="mt-3 business-subheading" alias="HeadingC">{soleTrader.verifyTitle}</TextStyle>
                    <TextStyle className="mb-3" alias="TextBodyShort">{soleTrader.verifySubTitle}</TextStyle>
                    <ActionButton element="button" onClick={handleSubmit(verify)} className="verifyABN-button boost-button--medium-emphasis my-2" variant="MediumEmphasis" label={soleTrader.verifyButtonText} />
                    {showVerifyFormErrorMessage && <Error isValidationError errorDescriptionId="verifyABN" errorText="Required selection. Please verify to continue." />}
                </div>)
            }
        </div>
    );
}

function SelectBusinessName({ formData, formDataErrors, businessNames, registerFieldAction, unregisterFieldAction, formMeta = {}, ...props }) {
    React.useEffect(() => {
        registerFieldAction('businessRegistrationForm', 'businessName', 'Field');
        return () => {
            unregisterFieldAction('businessRegistrationForm', 'businessName');
        };
    }, []);

    const onSelectBusinessName = (event) => {
        if (props.disabled) return;
        const { currentTarget: { value } } = event;
        props.change('businessName', value);
    };

    const { businessName: { touched = false } = {} } = formMeta;

    useEffect(()=>{
        if(touched && formDataErrors.businessName){
        addDataLayerEventInfo('error', 'Inline', 'unavailable',formDataErrors.businessName );
        }
    },[touched,formDataErrors.businessName])
    return (
        <div id="business-name-select-container">
            <RadioGroup
                legendId="businessNameLegend"
                groupLabel={businessNameSelect.title}
                helpText={businessNameSelect.brief}
                invalid={touched && formDataErrors.businessName}
                errorText={formDataErrors.businessName}
                id="businessName"
                name="businessName"
                variant="Comfortable"
                developmentUrl={ableSpriteSheet}
            >
                {
                    businessNames.map((item) => {
                        return (
                            <RadioButton
                                className="able-boost-radio"
                                key={item}
                                id={item}
                                checked={formData.businessName === item}
                                value={item}
                                label={item}
                                events={{ onClick: onSelectBusinessName }}
                            />
                        );
                    })
                }
            </RadioGroup>
        </div>
    );
}

function validate(values, props) {
    const doesSelectedExistingBusinessAccount = props.loggedIn && props.existingBusinessAccount && props.existingBusinessAccount !== 'createNew';
    const { Address, Email, Phone } = businessContactForm;
    const errors = {};
    if (!doesSelectedExistingBusinessAccount) {
        if (isEmpty(values.ABN)) {
            errors.ABN = ABN.errorMessages.emptyField;
        } else if (!isValidAbnOrAcn(values.ABN)) {
            errors.ABN = ABN.errorMessages.invalidField;
        }
        if (isEmpty(values.businessName)) {
            errors.businessName = businessNameSelect.errorMessages.emptyField;
        }
    }
    if (!errors.ABN || doesSelectedExistingBusinessAccount) {
        if (isEmpty(values.businessPhoneNumber)) {
            errors.businessPhoneNumber = Phone.errorMessages.emptyField;
        } else if (!isValidBusinessPhoneNumber(values.businessPhoneNumber)) { errors.businessPhoneNumber = Phone.errorMessages.invalidField; }
        if (!values.businessContactSameAsPersonalContact) {
            if (isEmpty(values.businessEmail)) {
                errors.businessEmail = Email.errorMessages.emptyField;
            } else if (validateEmail(values.businessEmail)) {
                errors.businessEmail = Email.errorMessages.invalidField;
            }
            if (props.addressVerificationMeta.errorCode === NONPHYSICAL_ADDRESS_ERROR) {
                errors.businessAddress = Address.errorMessages.nonPhysicalAddress;
            } else if (isEmpty(values.businessAddress)) {
                errors.businessAddress = Address.errorMessages.emptyField;
            }
        }
    }
    return errors;
}

const onChange = (values, dispatch, props, prevValues) => {
    const { actions: { removeInvalidABNMessageAction }, businessReigstrationMeta: { showVerifyFormErrorMessage } } = props;
    if (!showVerifyFormErrorMessage && values.ABN !== prevValues.ABN) {
        removeInvalidABNMessageAction();
    }
};

const BusinessRegistrationReduxForm = reduxForm({
    form: 'businessRegistrationForm',
    destroyOnUnmount: false,
    validate,
    onChange,
    onSubmit: () => { },
    fields: businessRegistrationFormFields,
    onSubmitFail: submitFailure(businessRegistrationFormFields),
    enableReinitialize: true
})(BusinessRegistrationForm);

export const BusinessRegistration = connect(state => ({
    businessReigstrationMeta: state.yourContactDetails.businessReigstrationMeta,
    addressVerificationMeta: state.yourContactDetails.addressVerificationMeta,
    hasMaxedOutServices: state.yourContactDetails.selectedBusiness.hasMaxedOutServices,
    initialBusinessDetails: state.app.loggedInCustomerData && state.app.loggedInCustomerData.businessDetails,
    showUnverfiedBusinessError: state.app.loggedInCustomerData && state.app.loggedInCustomerData.showUnverfiedBusinessError,
    formDataErrors: getFormSyncErrors('businessRegistrationForm')(state) || {},
    formData: getFormValues('businessRegistrationForm')(state) || {},
    formMeta: getFormMeta('businessRegistrationForm')(state) || {},
    existingBusinessAccount: state.yourContactDetails.selectedBusiness.selectedBusiness,
    createdAccountNumber: state.app.appData.customerInfo && state.app.appData.customerInfo.businessAccountNumber,
    loggedIn: state.app.appData.uiState.loggedIn
}), dispatch => ({
    actions: bindActionCreators({
        verifyABNOrACNAction: verifyABNOrACN,
        verifyABNOrACNResetAction: verifyABNOrACNReset,
        removeInvalidABNMessageAction: removeInvalidABNMessage,
        registerFieldAction: registerField,
        unregisterFieldAction: unregisterField,
        verifyABNOrACNLimitExceededAction: verifyABNOrACNLimitExceeded
    }, dispatch)
}))(BusinessRegistrationReduxForm);

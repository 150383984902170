import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateCustomerDetails } from '../app/actions';
import { verifyBusinessAddress } from './module';
import { updateStepperCustomData } from '../../components/Progress/module';
import { formValueSelector } from 'redux-form';

export function mapStateToProps(state) {
    return {
        app: state.app,
        appData: state.app.appData,
        loading: state.yourContactDetails.loading,
        locale: state.yourContactDetails.locale,
        errors: state.yourContactDetails.errors,
        address: state.yourContactDetails.address,
        showUpdateContactModal: state.yourContactDetails.showUpdateContactModal,
        validations: state.yourContactDetails.validations,
        editContact: state.yourContactDetails.editContact,
        updateMobileStatus: state.yourContactDetails.updateMobileStatus,
        businessReigstrationMeta: state.yourContactDetails.businessReigstrationMeta,
        personalEmailVerificationMeta: state.yourContactDetails.personalEmailVerificationMeta,
        phoneNumVerificationLimitInfo: state.yourContactDetails.phoneNumVerificationLimitInfo,
        emailAddress: formValueSelector('yourContactDetailsForm')(state, 'emailAddress') || '',
        welcome: state.welcome
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { validateCustomerDetails, updateStepperCustomData, verifyBusinessAddress });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const yourContactDetails = connect(mapStateToProps, mapDispatchToProps)(View);

export default yourContactDetails;

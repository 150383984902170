import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { downloadEsimProfile } from '../app/esim-actions';

export function mapStateToProps(state) {
    return {
        app: state.app,
        appData: state.app.appData,
        loading: state.activationGuide.loading,
        locale: state.activationGuide.locale,
        selectedBusiness: state.yourContactDetails.selectedBusiness.selectedBusiness
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { downloadEsimProfile });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const activationGuide = connect(mapStateToProps, mapDispatchToProps)(View);

export default activationGuide;

import './debugger.css';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { Select } from '../';
import { applicationFlow } from '../../adapters/findNextRoute';

// Element setting not needed in testing mode
if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#ppa');
}

class DebuggerModal extends Component {
    constructor() {
        super();
        this.state = { selectedValue: '' };
        this.handleRouteChange = this.handleRouteChange.bind(this);
    }

    render() {
        const routes = applicationFlow.map(appRoute => ({ label: appRoute.pageName, value: appRoute.path }));
        const { navigate, toggleDebugger } = this.props.actions;
        const { app: { appData: { uiState: { isDebuggerOpen = false } = {} } = {} } = {} } = this.props.state;

        return (
            <span className="clearfix">
                <button aria-label="Open Settings" className="debugger-button clearfix td-icon icon-settings" onClick={toggleDebugger} />
                <Modal isOpen={isDebuggerOpen} contentLabel="Debugger Modal" onRequestClose={toggleDebugger}>
                    <div className="row">
                        <button className="close" aria-label="Close button" onClick={toggleDebugger} />
                        <div className="col-md-8">
                            <Select name="route" label="Page Name" options={routes} value={this.state.selectedValue} onChange={this.handleRouteChange} />
                            <button className="btn primary" onClick={() => navigate(this.state.selectedValue)}>
                                Navigate
                            </button>
                        </div>
                    </div>
                    <div className="clearfix">
                        <pre className="current-state">{JSON.stringify(this.props.state.app, null, 2)}</pre>
                    </div>
                </Modal>
            </span>
        );
    }

    handleRouteChange(e) {
        this.setState({ selectedValue: e.target.value });
    }
}

export default DebuggerModal;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from './module';
import View from './presenter';
import { getCustomerData } from '../app/actions';
import { selectOffer } from '../app/esim-actions';
import { updateStepperCustomData } from '../../components/Progress/module';

export function mapStateToProps(state) {
    const {
        offerDetails: { customerOffers, errors }
    } = state;
    return {
        app: state.app,
        appData: state.app.appData,
        loading: { ...state.welcome.loading },
        validations: state.welcome.validations,
        errors: state.welcome.errors,
        locale: state.welcome.locale,
        deviceData: state.welcome.deviceData,
        showPortingModal: state.welcome.showPortingModal,
        portingNumber: state.welcome.portingNumber,
        customerOffers,
        offersError: errors,
        esimOfferData: state.app.appData.esimData,
        stepperBar: { ...state.stepperBar }
    };
}

export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { getCustomerData }, { selectOffer }, { updateStepperCustomData });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const Welcome = connect(mapStateToProps, mapDispatchToProps)(View);

export default Welcome;

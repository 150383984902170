import React, { Component } from 'react';
import Modal from 'react-modal';
import { ActionButton, TextStyle } from '@able/react';
import UpdateContactModal from './updateContactModal';
import { nextPage, formatMobileNumber } from '../../adapters/utils';
import { updateDataLayerObject, createDataLayerPage } from '../../adapters/analytics-utils';
import { LimitReachedSoftStopError } from './components/limitReachedSoftStopError';

const bannerImage = require('../../assets/img/location-pin.png');

class UpdateContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        };
        this.enableModal = this.enableModal.bind(this);
        this.skipUpdate = this.skipUpdate.bind(this);

        this.containerRef = React.createRef();
        this.modal = React.createRef();
    }

    componentWillMount() {
        const {
            editContact: { hasOtpSent },
            phoneNumVerificationLimitInfo: {
                limitReached
            } = {}
        } = this.props;
        if (hasOtpSent && !limitReached) {
            this.props.actions.resetEditContactModal();
        }
        if (limitReached) {
            this.enableModal();
        }
    }

    componentDidMount() {
        const {
            appData: {
                customer: {
                    data: { phoneNumber }
                }
            }
        } = this.props;
        updateDataLayerObject(createDataLayerPage(phoneNumber ? 'Update mobile number' : 'Add mobile number'));
        this.containerRef?.current?.focus();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.updateMobileStatus.isMobileUpdated &&
            !this.props.updateMobileStatus.isMobileUpdateInProgress &&
            prevProps.updateMobileStatus?.isMobileUpdateInProgress) {
            const {
                actions: { updateStepperCustomData, updateMobileSuccess },
            } = this.props;
            nextPage(updateStepperCustomData);
            updateMobileSuccess();
        }
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
        this.props.actions.triggerUpdateContactModal(false);
        this.props.actions.resetEditContactModal();
    };

    render() {
        const {
            appData: {
                msisdn,
                customer: {
                    data: { phoneNumber }
                },
                uiState: { isEsimActivation, selectedActivationFlow }
            },
            welcome: { portingNumber },
            actions: {
                updateMobileSuccess,
                resetEditContactModal,
                updateStepperCustomData
            },
            phoneNumVerificationLimitInfo: {
                limitReached,
                errorDetails
            } = {}
        } = this.props;

        const { showUpdateContactModal } = this.props;

        if (!phoneNumber && limitReached) {
            const updateContactMedium = () => {
                const isPortingFlow = selectedActivationFlow === 'portNumber';
                updateMobileSuccess({
                    phoneNumber: isPortingFlow ? portingNumber : isEsimActivation ? `The mobile number you're activating now` : msisdn
                });
                nextPage(updateStepperCustomData);
            }
            return <LimitReachedSoftStopError
                errorDetails={errorDetails}
                buttonText="Add activating number"
                onContinue={updateContactMedium}
            />;
        }

        return (
            <div ref={this.containerRef} tabIndex="-1" className="mt-5">
                {phoneNumber ? (
                    <div className="row">
                        <div className="col-md-5 p-md-0">
                            <TextStyle alias="HeadingA" element="h1" className="mb-3">
                                Update mobile number
                            </TextStyle>
                            <TextStyle alias="TextBodyShort" className="mbxxl">
                                You may update your mobile number here. We'll use it to verify your access.
                            </TextStyle>
                            <TextStyle alias="Label" element="label" htmlFor="mbl-num" className="mbm mt-5xl">
                                Mobile number
                            </TextStyle>
                            <TextStyle element="p" tabIndex="-1" alias="TextBodyShort" id="mbl-num" className="mbm">
                                {formatMobileNumber(phoneNumber || '')}
                            </TextStyle>
                            <ActionButton element="button" label="Update mobile number" className="boost-able-button" variant="LowEmphasis" onClick={this.enableModal} />
                            <ActionButton element="button" className="w-100 mt-3 boost-able-button" variant="MediumEmphasis" onClick={this.skipUpdate} label="Skip" />
                        </div>
                        <div className="col-md-5">{bannerImage && <img className="contact-details-image hide-in-mobile" alt="" src={bannerImage} />}</div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-5 p-0">
                            <TextStyle alias="HeadingA" element="h1" className="mb-3">
                                Add mobile number
                            </TextStyle>
                            <TextStyle alias="TextBodyShort">
                                Your mobile number is missing in our records. Add one now so we can use it to verify your access.
                            </TextStyle>
                            <ActionButton element="button" className="mt-5 mb-10 boost-able-button w-100" variant="HighEmphasis" label="Add mobile number" onClick={this.enableModal} />
                        </div>
                    </div>
                )}
                {showUpdateContactModal ? (
                    <Modal
                        parentSelector={() => document.querySelector('#modal-ppv')}
                        shouldCloseOnOverlayClick={false}
                        isOpen={this.state.modalOpen}
                        className="modal-dialog modal-lg ppv-modal modal-sm"
                        onRequestClose={this.toggleModal}
                        shouldCloseOnEsc={!limitReached}
                        aria={{ labelledby: 'unauth-update-contact-modal-heading', modal: 'true', role: 'dialog' }}
                        ref={this.modal}
                    >
                        {<UpdateContactModal
                            identityVerified
                            readerId="unauth-update-contact-modal-heading"
                            gotoInitModalOnErrorClose
                            modalRef={this.modal}
                            resetModal={resetEditContactModal}
                            {...this.props}
                            toggleModal={this.toggleModal}
                            updateSuccessAction={updateMobileSuccess}
                        />}
                    </Modal>
                ) : null}
            </div>
        );
    }

    enableModal() {
        const {
            actions: { triggerUpdateContactModal }
        } = this.props;
        triggerUpdateContactModal(true);
        this.setState({
            modalOpen: true
        });
    }

    skipUpdate() {
        const {
            actions: { updateStepperCustomData, unAuthUpdateAddNumberSkip }
        } = this.props;
        unAuthUpdateAddNumberSkip();
        nextPage(updateStepperCustomData);
    }
}

export default UpdateContact;

import * as React from 'react';
import { connect } from 'react-redux';
import './loader.css';
import Loader from '@mytelstra/mt-design-system/lib/Loader';

const LoadingSpinner = (props) => {
    const { payment: { loading: { paymentFieldsInitialised = false } = {} } = {} } = props;

    let classNames = 'div-width hidden';

    if (!paymentFieldsInitialised) {
        classNames = 'div-width';
    }

    return (
        <div className={classNames}>
            <Loader message="Just a moment..." slowLoadingMessage="Just a moment..." />
        </div>
    );
};

export function mapStateToProps(state) {
    return {
        payment: state.payment
    };
}

const ConnectedLoader = connect(mapStateToProps)(LoadingSpinner);

export default ConnectedLoader;

export default class Feature {
    /**
     * Test if cookies are enabled.
     * @param {Object} doc The browser document object.
     * @returns {Boolean}
     */
    static testCookies(doc) {
        doc = doc || document; // eslint-disable-line no-param-reassign
        try {
            // Create cookie
            doc.cookie = 'cookietest=1';
            const ret = doc.cookie.indexOf('cookietest=') !== -1;
            // Delete cookie
            doc.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
            return ret;
        } catch (e) {
            return false;
        }
    }

    /**
     * Test if localStorage is available.
     * @returns {Boolean}
     */
    static isLocalStorageEnabled() {
        const item = 'telstra-auth';
        try {
            localStorage.setItem(item, item);
            localStorage.removeItem(item);

            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Test if sessionStorage is available.
     * @returns {Boolean}
     */
    static isSessionStorageEnabled() {
        const item = 'telstra-auth';
        try {
            sessionStorage.setItem(item, item);
            sessionStorage.removeItem(item);

            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Test if the script was loaded in an iFrame.
     * @returns {Boolean}
     */
    static isIframe() {
        return window.self !== window.top;
    }
}

Feature.localStorage = Feature.isLocalStorageEnabled();
Feature.sessionStorage = Feature.isSessionStorageEnabled();
Feature.iframe = Feature.isIframe();

/* eslint no-underscore-dangle: 0 */

const NONE = 0;
const ERROR = 1;
const WARN = 2;
const INFO = 3;
const DEBUG = 4;

let _msg;
let _logger;
let _level;

const _log = (level, args) => {
    args.unshift(_msg);
    _logger[level](...args);
};

export default class Logger {
    /**
     * Initialize Logger module.
     * @param {Object} [logger] Logger implementation, defaults to window.console.
     * @param {String} [level] Log level, it could be one of the following values: NONE, ERROR, WARN, INFO, DEBUG.
     * @param {String} [msg] Prefix message for all logs.
     * @returns {void}
     */
    static init(logger, level, msg) {
        _logger = logger || console;
        _msg = msg;

        switch (level) {
            case 'ERROR':
                _level = ERROR;
                break;
            case 'WARN':
                _level = WARN;
                break;
            case 'INFO':
                _level = INFO;
                break;
            case 'DEBUG':
                _level = DEBUG;
                break;
            default:
                _level = NONE;
        }
    }

    /**
     * Log errors messages
     * @param {[Objects]} [args] Message to be printed to the console.
     * @returns {void}
     */
    static error(...args) {
        if (_level >= ERROR) {
            _log('error', args);
        }
    }

    /**
     * Log warning messages
     * @param {[Objects]} [args] Message to be printed to the console.
     * @returns {void}
     */
    static warn(...args) {
        if (_level >= WARN) {
            _log('warn', args);
        }
    }

    /**
     * Log info messages
     * @param {[Objects]} [args] Message to be printed to the console.
     * @returns {void}
     */
    static info(...args) {
        if (_level >= INFO) {
            _log('info', args);
        }
    }

    /**
     * Log debug messages
     * @param {[Objects]} [args] Message to be printed to the console.
     * @returns {void}
     */
    static debug(...args) {
        if (_level >= DEBUG) {
            _log('debug', args);
        }
    }
}

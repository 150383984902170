import { validateFirstName, validateLastName, validateMiddleName, validateBirthDate, validateSingleName } from "../../helper";

export const editFieldModalMeta = {
    singleName: {
        readerId: 'update-singleName-modal-heading',
        modalTitle: 'Enter single name',
        modalDesc: "By editing your single name, you won't be able to edit your date of birth. To change the format of your name to given name and family name, you need to visit a Telstra store with your ID.",
        input: {
            label: 'Single name',
            key: 'firstName',
            name: 'singleName',
            helpText: "Enter your name as it appears on your ID, including any spaces or symbols.",
            validate: validateSingleName,
            id: 'singleName',
            autocomplete: 'name'
        }
    },
    firstName: {
        readerId: 'update-firstName-modal-heading',
        modalTitle: 'Enter given name',
        modalDesc: "By editing your given name, you won't be able to edit any other details.",
        input: {
            label: 'Given name',
            key: 'firstName',
            name: 'firstName',
            validate: validateFirstName,
            id: 'firstName',
            autocomplete: 'given-name'
        }
    },
    familyName: {
        readerId: 'update-familyName-modal-heading',
        modalTitle: 'Enter family name',
        modalDesc: "By editing your family name, you won't be able to edit any other details.",
        input: {
            label: 'Family name',
            key: 'familyName',
            name: 'familyName',
            validate: validateLastName,
            id: 'familyName',
            autocomplete: 'family-name'
        }
    },
    middleName: {
        readerId: 'update-middleName-modal-heading',
        modalTitle: 'Enter middle name',
        input: {
            label: 'Middle name',
            key: 'middleName',
            name: 'middleName',
            validate: validateMiddleName,
            id: 'middleName',
            autocomplete: 'middle-name'
        }
    },
    birthDate: {
        readerId: 'update-birthDate-modal-heading',
        modalTitle: 'Enter date of birth',
        modalDesc: "By editing your date of birth, you won't be able to edit any other details.",
        input: {
            label: 'Date of birth',
            key: 'birthDate',
            name: 'editDOB',
            validate: validateBirthDate,
            id: 'editDOB',
            autocomplete: 'bday'
        }
    }
}
import React from 'react';
import './survey.css';
/**
 * PPA NPS Survey component. Needs welcome locale to load
 *
 * @param {any} { ...props }
 * @returns
 */
const Survey = ({ ...props }) => {
    const { loading: { appLoading }, customerId = '', serviceId = '', onCloseClick = () => false } = props;
    if (appLoading) return false;

    const { cancelActivation: { npsContent = {} } = {} } = props.locale || {};
    const openSurvey = event => {
        const link = `${npsContent.npsLinkURL}&SID=${serviceId}&CID=${customerId}"`;
        window.open(link, 'targetWindow', 'width=1300, height=650, scrollbars=1');
        event.target.parentNode.style.display = 'none';
    };
    return (
        <div id="nps-content">
            <div className="bottom-right-container tso-invitation-content tso-invitation-border">
                <span className="tso-invitation-caption">Help us improve.</span>
                <a role="presentation" className="tso-invitation-link" onClick={event => openSurvey(event)} target="_blank">
                    Complete our quick survey
                </a>
                <span className="divider"> | </span>
                <a role="presentation" onClick={() => onCloseClick()} className="tso-invitation-close">
                    X
                </a>
            </div>
        </div>
    );
};

export default Survey;

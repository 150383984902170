import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';
import {
    APP_INIT_DONE,
    CUSTOMER_CHECK_DONE,
    CUSTOMER_CHECK_ERROR,
    CUSTOMER_CHECK_INIT,
    CUSTOMER_STATE_CHECK_COMPLETE,
    DOCUMENT_VALIDATION_INIT,
    DOCUMENT_VALIDATION_ERROR,
    DOCUMENT_VALIDATION_SUCCESS,
    PORTING_CHECK_INIT,
    PORTING_VALIDATION_ERROR,
    PORTING_VALIDATION_SUCCESS,
    STORE_USER_ACCESS_TOKEN,
    RECHECK_AUTHENTICATION,
    CUSTOMER_DATA_FETCH_INIT,
    CUSTOMER_DATA_FETCH_SUCCESS,
    CUSTOMER_DATA_FETCH_ERROR,
    LOGIN_CHECK_STATUS_UPDATE,
    STORE_LANDING_ADDRESS,
    UPDATE_SHOPPING_CART_ERROR,
    UPDATE_SHOPPING_CART_SUCCESS,
    STORE_USER_LOA,
    UPDATE_CUSTOMER_BUSINESS_DATA,
    SET_FULL_PAGE_ERROR_APP_FLAG
} from '../actions';
import { URLSearchParams, generateUUID, storeProcessToken, nextPage, route } from '../../adapters/utils';
import { notifyAnalytics, requestCodeMeta, errorMessages } from '../../adapters/analytics-utils';
import { retrieveEsimParams } from './esim-actions';
import { isEmpty } from '../../adapters/validations';
import store from '../../store';
import { updateStepperCustomData } from '../../components/Progress/module';
import { reset } from 'redux-form';
import { activateSim } from './activate-actions';

// Action Creators
/**
 * App initialisation dispatcher
 * Deals with the session token recieved by the server
 * Checks for the URL Params to preload msisdn or sim serial
 *
 * @export
 * @param {any} location - URL
 * @returns
 */
export function initApp(location) {
    // TODO ESIM-SODA Test with the actual flow, handle error scenarios and remove commented/unused esim code
    const { source = '', model = '', eid = '', imei = '' } = URLSearchParams(location.search);

    if (!isEmpty(imei)) {
        route('/esim-microsoft-landing');
    }

    let { param } = URLSearchParams(location.search);
    if (!isEmpty(param)) {
        param = param.split('|')[1];
        window.localStorage.setItem('parameters', param);
    }

    if (window.localStorage) {
        param = window.localStorage.getItem('parameters');
        !isEmpty(param || source) && window.localStorage.setItem('esimFlow', true);
        console.log(`param after local storage  = ${param}`); // eslint-disable-line no-console
    }

    let sessionToken;
    if (window.localStorage) {
        sessionToken = window.localStorage.getItem('sessionToken');
    }
    if (!sessionToken && window.localStorage) {
        sessionToken = generateUUID();
        window.localStorage.setItem('sessionToken', sessionToken);
    }

    // TODO ESIM-SODA esim flow set if param exists. May need to revisit the logic based on architectural decisions.
    const isEsimActivation = !isEmpty(param || source);
    const isEsimAuth = !isEmpty(param);
    !isEsimActivation && window.localStorage && window.localStorage.removeItem('parameters');

    let payload;
    if (isEsimActivation) {
        if (!isEmpty(param)) {
            payload = {
                data: {
                    token: param
                }
            };
        } else {
            payload = {
                data: {
                    source,
                    model,
                    eid
                }
            };
        }
    }

    return (dispatch) => {
        isEsimActivation && !isEsimAuth && isEmpty(imei) && dispatch(retrieveEsimParams(payload));
        dispatch({
            type: APP_INIT_DONE,
            appData: {
                esimData: {
                    param,
                    source,
                    model,
                    eid,
                    imei
                }
            },
            sessionToken,
            isEsimActivation,
            isEsimAuth
        });
    };
}

/**
 * Completes the application initialisation run after checking the
 * user validity if the customer is logged in.
 *
 * @export
 * @param {any} isCustomerValid
 * @param {any} telstraAuthCustomerProfile
 * @returns
 */
export function completeInit(isCustomerValid, telstraAuthCustomerProfile) {
    return { type: CUSTOMER_STATE_CHECK_COMPLETE, isCustomerValid, telstraAuthCustomerProfile };
}
/**
 * Stores the user access token returned by Telstra okapi auth.
 *
 * @export
 * @param {any} token
 * @returns
 */
export function storeUserAccessToken(token) {
    return { type: STORE_USER_ACCESS_TOKEN, token };
}

/**
 * Stores the acr claim of the auth user
 *
 * @export
 * @param {any} token
 * @returns
 */
export function storeAcrClaim(acr) {
    return { type: STORE_USER_LOA, acr };
}

/**
 * Validate customer details based on form values
 *
 * @param {any} customer
 * @param {any} [dataFactory=client.fetchCustomerValidation]
 * @returns
 */
export const validateCustomerDetails = (customer, dataFactory = client.fetchCustomerValidation) => {
    return (dispatch) => {
        dispatch({ type: CUSTOMER_CHECK_INIT });
        dataFactory(dispatch, receiveCustomerValidation, receiveCustomerValidationError, { data: customer });
    };
};

/**
 * Valdiates cusotmer document data
 * passport
 * dl
 * medicare
 * aus visa
 *
 * @param {any} document document form object
 * @param {any} [dataFactory=client.fetchDocumentValidation] endpoint connection
 * @returns
 */
export const validateDocumentDetails = (document, dataFactory = client.fetchDocumentValidation) => {
    return (dispatch) => {
        dispatch(requestDocumentValidation(document));
        dataFactory(dispatch, receiveDocumentValidation, receiveDocumentValidationError, document);
    };
};

const requestDocumentValidation = (data) => {
    return {
        type: DOCUMENT_VALIDATION_INIT,
        data
    };
};

/**
 * Validates porting details
 *
 * @param {any} document
 * @param {any} [dataFactory=client.fetchDocumentValidation]
 * @returns
 */
export const validatePortingDetails = (portingDetails, callerModule = '', dataFactory = client.fetchPortingEligibility) => {
    return (dispatch) => {
        dispatch({ type: PORTING_CHECK_INIT, callerModule });
        dataFactory(dispatch, receivePortingValidation, receivePortingValidationError, portingDetails);
    };
};

/**
 * process response from service call
 * for customer validation
 * @param {any} packageData
 * @returns
 */
export const processCustomerValidationResponse = (packageData) => {
    let errorCode = '';
    if (typeof packageData.status === 'string') {
        if (packageData.status.indexOf('SVCT-FATAL-ERROR') !== -1) {
            errorCode = errorCodeMeta.SERVICE_ERROR;
        } else if (packageData.status.indexOf('SFCC-FATAL-422') !== -1) {
            errorCode = packageData.status;
        } else if (packageData.status.indexOf('SFCC-FATAL-') !== -1) {
            errorCode = packageData.status;
        } else if (packageData.status === 'SFADUP-FATAL-422') {
            errorCode = packageData.status;
        } else if (packageData.status.indexOf('-FATAL-') !== -1) {
            errorCode = errorCodeMeta.FATAL_ERROR;
        } else if (packageData.status === 'SVCT-EX-SMB') {
            errorCode = packageData.status;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data };
};

/**
 * process response from service call
 * for document validation
 *
 * @param {any} packageData
 * @returns
 */
const processDocumentValidationResponse = (packageData) => {
    const { data, status } = packageData;
    let errorCode = '';
    const errorMeta = {};
    if (typeof status === 'string' && status.toLowerCase() !== 'success') {
        if (status.indexOf('CIDV-EX') > -1) {
            errorMeta.downstreamFailure = true;
            errorCode = errorCodeMeta.ID_UNAVAILABLE;
            return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data, errorMeta };
        } else if (status.indexOf('-FATAL-') !== -1) {
            if (status.startsWith('CIDV-FATAL-')) {
                errorMeta.downstreamFailure = true;
            }
            errorCode = errorCodeMeta.SERVER_ERROR;
            return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data, errorMeta };
        }
        if (!data) {
            errorCode = errorCodeMeta.INVALID_DOCUMENT;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data, errorMeta };
};

/**
 * process response from service call
 * for document validation
 *
 * @param {any} packageData
 * @returns
 */
const processPortingValidationResponse = (packageData) => {
    let errorCode = '';
    const portingError = packageData.status;
    if (typeof portingError === 'string' && portingError.toLowerCase() !== 'success') {
        if (portingError === 'PPVVC-DECLINE') {
            errorCode = errorCodeMeta.PORTING_DECLINED;
        } else {
            errorCode = errorCodeMeta.PORTING_FAILED;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data };
};

// DISPATCH OBJECTS
/**
 * Processes the response from service call which check the
 * validity of entered sim id dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const receiveCustomerValidation = (packageDetails) => {
    const { hasError, errorCode, data } = processCustomerValidationResponse(packageDetails);
    if (hasError) {
        if (errorCode === 'SVCT-EX-SMB') {
            const state = store.getState();
            const { yourContactDetails: { businessReigstrationMeta: { businessDetails: { businessNames = [] } = {} } } = {} } = state;
            if (businessNames && businessNames.length === 1) {
                store.dispatch(reset('businessRegistrationForm'));
            }
        }
        return receiveCustomerValidationError(errorCode);
    }
    !data.matchedContact && nextPage(updateStepperCustomData);
    return {
        type: CUSTOMER_CHECK_DONE,
        data
    };
};

/**
 * Processes the response from service call which check the
 * validity of entered sim id dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const receiveDocumentValidation = (packageDetails) => {
    const { hasError, errorCode, data, errorMeta } = processDocumentValidationResponse(packageDetails);

    if (hasError) {
        return receiveDocumentValidationError(errorCode, errorMeta);
    }

    const state = store.getState();

    return {
        type: DOCUMENT_VALIDATION_SUCCESS,
        data,
        submittedData: state.yourIdDetails.submittedData
    };
};

/**
 * Processes the response from service call which check the
 * validity of entered number dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const receivePortingValidation = (packageDetails) => {
    const { hasError, errorCode, data } = processPortingValidationResponse(packageDetails);

    if (hasError) {
        return receivePortingValidationError(errorCode);
    }

    nextPage(updateStepperCustomData);
    return {
        type: PORTING_VALIDATION_SUCCESS,
        data
    };
};

/**
 * Returns dispatch object after processing the http response from
 * service call
 *
 * @param {string} httpStatus
 * @returns
 */
export const receiveCustomerValidationError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 400:
            errorCode = errorCodeMeta.BAD_REQUEST;
            break;
        case 403:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 405:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    notifyAnalytics(requestCodeMeta.customerValidationFaliure, errorCode);
    return { type: CUSTOMER_CHECK_ERROR, errorCode };
};

/**
 * Returns dispatch object for failed document validation
 *
 * @param {any} httpStatus
 * @returns
 */
export const receiveDocumentValidationError = (httpStatus, errorMeta) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            notifyAnalytics({ request: requestCodeMeta.documentFailure, errorText: requestCodeMeta.validationUiFailure }, errorCode);
            break;
        case 403:
            errorCode = errorCodeMeta.INVALID_DOCUMENT;
            notifyAnalytics({ request: requestCodeMeta.documentFailure, errorText: requestCodeMeta.validationUiFailure }, errorCode);
            break;
        case 405:
            errorCode = errorCodeMeta.INVALID_DOCUMENT;
            notifyAnalytics({ request: requestCodeMeta.documentFailure, errorText: requestCodeMeta.validationUiFailure }, errorCode);
            break;
        case errorCodeMeta.ID_UNAVAILABLE:
            errorCode = errorCodeMeta.ID_UNAVAILABLE;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    if (errorMeta?.downstreamFailure) {
        notifyAnalytics({ request: requestCodeMeta.documentFailure, errorText: errorMessages.TECHNICAL_ISSUE_IDPAGE }, errorCode);
    }

    return { type: DOCUMENT_VALIDATION_ERROR, errorCode };
};

/**
 * Returns dispatch object for failed porting validation
 *
 * @param {any} httpStatus
 * @returns
 */
export const receivePortingValidationError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    notifyAnalytics(requestCodeMeta.portingFailure, errorCode);
    return { type: PORTING_VALIDATION_ERROR, errorCode };
};
/**
 * returns an action to recheck the user authentication.
 *
 */
export const recheckAuthentication = () => {
    return { type: RECHECK_AUTHENTICATION };
};

/**
 * Get current customer data. This func is triggered
 * if the user state is determined as logged in by
 * Telstra Auth
 *
 * @param {string} [callerModule='']
 * @param {any} [dataFactory=client.fetchCurrentCustomer]
 * @returns
 */
export const getCustomerData = (callerModule = '', dataFactory = client.fetchCurrentCustomer) => {
    return (dispatch) => {
        dispatch({ type: CUSTOMER_DATA_FETCH_INIT });
        dataFactory(dispatch, receiveCustomerData, receiveCustomerDataError);
    };
};

export const processCustomerData = (customerData) => {
    let errorCode = '';
    if (typeof customerData.status === 'string') {
        if (customerData.status.indexOf('SVCT-FATAL-ERROR') !== -1) {
            errorCode = errorCodeMeta.SERVICE_ERROR;
        } else if (customerData.status.indexOf('-FATAL-') !== -1) {
            errorCode = errorCodeMeta.FATAL_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: customerData.data };
};

/**
 * Return dispatch object when customer data service call
 * passes
 *
 * @param {any} customerData
 */
const receiveCustomerData = (customerData) => {
    const { data = {}, hasError, errorCode } = processCustomerData(customerData);
    if (hasError) {
        return receiveCustomerDataError(errorCode);
    }
    data && storeProcessToken(data.token);
    return { type: CUSTOMER_DATA_FETCH_SUCCESS, customerData: data };
};

/**
 * Return dispatch object when customer data service call
 * fails
 *
 * @returns
 */

export const receiveCustomerDataError = (error) => ({ type: CUSTOMER_DATA_FETCH_ERROR, error });

export const loginCheckStatusUpdate = (status) => ({ type: LOGIN_CHECK_STATUS_UPDATE, loginCheckInProgress: status });

export const storeLandingLocation = (location) => ({ type: STORE_LANDING_ADDRESS, location });

export const undoRechargeAndActivate = () => {
    return undoCartRechargeId();
};

export const undoCartRechargeId = (dataFactory = client.updateCartData) => {
    const state = store.getState();
    const rechargeId = null;
    const offerDetails = state.offerDetails.selectedOffer;
    const { productOfferingId } = offerDetails;
    const { serviceNumber: { starterCredit } = {} } = state.app.appData;
    const shoppingCartId = state.app.appData.shoppingCart.shoppingCartId;

    const payload = {
        data: {
            productOfferingId,
            rechargeId,
            starterCredit,
            shoppingCartId
        }
    };

    return (dispatch) => {
        return dataFactory(dispatch, shoppingCartUpdateSuccess, shoppingCartUpdateFailure, payload);
    };
};

export const shoppingCartUpdateSuccess = (cartData) => {
    const { hasError, errorCode, data } = processShoppingCartUpdateData(cartData);
    if (hasError) {
        return shoppingCartUpdateFailure(errorCode);
    }
    return (dispatch) => {
        dispatch({
            type: UPDATE_SHOPPING_CART_SUCCESS,
            data
        });
        dispatch(activateSim());
    };
};

export const shoppingCartUpdateFailure = (httpStatus) => {
    const errorCode = processServerError(httpStatus);
    return { type: UPDATE_SHOPPING_CART_ERROR, errorCode };
};

const processServerError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 400:
            errorCode = errorCodeMeta.BAD_REQUEST;
            break;
        case 404:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 405:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    return errorCode;
};

const processShoppingCartUpdateData = (cartData) => {
    let errorCode = '';
    if (cartData.status.toLowerCase() !== 'success') {
        const updateCartStatus = cartData.status.toString();
        if (updateCartStatus === 'RTFA-DECLINE') {
            errorCode = errorCodeMeta.FRAUD_DECLINED_ERROR;
        } else if (updateCartStatus.startsWith('FRAUD-FATAL')) {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        } else if (updateCartStatus.startsWith('DXPCAO-FATAL')) {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        } else if (updateCartStatus.startsWith('DXPCRO-FATAL')) {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        } else {
            errorCode = errorCodeMeta.UPDATE_CART_FATAL_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: cartData.data };
};

export const updateCustomerBusinessData = (accountNumber, data = {}) => {
    return {
        type: UPDATE_CUSTOMER_BUSINESS_DATA,
        data,
        accountNumber
    };
};

export const setFullPageErrorAppFlag = () => {
    return {
        type: SET_FULL_PAGE_ERROR_APP_FLAG
    };
};

export { activateSim };

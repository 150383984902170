import store from '../store';
import { REQUEST_IN_PROGRESS, REQUEST_COMPLETE } from '../routes/actions';
import axios from 'axios';
// import axiosRetry from 'axios-retry';

/**
 * Return api location from global app config
 * @returns
 */
export function apiLocation() {
    const { PPA_API_BASE_URL = '' } = window.appConfig || {};
    return PPA_API_BASE_URL;
}

/**
 * Returns AEM base path from App Config
 * @returns
 */
export function localeLocation() {
    const { viewBasePath = '' } = window.appConfig || {};
    return viewBasePath;
}

const localeAxios = axios.create({ baseURL: localeLocation() });
const apiAxios = (headers = {}, baseURL = apiLocation()) => axios.create({ baseURL, headers });
const validResponseCodes = [200, 417, 403, 405, 406, 404, 412, 409, 422];

/**
 * Create a network request from the following parameter
 *
 * @param {any} fetchResource - request instance
 * @param {any} dispatch - redux dispatch
 * @param {any} successActionCreator - dispatches success action of calling component
 * @param {any} errorActionCreator - dispatches error action of calling component
 */
export const fetchFromNetwork = (fetchResource, dispatch, successActionCreator, errorActionCreator) => {
    return fetchResource
        .then((response) => {
            // Consume request and dispatch error action with 500.
            if (validResponseCodes.indexOf(response.status) === -1) {
                dispatch(errorActionCreator(500));
                return false;
            }

            const httpStatus = response.status;
            const data = response.data;
            return { ...data, httpStatus, response };
        })
        .then((response) => {
            if (response) {
                dispatch(successActionCreator(response));
            }
            dispatch({ type: REQUEST_COMPLETE, response });
            return response;
        })
        .catch((error) => {
            if (error.response !== undefined) {
                const { status } = error.response || {};
                if (validResponseCodes.indexOf(status) === -1) {
                    dispatch(errorActionCreator(500));
                } else {
                    // PA-313 return whole error object
                    // to process error further
                    dispatch(errorActionCreator(error.response.status, error.response));
                }
            } else if (error.request) {
                dispatch(errorActionCreator(4000));
            } else {
                dispatch(errorActionCreator());
            }
            dispatch({ type: REQUEST_COMPLETE, error });
        });
};

/**
 * Creates a request to fetch data from AEM locales
 * TODO: FIX - Keeping it as is for making the pages work for now.
 *
 * @export
 * @param {any} dispatch -> redux dispatch
 * @param {any} path -> page path
 * @param {any} successActionCreator -> dispatches success action of calling component
 * @param {any} errorActionCreator -> dispatches error action of calling component
 */
export function fetchFromLocale(dispatch, path, successActionCreator, errorActionCreator) {
    return fetchFromNetwork(localeAxios.get(`locale.${path}.htm`), dispatch, successActionCreator, errorActionCreator);
}

/**
 * Constructs default header which needs to be shared across multiple
 * request.
 */
const getDefaultHeader = () => {
    const state = store.getState();
    let requestApplicationHeader = 'BPPA';
    if (state.app.appData.uiState.isEsimActivation) {
        requestApplicationHeader = 'BPPA-ESIM';
    }
    return {
        'x-process-token': state.app.serviceAuthToken,
        'x-session-id': state.app.sessionToken,
        'x-request-application': requestApplicationHeader
    };
};

/**
 * Create a GET network call instance and assigns default headers.
 *
 * @export
 * @param {any} dispatch -> redux dispatch
 * @param {any} path -> network path
 * @param {any} successActionCreator -> dispatches success action of calling component
 * @param {any} errorActionCreator -> dispatches error action of calling component
 * @param {any} [options={}] optional headers and get parameters from calling component.
 * @returns fetchFromNetwork instance
 */
export function getFromApi(dispatch, path, successActionCreator, errorActionCreator, options = {}) {
    const { params, useDefaultApi = true } = options;
    let { headers = {} } = options;
    headers = useDefaultApi ? Object.assign({}, getDefaultHeader(), headers) : {};
    const url = path;
    if (params) {
        params.forEach((key) => new URL(url).searchParams.append(key, params[key]));
    }

    // dispatched init request for loading cogs
    const request = apiAxios(headers).get(url);
    // const axiosInstance = apiAxios(headers);
    // axiosRetry(axiosInstance, {
    //     retries: 2, // number of retries
    //     retryCondition: (error) => {
    //         // if retry condition is not specified, by default idempotent requests are retried
    //         return error.response.status === 408 || 500 || 502 || 503 || 504;
    //     }
    // });
    // const request = axiosInstance.get(url); axios retry code commented
    dispatch({ type: REQUEST_IN_PROGRESS, path });
    return fetchFromNetwork(request, dispatch, successActionCreator, errorActionCreator);
}

/**
 * Create a GET network call instance and assigns default headers.
 *
 * @export
 * @param {any} dispatch -> redux dispatch
 * @param {any} path -> network path
 * @param {any} successActionCreator -> dispatches success action of calling component
 * @param {any} errorActionCreator -> dispatches error action of calling component
 * @param {any} [options={}] -> optional headers and get parameters from calling component.
 * @returns fetchFromNetwork instance
 */
export function postToApi(dispatch, path, successActionCreator, errorActionCreator, options = {}) {
    const { params, useDefaultApi = true } = options;
    let { headers = {} } = options;
    if (useDefaultApi) {
        headers = Object.assign({}, getDefaultHeader(), headers);
    }
    headers = Object.assign({}, headers, { 'Content-Type': 'application/json' });
    const apiInstance = useDefaultApi ? apiAxios(headers) : apiAxios(headers, path);
    // axiosRetry(apiInstance, {
    //     retries: 2, // number of retries
    //     retryCondition: (error) => {
    //         // if retry condition is not specified, by default idempotent requests are retried
    //         return error.response.status === 408 || 500 || 502 || 503 || 504;
    //     }
    // }); axios retry code commented
    const payload = useDefaultApi ? { url: path, method: 'post', data: params } : { method: 'post', data: params };
    const request = apiInstance(payload);
    dispatch({ type: REQUEST_IN_PROGRESS, path });

    return fetchFromNetwork(request, dispatch, successActionCreator, errorActionCreator);
}

export function postFromPaypal(dispatch, path, successActionCreator, errorActionCreator, options = {}) {
    const { params } = options;
    let { headers = {} } = options;
    headers = Object.assign({}, getDefaultHeader(), headers);
    const requestPath = apiLocation() + path;
    return apiAxios(headers)({
        url: requestPath,
        method: 'post',
        data: params
    }).then((response) => {
        dispatch(successActionCreator(response));
        return response;
    });
}

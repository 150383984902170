import React, { Component } from 'react';
import { getUiState, getPassedEsimProps } from '../../adapters/store-utils';
import { isEmpty } from '../../adapters/validations';
import { CancelActivation } from '../';
import './footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false };
    }
    render() {
        const { loading: { appLoading, hasVerifiedEsimFreeOfferEligibility }, isCoreLoaded = true, appData } = this.props;

        if (appLoading && !isCoreLoaded) return false;

        const { isEsimActivation = false } = getUiState() || {};
        if (isEsimActivation) {
            const { helpAndSupport = {} } = this.props.locale || {};
            const { esimData: { isEligibleForFreeOffer, iccids = '', iccid = '' } } = appData;
            const isRedirecting = !isEligibleForFreeOffer && (!isEmpty(iccid) || !isEmpty(iccids));
            return hasVerifiedEsimFreeOfferEligibility && !isRedirecting ? (
                <div className="esim-footer">
                    <div className="getting-connected">
                        <a href={helpAndSupport.link1} target="_blank" rel="noreferrer">
                            {helpAndSupport.linkText1}
                            <i className="td-icon-xs icon-ui-right-arrow-thick blue-arrow-thick" />
                        </a>
                    </div>
                    <div className="help-support">
                        <a href={`${helpAndSupport.link}${getPassedEsimProps()}`}>
                            {helpAndSupport.linkText}
                            <i className="td-icon-xs icon-ui-right-arrow-thick blue-arrow-thick" />
                        </a>
                    </div>
                </div>
            ) : (
                false
            );
        }

        return (
            <div className="footer">
                <CancelActivation />
            </div>
        );
    }
}

export default Footer;

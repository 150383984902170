import './ableCheckBox.css';
import React, { Fragment, useEffect } from 'react';
import { Error } from '../..';
import { Checkbox } from '@able/react';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';

export function AbleCheckBox({ meta={}, input={}, ...props }) {
    const { name } = input;
    const { touched = false, error, submitFailed = false } = meta;
    useEffect(() => {
        if (submitFailed && touched && error) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
        }
    },[submitFailed, touched, error]);
    return (
    <Fragment>
        <Checkbox {...props} {...input} />
        {(name === 'noMiddleName') && (submitFailed || (touched && error)) && <Error errorDescriptionId={name} isValidationError errorText={error} errorIconClassName={'icon-alert-solid-round'} />}
    </Fragment>
    );
}
import { createStore as _createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'localforage';
import lStorage from 'redux-persist/lib/storage';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import createEncryptor from 'redux-persist-transform-encrypt';
import { createReduxHistoryContext } from 'redux-first-history';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';
import { createBrowserHistory } from 'history';

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const encryptor = createEncryptor({ secretKey: '732209b9-c1dd-4391-9e85-b6af706d2649' });

let finalCreateStore = applyMiddleware(routerMiddleware, thunkMiddleware);

// if we have redux devtools installed hook into it.
// eslint-disable-next-line
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    finalCreateStore = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(finalCreateStore); // eslint-disable-line no-underscore-dangle
}

const persistStorage = window.sessionStorage ? createWebStorage('session') : lStorage;
const persistConfig = {
    key: 'ppareducer',
    storage: persistStorage,
    transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, rootReducer(routerReducer));
const store = finalCreateStore(_createStore)(persistedReducer, {});
export const history = createReduxHistory(store);
export const persistor = persistStore(store);
persistor.pause();

export default store;

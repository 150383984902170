import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { IconButton, TextStyle } from '@able/react';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import { AutoComplete } from '../../../../components';
import { validateAddress } from './updateAddressUtils';
import { updateAddressSuccess, getIsMobileUpdated, getIsMobileUpdateInProgress, getAddressVerificationData, getVerificationInProgress, getErrors } from './ducks';
import { getAddress, verifyAddressUpdateContactDetails, updateContactMedium, resetErrors } from '../../module';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import './updateAddress.css';
import { focusOnElement, getAbleSpriteSheet } from '../../../../adapters/utils';
import { updateDataLayerObject, createDataLayerPage, addDataLayerEventInfo } from '../../../../adapters/analytics-utils';
import { NONPHYSICAL_ADDRESS_ERROR } from '../../../../adapters/errorCode';

const ableSpriteSheet = getAbleSpriteSheet();

const UpdateAddress = ({
    onClose,
    updateContactMediumAction,
    updateAddressSuccessAction,
    getAddressAction,
    verifyAddressUpdateContactDetailsAction,
    isMobileUpdated,
    isMobileUpdateInProgress,
    verificationData,
    verificationInProgress,
    modalRef,
    readerId,
    resetErrors,
    errors
}) => {
    const [addressInput, setAddressInput] = useState('');
    const [addressError, setAddressError] = useState('');
    const [prevVerificationData, setPrevVerificationData] = useState(verificationData);

    useEffect(() => {
        updateDataLayerObject(createDataLayerPage('Update Address'));
        if (modalRef && modalRef.current) {
            modalRef.current.node.getElementsByClassName("modal-heading")[0].focus();
            modalRef.current.node.getElementsByClassName("ReactModal__Overlay")[0].removeAttribute("aria-modal");
        }
    }, []);

    if (isMobileUpdated) {
        updateAddressSuccessAction({ verificationData });
        onClose();
    }

    if (prevVerificationData !== verificationData) {
        const payload = {
            data: {
                activatingMsisdn: false,
                contactMediumType: 'Address',
                contactMediumValue: verificationData.addressId
            }
        };
        setPrevVerificationData(verificationData);
        updateContactMediumAction(payload);
    }

    const onAddressChange = (value) => {
        setAddressInput(value);
        resetErrors();
        setAddressError('');
    };

    const onClickContinue = async () => {
        const err = validateAddress(addressInput);
        document.getElementById('address-error-description') ? document.getElementById('address-error-description').style.display = 'block' : null;
        if (err) {
            setAddressError(err);
            addDataLayerEventInfo('error', 'Inline', 'unavailable', err.error || '');
            focusOnElement('#autosuggest-address');
            return;
        }
        await verifyAddressUpdateContactDetailsAction({
            addressLine1: addressInput.value
        });
    };

    const getAddressList = (input) => {
        if (!input) {
            return Promise.resolve({ options: [] });
        }

        return getAddressAction(input).then((response) => {
            const addresses = [];
            if (!response || !response.data) return addresses;

            response.data.results.forEach((value) => {
                addresses.push({
                    label: value.name,
                    value: value.name
                });
            });
            return Promise.resolve(addresses);
        });
    };

    const { Address } = CONTACT_PAGE_CONSTANTS;

    useEffect(() => {
        if (errors.errorCode === NONPHYSICAL_ADDRESS_ERROR)
            setAddressError(CONTACT_PAGE_CONSTANTS.nonPhysicalAddressErrorMeta);
        else setAddressError('');
    }, [errors.errorCode]);

    return (
        <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content">
            <React.Fragment>
                <div className="modal-header-no-border ppv-header update-contact-modal-header">
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onClose} />
                </div>
                <div className="update-address-container">
                    <div tabIndex="-1" className="update-address-item update-contact-title modal-heading" id={readerId}>
                        <TextStyle element={'h2'} alias="HeadingB">
                            Update address
                        </TextStyle>
                    </div>
                    <div className="update-address-item">
                        <Field
                            name="address"
                            id="addressId"
                            component={AutoComplete}
                            type="text"
                            assistiveText={Address.assistiveText}
                            able
                            label="Enter address"
                            formSubmitError={errors.errorCode === NONPHYSICAL_ADDRESS_ERROR}
                            placeholder={Address.placeholder}
                            loadOptions={(props) => getAddressList(props)}
                            selectedValue={addressInput}
                            showTick={false}
                            aria-required="true"
                            onChange={onAddressChange}
                            meta={addressError}
                        />
                    </div>
                    <div className="update-address-item">
                        <SpinningButton
                            onSubmit={onClickContinue}
                            buttonText="Continue"
                            isDisabled={isMobileUpdateInProgress || verificationInProgress}
                            isLoading={isMobileUpdateInProgress || verificationInProgress}
                        />
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
};

export default connect(
    (state) => ({
        isMobileUpdated: getIsMobileUpdated(state),
        isMobileUpdateInProgress: getIsMobileUpdateInProgress(state),
        verificationData: getAddressVerificationData(state),
        errors: getErrors(state),
        verificationInProgress: getVerificationInProgress(state)
    }),
    {
        updateContactMediumAction: updateContactMedium,
        updateAddressSuccessAction: updateAddressSuccess,
        getAddressAction: getAddress,
        resetErrors,
        verifyAddressUpdateContactDetailsAction: verifyAddressUpdateContactDetails
    }
)(UpdateAddress);

import React from 'react';
import { nextPage } from '../../adapters/utils';
import Parser from 'html-react-parser';
import './message.css';
import { isEmpty } from '../../adapters/validations';

const Message = ({ message = '', type = 'warning', fullPageError = false, fullPageButtonText = 'Next', customButton, children }) => {
    let icon;

    switch (type) {
        case 'warning':
            icon = 'icon-alert-round';
            break;
        case 'info':
            icon = 'icon-information';
            break;
        default:
            icon = 'icon-alert-round';
            break;
    }

    const showCustomButton = !isEmpty(customButton);
    if (fullPageError) {
        return (
            <div className={`ppa-full-page-message ${type} full-page-error`}>
                <div className="icon-wrapper">
                    <i className={`td-icon-lg ${icon}`} />
                </div>
                <div className="message-box">{children}</div>
                <div className="button-box">
                    {!showCustomButton && (
                        <button className="btn primary error-button" onClick={() => nextPage()}>
                            {fullPageButtonText}
                        </button>
                    )}
                    {showCustomButton && customButton}
                </div>
            </div>
        );
    }

    return (
        <div className={`ppa-message ${type}`}>
            <div className="icon-wrapper">
                <i className={`td-icon-sm ${icon}`} />
            </div>
            <div className="message-box">{Parser(message)}</div>
        </div>
    );
};

export default Message;

import React from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';

// Element setting not needed in testing mode
if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#ppa');
}
const CancelModal = ({ toggleModal, modalOpen, ...props }) => {
    const redirectToHomePage = () => props.history.go('/');
    const { cancelActivation: { cancelModalConfig = {} } = {} } = props.locale || {};
    return (
        <Modal isOpen={modalOpen} contentLabel="Cancel Activation Modal" onRequestClose={toggleModal} className="modal-dialog modal-lg" role="dialog">
            <div className="modal-content">
                <div className="modal-header-no-border">
                    <button className="close" aria-label="Close dialog" onClick={toggleModal} />
                    <h1 className="modal-title grey sub-heading" tabIndex="-1">
                        {cancelModalConfig.titleText}
                    </h1>
                </div>
                <div className="modal-body">
                    <p className="negative-msg">{cancelModalConfig.descriptionText}</p>
                    <p className="form-group">
                        <div className="btn-input-control-service">
                            <button className="btn primary cancel-activation" onClick={redirectToHomePage}>
                                {cancelModalConfig.cancelBtnText}
                            </button>
                        </div>
                        <div className="btn-input-control-service">
                            <button className="button-as-link" onClick={toggleModal}>
                                {cancelModalConfig.continueText}
                            </button>
                        </div>
                    </p>
                </div>
            </div>
        </Modal>
    );
};
export default withRouter(CancelModal);

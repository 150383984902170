import './youriddetails.css';
import * as actionCreators from './module';
import View from './presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateDocumentDetails, validateCustomerDetails, validatePortingDetails } from '../app/actions';
import { updateStepperCustomData } from '../../components/Progress/module';

export function mapStateToProps(state) {
    return {
        appData: state.app.appData,
        loading: state.yourIdDetails.loading,
        locale: state.yourIdDetails.locale,
        bannerImage: state.yourIdDetails.bannerImage,
        errors: state.yourIdDetails.errors,
        loggedInCustomerData: state.app.loggedInCustomerData,
        hasErrorOccurred: state.app.hasErrorOccurred,
        customerDataFetchSuccess: state.app.customerDataFetchSuccess
    };
}
export function mapDispatchToProps(dispatch) {
    const combineActions = Object.assign({}, actionCreators, { validateDocumentDetails, validateCustomerDetails, validatePortingDetails, updateStepperCustomData });
    return { actions: bindActionCreators(combineActions, dispatch) };
}

const yourIdDetails = connect(mapStateToProps, mapDispatchToProps)(View);

export default yourIdDetails;

import React, { Component } from 'react';
import Modal from 'react-modal';
import './ppvmodal.css';
import TransferNumber from './TransferNumber';

class PPVModal extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: true };
    }
    componentWillMount() {
        const {
            validations: { isSmsCodeVerified }
        } = this.props;
        if (isSmsCodeVerified) {
            this.props.actions.resetPorting();
        }
    }
    componentDidUpdate() {
        const modalHeading = document.querySelector('.modal-title.grey.sub-heading');
        if (modalHeading) {
            modalHeading.focus();
        }
    }
    render() {
        const toggleModal = () => {
            this.setState({ modalOpen: !this.state.modalOpen });
            this.props.actions.triggerPortingModal(false);
        };
        const {
            appData: { uiState: { selectedActivationFlow } = {} }
        } = this.props;

        return (
            <Modal
                parentSelector={() => document.querySelector('#modal-ppv')}
                shouldCloseOnOverlayClick={false}
                isOpen={this.state.modalOpen}
                className="modal-dialog modal-lg modal-sm ppv-modal"
                role="dialog"
                onRequestClose={toggleModal}
            >
                {selectedActivationFlow && selectedActivationFlow === 'portNumber' && <TransferNumber {...this.props} toggleModal={toggleModal} />}
            </Modal>
        );
    }
}

export default PPVModal;

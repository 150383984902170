import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';
import { callingModuleMeta } from '../../adapters/constants';

import { AUTHORISED_REPRESENTATIVE_INIT_DONE, AUTHORISED_REPRESENTATIVE_INIT_ERROR, CUSTOMER_CHECK_ERROR, CUSTOMER_CHECK_DONE, LOCK_STATE, RESET_AUTHORISED_DETAILS_FORM } from '../actions';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false, lockFields: false, formProcessed: false },
    locale: {},
    errors: { hasError: false, errorCode: null, hasServerError: false }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case AUTHORISED_REPRESENTATIVE_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true },
                locale: action.data
            };
        case AUTHORISED_REPRESENTATIVE_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case CUSTOMER_CHECK_ERROR: {
            if (action.callingModule === callingModuleMeta.authorisedRepresentative) {
                const hasServerError = action.errorCode === errorCodeMeta.SERVER_ERROR;
                return {
                    ...state,
                    errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError },
                    loading: { ...state.loading, lockFields: hasServerError }
                };
            }
            return state;
        }
        case CUSTOMER_CHECK_DONE:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true }
            };

        case LOCK_STATE:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false, lockFields: true }
            };

        case RESET_AUTHORISED_DETAILS_FORM:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false }
            };

        default:
            return state;
    }
};

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'authorisedrepresentative';
    return dispatch => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = data => {
    return { type: AUTHORISED_REPRESENTATIVE_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: AUTHORISED_REPRESENTATIVE_INIT_ERROR, data: httpStatus };
}

/**
 * Reset authorised details form
 *
 * @export
 * @returns
 */
export function resetForm() {
    return { type: RESET_AUTHORISED_DETAILS_FORM };
}

/**
 * Lock persisting form state
 *
 * @export
 * @returns
 */

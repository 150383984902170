import React from 'react';
import classNames from 'classnames';
import { TextStyle } from '@able/react';
import './index.css';

export default function Table({ className = '', columns = [], data = [] }) {
    function getTableRow(rowData = {}) {
        const rowCols = columns.map(({ dataKey }) => {
            return (
                <td key={dataKey}>
                    <TextStyle alias="TextBodyShort">
                        {rowData[dataKey]}
                    </TextStyle>
                </td>
            );
        });
        return <tr>{rowCols}</tr>;
    }

    const tHeadCells = columns.map(({ label, dataKey }) => {
        return (
            <th key={dataKey}>
                <TextStyle alias="Label">
                    {label}
                </TextStyle>
            </th>
        );
    });

    return (
        <div className={classNames(['ppa-custom-table', className])}>
            <table>
                <thead>
                    <tr>{tHeadCells}</tr>
                </thead>
                <tbody>{data.map(getTableRow)}</tbody>
            </table>
        </div>
    );
}

import React, { useState, useRef, useEffect } from 'react';
import { TextStyle, TextField, ActionButton, MessageSection, IconButton } from '@able/react';
import Modal from 'react-modal';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import PortingLock from '../../../../assets/img/svg/boost-lock.svg';
import { focusOnModal, focusOnElement, getAbleSpriteSheet } from '../../../../adapters/utils';
import * as errorCodeMeta from '../../../../adapters/errorCode';
import { getOtcError } from '../../utils/utils';
import { FullModalError } from '../fullModalError/fullModalError';
import { addDataLayerEventInfo, createDataLayerPage, updateDataLayerObject } from '../../../../adapters/analytics-utils';

const ableSpriteSheet = getAbleSpriteSheet();

const OtcVerify = ({
    newEmail,
    errorCode,
    onCloseModal,
    onContinue,
    onResendCode,
    hasOtpSent,
    isProcessingOtp,
    isVerifyOTCInProgress,
    otcCounter,
    isUpdating,
    errorPropsOverrides = {}
}) => {
    const [otc, setOtc] = useState('');
    const [otcMeta, setOtcMeta] = useState({ touched: false, validationError: '', apiError: {} });
    const [isResend, setIsResend] = useState(false);
    const modal1 = useRef(null);

    useEffect(() => {
        updateDataLayerObject(createDataLayerPage('Email - Enter your one-time code'));
        modal1 && modal1.current && focusOnModal(modal1, 0);
    }, []);

    const onOtcChange = ({ target: { value } = {} } = {}) => {
        setOtc(value);
        if (otcMeta.touched) {
            const validationError = getOtcError(value);
            const newOtcMeta = { touched: true, validationError, apiError: {} };
            setOtcMeta(newOtcMeta);
        }
    };

    const onVerifyClick = () => {
        const validationError = getOtcError(otc);
        const newOtcMeta = { validationError, touched: true, apiError: {} };
        setOtcMeta(newOtcMeta);
        if (validationError) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', validationError);
            focusOnElement('#otcInput', 0, true);
            return;
        }
        onContinue(otc);
    };

    useEffect(() => {
        if (otcMeta.apiError.errorCode) {
            focusOnElement('#otcInput', 0, true);
            return;
        }
    }, [otcMeta.apiError.errorCode])

    const onBlur = () => {
        const validationError = getOtcError(otc);
        const newOtcMeta = { ...otcMeta, validationError, touched: true };
        setOtcMeta(newOtcMeta);
    };

    const onResendClick = () => {
        setIsResend(true);
        onResendCode();
        const newOtcMeta = { ...otcMeta, apiError: {} };
        addDataLayerEventInfo('click', 'clickTrack', 'linkClick', 'Resend code');
        setOtcMeta(newOtcMeta);
    };

    useEffect(() => {
        let errorProps = {};
        if (errorCode === errorCodeMeta.INVALID_OTC) {
            errorProps = {
                errorHeading: '',
                errorCode,
                errorText: 'Incorrect code. Please check your code and try again.'
            };
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Incorrect code. Please check your code and try again.');
        } else if (errorCode === errorCodeMeta.EXPIRED_OTC) {
            errorProps = {
                errorHeading: '',
                errorCode,
                errorText: 'The code you’ve entered has expired. Request a new one below.'
            };
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'The code you’ve entered has expired. Request a new one below.');
        } else if (errorCode === errorCodeMeta.MAX_ATTEMPTS_OTC) {
            errorProps = {
                errorHeading: 'Unable to process request',
                errorCode,
                errorText: "You've reached the maximum attempts for entering your one-time code. You'll need to wait at least 20 minutes before trying again."
            };
        } else if (errorCode === errorCodeMeta.MAX_ATTEMPTS_SEND_OTC) {
            errorProps = {
                errorHeading: 'Unable to process request',
                errorCode,
                errorText: "You've reached the maximum attempts for requesting your one-time code. You'll need to wait at least 20 minutes before trying again."
            };
        } else if (
            errorCode === errorCodeMeta.UNKNOWN_ERROR ||
            errorCode === errorCodeMeta.SEND_OTC_ERROR
        ) {
            errorProps = {
                errorCode,
                errorHeading: 'Something went wrong',
                errorText: "We're unable to process your request. Please try again later.",
                errorTextLink: 'Try again'
            };
        }
        errorProps = { ...errorProps, ...errorPropsOverrides };
        setOtcMeta({ ...otcMeta, apiError: errorProps });
    }, [errorCode, otcCounter]);

    const renderOtcForm = () => {
        return (
            <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content">

                <div className="modal-header-no-border ppv-header update-contact-modal-header px-5">
                    <img className="porting-lock-image mx-0 mt-4 mb-0" src={PortingLock} aria-hidden="true" alt="" />
                    <IconButton icon="Close" className="modal-close-btn otc-modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onCloseModal} />
                </div>

                <div className="px-5 pt-4">
                    <div aria-live='polite'>
                        {isResend && hasOtpSent && !isProcessingOtp && (
                            <MessageSection
                                className="mb-3"
                                variant="Success"
                                developmentUrl={ableSpriteSheet}
                                description="We’ve resent your one-time code." />
                        )}
                    </div>
                    <div tabIndex="-1" className="modal-heading mb-3" id="enter-otc-heading">
                        <TextStyle tabIndex="-1" element={'h2'} alias="HeadingB">Enter your one-time code</TextStyle>
                        <TextStyle alias="TextBodyShort">
                            {`Please enter the 6-digit code we sent to ${newEmail}`}
                        </TextStyle>
                    </div>
                    <div id="alert-liveRegion">
                        {otcMeta.apiError.errorCode && (
                            <MessageSection
                                variant="Error"
                                developmentUrl={ableSpriteSheet}
                                titleText={otcMeta.apiError.errorHeading}
                                description={otcMeta.apiError.errorText} />
                        )}
                    </div>
                    <TextField
                        id="otcInput"
                        name="otcInput"
                        label="Enter 6 digit code"
                        required
                        className="my-3"
                        defaultValue=""
                        invalidInputText={otcMeta.validationError}
                        invalid={!!(otcMeta.touched && otcMeta.validationError)}
                        developmentUrl={ableSpriteSheet}
                        maxLength={6}
                        value={otc}
                        onChange={onOtcChange}
                        onBlur={onBlur}
                        aria-label={otcMeta.validationError}
                        aria-describedby="alert-liveRegion"
                    />
                    <SpinningButton
                        onSubmit={onVerifyClick}
                        isDisabled={isVerifyOTCInProgress || isUpdating}
                        isLoading={isVerifyOTCInProgress || isUpdating}
                        buttonText="Verify"
                        stylingClass="mb-4 w-100"
                    />
                    <div className="center">
                        <ActionButton
                            element="button"
                            className="update-inline boost-able-button"
                            id="resendBtn"
                            variant="LowEmphasis"
                            label="Resend code"
                            onClick={onResendClick}
                            disabled={isProcessingOtp}
                        />
                    </div>

                </div>
            </div>
        );
    };

    return (
        <Modal
            parentSelector={() => document.querySelector('#modal-ppv')}
            shouldCloseOnOverlayClick={false}
            isOpen
            className="modal-dialog modal-lg ppv-modal modal-sm"
            aria={{ modal: 'true', role: 'dialog' }}
            onRequestClose={onCloseModal}
            shouldCloseOnEsc
            ref={modal1}
        >
            {[
                errorCodeMeta.MAX_ATTEMPTS_OTC,
                errorCodeMeta.MAX_ATTEMPTS_SEND_OTC,
                errorCodeMeta.SEND_OTC_ERROR,
                errorCodeMeta.UNKNOWN_ERROR
            ].includes(otcMeta.apiError.errorCode)
                ? <FullModalError errorContent={otcMeta.apiError} onCloseModal={onCloseModal} />
                : renderOtcForm()}
        </Modal>
    );
};

export default OtcVerify;

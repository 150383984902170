import {
    ADDRESS_VERIFICATION_REQUIRED,
    ADDRESS_VERIFICATION_REQUIRED_STATUS,
    ADDRESS_VERIFICATION_SUCCESS,
    ADDRESS_VERIFICATION_FAILURE,
    ADDRESS_LOOKUP_FAILURE,
    APP_INIT_DONE,
    CUSTOMER_CHECK_DONE,
    CUSTOMER_CHECK_ERROR,
    CUSTOMER_DATA_FETCH_SUCCESS,
    CUSTOMER_STATE_CHECK_COMPLETE,
    DOCUMENT_VALIDATION_ERROR,
    DOCUMENT_VALIDATION_SUCCESS,
    FORM_FIELD_CHANGE,
    CART_SUBMISSION_ERROR,
    NUMBER_CHECK_DONE,
    NUMBER_CHECK_ERROR,
    PORTING_VALIDATION_ERROR,
    PORTING_VALIDATION_SUCCESS,
    REQUEST_COMPLETE,
    REQUEST_IN_PROGRESS,
    SERVICE_ACTIVATION_ERROR,
    STORE_USER_ACCESS_TOKEN,
    TOGGLE_DEBUGGER,
    CUSTOMER_DATA_FETCH_ERROR,
    RECHECK_AUTHENTICATION,
    LOGIN_CHECK_STATUS_UPDATE,
    EXISTING_CUSTOMER_FETCH_ERROR,
    EXISTING_CUSTOMER_FETCH_SUCCESS,
    CREATE_SHOPPING_CART_SUCCESS,
    CREATE_SHOPPING_CART_ERROR,
    UPDATE_SHOPPING_CART_INIT,
    UPDATE_SHOPPING_CART_ERROR,
    UPDATE_SHOPPING_CART_SUCCESS,
    CART_SUBMISSION_SUCCESS,
    NETWORK_CONNECTION_STATUS,
    STORE_LANDING_ADDRESS,
    OFFER_DETAILS_INIT_DONE,
    CART_SUBMISSION_INIT,
    PORTING_CHECK_INIT,
    STORE_USER_LOA,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_MOBILE_SUCCESS,
    PAYMENT_INIT_ERROR,
    UPDATE_CUSTOMER_BUSINESS_DATA,
    VERIFY_BUSINESS_ADDRESS_ERROR,
    UPDATE_BIZ_CONTACT_MEDIUM_ERROR,
    CREATE_BUSINESS_ACCOUNT_ERROR,
    ABN_ACN_VALIDATION_REQUEST_ERROR,
    ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED,
    ADD_CUSTOMER_BUSINESS,
    SET_FULL_PAGE_ERROR_APP_FLAG
} from '../actions';
import esimActionHandler from './esim-module';
import * as errorCodeMeta from '../../adapters/errorCode';
import {
    BAD_REQUEST,
    MAX_RETRY_FAILURE,
    SERVER_ERROR,
    FATAL_ERROR,
    FRAUD_DECLINED_ERROR,
    UPDATE_CART_FATAL_ERROR,
    CREATE_CART_FATAL_ERROR,
    SERVICE_ERROR,
    NETWORK_FAILURE,
    PAYMENT_UNEXPECTED_ERROR
} from '../../adapters/errorCode';
import { STORE_STATE_AND_LOGIN, RESET_PERSISTOR, BILLPAY_PAYMENT_SUCCESS, PAYPAL_PAYMENT_SUCCESS, VISACHECKOUT_PAYMENT_SUCCESS, callingModuleMeta, PAYMENT_ERROR, BILLPAY_INIT_ERROR, STORE_STATE_AND_LOGIN_MFA, userProfileInfoMeta } from '../../adapters/constants';
import { notifyAnalytics, requestCodeMeta, addMultiDataLayerUser, addDataLayerUser } from '../../adapters/analytics-utils';
import { isPaymentAvailable } from '../../adapters/utils';
import { applicationFlow } from '../../adapters/findNextRoute';
import { isEmpty } from '../../adapters/validations';
import WELCOME_LOCALE from '../../assets/locale/v1/welcome';

const totalSteps = applicationFlow.length;
// REDUCER
const initialState = {
    loading: true,
    isAppLoaded: false,
    loggedInCustomerData: {}, // data received from service call if user is logged in,
    loginCheckInProgress: false,
    hasErrorOccurred: false,
    hasServiceError: false,
    customerDataFetchSuccess: false,
    appData: {
        landingLocation: null,
        imsi: null,
        msisdn: null,
        simserial: null,
        other: null,
        channel: 'default',
        telstraAuthCustomerProfile: {},
        userAccessToken: null,
        isOdaFlow: false,
        odaInput: null,
        loading: { activationInProgress: false },
        skipRecharge: null,
        levelOfAuthentication: null,
        esimData: {
            eid: null,
            iccid: null,
            transactionId: null,
            offer: { selectedOffer: null },
            isEligibleForFreeOffer: false,
            hasRequestMsisdnError: false,
            hasCompletedEsimMsisdnRequest: false,
            hasCompletedEsimProfileDownload: false,
            hasEsimProfileError: false,
            isProcessingProfile: false,
            eligibilityErrors: { hasError: false, errorCode: null, hasServerError: false },
            deepLinks: {
                download: null,
                error: null
            }
        },
        uiState: {
            isEsimActivation: false,
            requestInProgress: false,
            requestPath: null,
            loggedIn: false,
            selectedActivationFlow: null,
            selectedDevice: null,
            selectedCurrentPlan: null,
            selectedCustomerType: 'personal',
            selectedIdentityType: null,
            stateOfIssue: 'NSW',
            appLock: false,
            customerValidationCallingModule: null,
            isDebuggerOpen: false,
            isRechargeSuccessful: false,
            persistState: false,
            stateRestored: false,
            lastRoute: null,
            isAemLogin: false,
            hasNetworkAccess: false,
            isMfafromEdit: false,
            editingField: null,
            isCaimanLogin: false,
            isEsimAuth: false
        },
        serviceNumber: { starterCredit: null, simProfile: '3GMF668', packageData: {}, failedAttempts: 0, isRechargeAllowed: true },
        progressBar: { totalSteps, currentStep: 1 },
        customer: { found: false, data: {}, dvsCheckRequired: true, failedAttempts: 0 },
        documentValidation: { valid: false, data: {}, failedAttempts: 0 },
        porting: { valid: false, data: {}, failedAttempts: 0, portingInProgress: false },
        address: { verified: false, postCode: null, addressData: {}, verificationData: [], verificationOptions: [] },
        telstraId: { hasExistingTelstraId: false, telstraIdData: {} }
    }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case REQUEST_IN_PROGRESS:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, requestInProgress: true, requestPath: action.path }
                }
            });
        case STORE_STATE_AND_LOGIN:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, persistState: true, lastRoute: action.lastRoute, isAemLogin: action.isAemLogin, isCaimanLogin: true }
                }
            });

        case STORE_STATE_AND_LOGIN_MFA:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: {
                        ...state.appData.uiState,
                        persistState: true,
                        lastRoute: action.lastRoute,
                        isAemLogin: action.isAemLogin,
                        isCaimanLogin: true,
                        isMfafromEdit: action.isMfaFromEdit,
                        editingField: action.editingField
                    }
                }
            });

        case RESET_PERSISTOR:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, persistState: false }
                }
            });

        case REQUEST_COMPLETE:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, requestInProgress: false, requestPath: action.path }
                }
            });
        case APP_INIT_DONE:
            return Object.assign({}, state, {
                isAppLoaded: true,
                appData: {
                    ...state.appData,
                    imsi: action.appData.imsi,
                    msisdn: action.appData.msisdn,
                    simserial: action.appData.simserial,
                    other: action.appData.other,
                    channel: action.appData.channel,
                    odaInput: action.appData.odaInput,
                    isOdaFlow: action.appData.isOdaFlow,
                    esimData: { ...state.appData.esimData, ...action.appData.esimData },
                    uiState: { ...state.appData.uiState, isEsimActivation: action.isEsimActivation, isEsimAuth: action.isEsimAuth }
                },
                sessionToken: action.sessionToken
            });

        // stores the telstra auth customer profile
        case CUSTOMER_STATE_CHECK_COMPLETE: {
            const {
                appData: {
                    uiState: { persistState }
                }
            } = state;
            return Object.assign({}, state, {
                loading: false,
                isAppLoaded: !(persistState && action.isCustomerValid), // only udpate the app laoder if the state is fresh
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, loggedIn: action.isCustomerValid },
                    telstraAuthCustomerProfile: action.telstraAuthCustomerProfile
                }
            });
        }

        // Initiate authentication recheck when state has to be persisted
        case RECHECK_AUTHENTICATION:
            return Object.assign({}, state, {
                isAppLoaded: false,
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, loggedIn: false, stateRestored: true },
                    telstraAuthCustomerProfile: {}
                }
            });

        case LOGIN_CHECK_STATUS_UPDATE:
            return Object.assign({}, state, { loginCheckInProgress: action.loginCheckInProgress });

        // Toogle application debugger state.
        case TOGGLE_DEBUGGER: {
            const { appData: { uiState: { isDebuggerOpen = false } = {} } = {} } = state;
            return Object.assign({}, state, {
                appData: { ...state.appData, uiState: { ...state.appData.uiState, isDebuggerOpen: !isDebuggerOpen } }
            });
        }
        case CART_SUBMISSION_INIT: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    loading: { ...state.appData.loading, activationInProgress: true }
                }
            });
        }
        case CART_SUBMISSION_ERROR:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    loading: { ...state.appData.loading, activationInProgress: false },
                    uiState: {
                        ...state.appData.uiState,
                        appLock: true,
                        errors: {
                            ...state.appData.uiState.errors,
                            hasError: true,
                            errorCode: action.errorCode,
                            PaymentId: action.PaymentId
                        }
                    }
                },
                hasErrorOccurred: true
            });

        case PAYPAL_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                appData: { ...state.appData, uiState: { ...state.appData.uiState, isRechargeSuccessful: true }, braintreeDetails: { braintreeTransactionId: action.data } }
            });
        case VISACHECKOUT_PAYMENT_SUCCESS:
        case BILLPAY_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                appData: { ...state.appData, uiState: { ...state.appData.uiState, isRechargeSuccessful: true }, braintreeDetails: { braintreeTransactionId: action.data } }
            });

        // stores the telstra auth user token to fetch data
        // from get customer call.
        case STORE_USER_ACCESS_TOKEN:
            return Object.assign({}, state, { appData: { ...state.appData, userAccessToken: action.token } });

        //stores LOA of the user from initial login
        case STORE_USER_LOA:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    levelOfAuthentication: action.acr
                }
            });

        // store response from sim check service response.
        case NUMBER_CHECK_DONE: {
            const { data: { starterCredit = null, deviceOffers = [] } = {} } = action;
            const parsedStarterCredit = parseInt(starterCredit, 10);
            const isRechargeAllowed = isPaymentAvailable() && parsedStarterCredit === 0;
            const { tabName } = !isEmpty(deviceOffers[0]) ? deviceOffers[0].offers[0] : null;
            return Object.assign({}, state, {
                serviceAuthToken: action.data.token,

                appData: {
                    ...state.appData,
                    msisdn: action.data.msisdn,
                    serviceNumber: {
                        ...state.appData.serviceNumber,
                        autoRecharge: action.data.autoRecharge,
                        starterCredit: parsedStarterCredit,
                        simProfile: action.data.accountProfile,
                        packageData: action.data,
                        isRechargeAllowed
                    },
                    offers: {
                        ...state.appData.offers,
                        offer: action.data.offers,
                        productOffers: action.data.productOfferCodes
                    },
                    uiState: {
                        ...state.appData.uiState,
                        selectedCurrentPlan: tabName
                    }
                }
            });
        }

        case NUMBER_CHECK_ERROR: {
            const { data: { completionDate = null } = {} } = action;
            const {
                serviceNumber: { failedAttempts }
            } = state.appData;
            return Object.assign({}, state, {
                hasErrorOccurred: failedAttempts + 1 >= WELCOME_LOCALE.allowedAttempts ||
                    action.errorCode === SERVER_ERROR ||
                    action.errorCode === BAD_REQUEST ||
                    action.errorCode === NETWORK_FAILURE ||
                    action.errorCode === FATAL_ERROR,
                appData: {
                    ...state.appData,
                    serviceNumber: { ...state.appData.serviceNumber, failedAttempts: failedAttempts + 1, completionDate },
                    uiState: { ...state.appData.uiState }
                }
            });
        }

        case PAYMENT_ERROR: {
            return Object.assign({}, state, {
                hasErrorOccurred: action.errorCode === FATAL_ERROR || action.errorCode === NETWORK_FAILURE,
                appData: {
                    ...state.appData,
                    loading: { ...state.appData.loading, activationInProgress: false }
                }
            });
        }

        case BILLPAY_INIT_ERROR: {
            return Object.assign({}, state, {
                hasErrorOccurred: true
            });
        }

        case PAYMENT_INIT_ERROR: {
            return Object.assign({}, state, {
                hasErrorOccurred: true
            });
        }

        case CUSTOMER_CHECK_DONE: {
            const { servicecount } = action.data;
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    customer: { found: true, dvsCheckRequired: action.data.needsDVSCheck, data: action.data },
                    customerInfo: {
                        ...state.appData.customerInfo,
                        customerAccountUUID: action.data.customerAccountUUID,
                        customerAccountCode: action.data.customerAccountCode,
                        identityProfileId: action.data.b2cid,
                        contactId: action.data.contactId,
                        businessAccountNumber: action.data.accountNumber,
                        serviceCount: servicecount
                    }
                }
            });
        }

        case CUSTOMER_DATA_FETCH_SUCCESS: {
            const {
                needsDVSCheck = true,
                dobEditable = false,
                personalDetailsEditable = false,
                customerFound = false,
                token,
                address,
                addressId,
                adborAddressId,
                addressline1,
                addressline2,
                addressline3,
                localityName,
                state: customerState,
                postcode,
                phoneNumber,
                contactEmail,
                servicecount,
                contactDetails = []
            } = action.customerData;

            //business details alphabetical sorting
            const { businessDetails = [] } = action.customerData;
            action.customerData.businessDetails = businessDetails.sort((a, b) => {
                const aAccountName = a.accountName.trim();
                const bAccountName = b.accountName.trim();
                return aAccountName > bAccountName ? 1 : -1;
            });

            return {
                ...state,
                // PPAI-1: switch the app loader flag
                // this stop the laoder spinning and land on
                // the next route when flow is resumed
                isAppLoaded: true,
                loading: false,
                loginCheckInProgress: false,
                serviceAuthToken: token,
                loggedInCustomerData: action.customerData,
                customerDataFetchSuccess: true,
                appData: {
                    ...state.appData,
                    customer: {
                        ...state.appData.customer,
                        found: customerFound,
                        dvsCheckRequired: needsDVSCheck,
                        data: {
                            ...state.appData.customer.data,
                            contactDetails,
                            phoneNumber,
                            contactEmail,
                            dobEditable,
                            personalDetailsEditable
                        }
                    },
                    address: {
                        ...state.appData.address,
                        addressData: { address, addressId },
                        adborAddressId,
                        verified: true,
                        addressline1,
                        addressline2,
                        addressline3,
                        localityName,
                        customerState,
                        postcode
                    },
                    customerInfo: {
                        ...state.appData.customerInfo,
                        customerAccountUUID: action.customerData.customerAccountUUID,
                        customerAccountCode: action.customerData.customerAccountCode,
                        contactId: action.customerData.contactId,
                        contactEmail,
                        phoneNumber,
                        serviceCount: servicecount
                    }
                }
            };
        }
        case CUSTOMER_DATA_FETCH_ERROR: {
            const { error } = action;
            return Object.assign({}, state, {
                // PPAI-1: switch the flags to loading complete
                // if the customer data fetch fails and resume the
                // un-authenticated flow.
                loading: false,
                isAppLoaded: true,
                loginCheckInProgress: false,
                hasServiceError: error === SERVICE_ERROR
            });
        }
        case CUSTOMER_CHECK_ERROR: {
            const {
                customer: { failedAttempts },
                uiState: { customerValidationCallingModule }
            } = state.appData;
            // general scenario for server error
            const appLock = failedAttempts + 1 >= 3
                || action.errorCode === SERVER_ERROR
                || action.errorCode === NETWORK_FAILURE
                || action.errorCode === BAD_REQUEST
                || action.errorCode === 'SFCC-FATAL-422'
                || (action.errorCode && action.errorCode.indexOf('SFCC-FATAL-') !== -1)
                || action.errorCode === 'SFADUP-FATAL-422';
            const incrementFailedAttempts = customerValidationCallingModule === callingModuleMeta.authorisedRepresentative;
            if (incrementFailedAttempts && failedAttempts + 1 >= 3) {
                notifyAnalytics(requestCodeMeta.authoriedRepresntativeMaxRetry, MAX_RETRY_FAILURE);
            }
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    customer: { ...state.appData.customer, failedAttempts: incrementFailedAttempts ? failedAttempts + 1 : failedAttempts },
                    uiState: { ...state.appData.uiState, appLock }
                },
                hasErrorOccurred: action.errorCode === FATAL_ERROR
                    || action.errorCode === 'SFCC-FATAL-422'
                    || (action.errorCode && action.errorCode.indexOf('SFCC-FATAL-') !== -1)
                    || action.errorCode === 'SFADUP-FATAL-422',
                hasServiceError: action.errorCode === SERVICE_ERROR
            });
        }

        case ADDRESS_VERIFICATION_SUCCESS: {
            const verificationData = action.data[0] || {};
            const { fullAddress: { localityDetails: { postCode = '' } = {} } = {} } = verificationData || {};
            return Object.assign({}, state, {
                appData: { ...state.appData, address: { ...state.appData.address, verified: true, verificationData, postCode } }
            });
        }

        // store verification options array for further mapping purpose.
        case ADDRESS_VERIFICATION_REQUIRED:
            return Object.assign({}, state, {
                appData: { ...state.appData, address: { ...state.appData.address, verificationOptions: action.data } }
            });

        case ADDRESS_VERIFICATION_REQUIRED_STATUS: {
            let { verificationOptions } = state.appData.address;
            verificationOptions = action.verificationStatus ? verificationOptions : [];
            return Object.assign({}, state, {
                appData: { ...state.appData, address: { ...state.appData.address, verified: action.verificationStatus, verificationOptions } }
            });
        }
        case DOCUMENT_VALIDATION_SUCCESS:
            let loggedInCustomerData = state.loggedInCustomerData;
            const loggedIn = state.appData.uiState.loggedIn;
            if (loggedIn && action.submittedData && state.appData.customer.dvsCheckRequired) {
                const submittedData = action.submittedData.data;
                loggedInCustomerData = {
                    ...loggedInCustomerData,
                    familyNames: submittedData.familyName,
                    givenNames: submittedData.givenName,
                    middleNames: submittedData.middleName,
                    dob: [submittedData.dobYear, submittedData.dobMonth, submittedData.dobDay].join('-')
                };
            }
            return Object.assign({}, state, {
                appData: { ...state.appData, documentValidation: { valid: true, data: action.data } },
                loggedInCustomerData
            });

        case DOCUMENT_VALIDATION_ERROR: {
            const { failedAttempts } = state.appData.documentValidation;
            return Object.assign({}, state, {
                hasErrorOccurred: failedAttempts + 1 >= 3 || action.errorCode === SERVER_ERROR || action.errorCode === NETWORK_FAILURE,
                appData: {
                    ...state.appData,
                    documentValidation: { ...state.appData.documentValidation, failedAttempts: failedAttempts + 1 },
                    uiState: { ...state.appData.uiState, appLock: failedAttempts + 1 >= 3 || action.errorCode === SERVER_ERROR }
                }
            });
        }

        case PORTING_CHECK_INIT:
            return Object.assign({}, state, {
                appData: { ...state.appData, porting: { ...state.appData.porting, portingInProgress: true } }
            });

        case PORTING_VALIDATION_SUCCESS:
            return Object.assign({}, state, {
                appData: { ...state.appData, porting: { valid: true, data: action.data, portingInProgress: false } }
            });

        case PORTING_VALIDATION_ERROR: {
            const { failedAttempts } = state.appData.porting;
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    porting: { ...state.appData.porting, failedAttempts: failedAttempts + 1, portingInProgress: false },
                    uiState: { ...state.appData.uiState, appLock: failedAttempts + 1 >= 3 || action.errorCode === SERVER_ERROR }
                }
            });
        }

        case EXISTING_CUSTOMER_FETCH_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    telstraId: { ...state.appData.telstraId, hasExistingTelstraId: false }
                }
            });
        }

        case SERVICE_ACTIVATION_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: action.errorCode === SERVER_ERROR }
                }
            });
        }

        case EXISTING_CUSTOMER_FETCH_SUCCESS:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    telstraId: { ...state.appData.telstraId, hasExistingTelstraId: true, telstraIdData: action.data }
                }
            });

        case CREATE_SHOPPING_CART_SUCCESS:
            const { userProfileType = '' } = action.data;
            const { customerState } = state.appData.address;
            if (customerState) addDataLayerUser({ address: { stateProvince: customerState } });
            if(userProfileType){
                addMultiDataLayerUser([{ type: userProfileInfoMeta.USER_PROFILE_TYPE, value: userProfileType }]);
            }
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    shoppingCart: { ...state.appData.shoppingCart, shoppingCartId: action.data.id, userProfileType }
                }
            });
        case ADDRESS_VERIFICATION_FAILURE:
        case ADDRESS_LOOKUP_FAILURE:
        case CREATE_SHOPPING_CART_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    shoppingCart: { ...state.appData.shoppingCart, hasExistingTelstraId: false },
                    uiState: { ...state.appData.uiState, appLock: true }
                },
                hasErrorOccurred: action.errorCode === SERVER_ERROR || action.errorCode === CREATE_CART_FATAL_ERROR || action.errorCode === NETWORK_FAILURE
            });
        }
        case UPDATE_SHOPPING_CART_INIT:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    skipRecharge: action.skipRecharge
                }
            });
        case UPDATE_SHOPPING_CART_SUCCESS:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    updateCart: { ...state.appData.updateCart, data: action.data }
                }
            });

        case UPDATE_SHOPPING_CART_ERROR:
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    updateCart: { ...state.appData.updateCart, hasExistingTelstraId: false },
                    uiState: {
                        ...state.appData.uiState,
                        appLock: true,
                        errors: {
                            ...state.appData.uiState.errors,
                            hasError: true,
                            errorCode: action.errorCode
                        }
                    }
                },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: true },
                hasErrorOccurred: action.errorCode === FRAUD_DECLINED_ERROR || action.errorCode === UPDATE_CART_FATAL_ERROR || action.errorCode === NETWORK_FAILURE
            });
        case CART_SUBMISSION_SUCCESS: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    msisdn: action.data.msisdn || state.appData.msisdn,
                    loading: { ...state.appData.loading, formProcessed: true, activationInProgress: false },
                    activationData: { ...state.appData.activationData, activationDataDetails: { customerInteractionId: action.data.id, orderId: action.data.externalId } }
                }
            });
        }

        case NETWORK_CONNECTION_STATUS: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, hasNetworkAccess: action.status }
                }
            });
        }

        case STORE_LANDING_ADDRESS: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    landingLocation: action.location
                }
            });
        }

        case OFFER_DETAILS_INIT_DONE: {
            return Object.assign({}, state, {
                appData: { ...state.appData, uiState: { ...state.appData.uiState, customerValidationCallingModule: null } }
            });
        }
        // FORM FIELD CHANGE ACTIONS
        case FORM_FIELD_CHANGE: {
            const {
                meta: { form, field },
                payload
            } = action;

            if (form === 'simValidationForm' && field === 'selectedDevice') {
                let {
                    appData: {
                        uiState: { selectedActivationFlow }
                    }
                } = state;
                if (payload !== 'Prepaid-Mobile-3G-Handset' && (selectedActivationFlow !== 'portNumber' || isEmpty(selectedActivationFlow))) {
                    selectedActivationFlow = 'providedNumber';
                }
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: {
                            ...state.appData.uiState,
                            selectedDevice: payload,
                            selectedActivationFlow
                        }
                    }
                });
            } else if (form === 'simValidationForm' && field === 'selectedActivationFlow') {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, selectedActivationFlow: payload }
                    }
                });
            } else if (form === 'customerDetailsForm' && field === 'customerType') {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, selectedCustomerType: payload }
                    }
                });
            } else if (form === 'identityDetailsForm' && field === 'customerType') {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, selectedCustomerType: payload }
                    }
                });
            } else if (form === 'identityDetailsForm' && field === 'identityType') {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, selectedIdentityType: payload }
                    }
                });
            } else if (form === 'drivingLicenseForm' && field === 'stateOfIssue') {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, stateOfIssue: payload }
                    }
                });
            } else if ((form === 'addressDetailsForm' || form === 'yourContactDetailsForm') && field === 'address') {
                const { label: address = '', value: addressId = '' } = payload || {};
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        address: { ...state.appData.address, addressData: payload ? { address, addressId } : {} }
                    }
                });
            } else if ((form === 'addressDetailsForm' || form === 'yourContactDetailsForm') && field === 'verifyAddress' && payload !== 'addressNotFound') {
                const { verificationOptions } = state.appData.address;
                const verificationData = verificationOptions.filter((address) => address.value === payload);
                const { fullAddress: { localityDetails: { postCode = '' } = {} } = {} } = verificationData[0] || {};
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        address: {
                            ...state.appData.address,
                            verified: true,
                            verificationData,
                            postCode
                        }
                    }
                });
            }
            return state;
        }
        case UPDATE_EMAIL_SUCCESS: {
            const { contactEmail } = action.payload;
            return {
                ...state,
                appData: {
                    ...state.appData,
                    customerInfo: {
                        ...state.appData.customerInfo,
                        contactEmail
                    }
                }
            };
        }
        case UPDATE_ADDRESS_SUCCESS: {
            const {
                addressId,
                addressLine1,
                addressLine2,
                addressLine3,
                addressline1: _addressline1,
                addressline2: _addressline2,
                addressline3: _addressline3,
                postcode,
                state: customerState,
                localityName
            } = action.payload.verificationData;
            const addressline1 = addressLine1 || _addressline1;
            const addressline2 = addressLine2 || _addressline2;
            const addressline3 = addressLine3 || _addressline3;
            return {
                ...state,
                appData: {
                    ...state.appData,
                    address: {
                        ...state.appData.address,
                        addressData: { addressId },
                        addressline1,
                        addressline2,
                        addressline3,
                        adborAddressId: addressId,
                        localityName,
                        customerState,
                        postcode,
                        verificationData: []
                    }

                }
            };
        }
        case UPDATE_MOBILE_SUCCESS: {
            const { phoneNumber } = action.payload;
            if (!phoneNumber) {
                return { ...state };
            }
            return {
                ...state,
                appData: {
                    ...state.appData,
                    customerInfo: {
                        ...state.appData.customerInfo,
                        phoneNumber
                    },
                    customer: {
                        ...state.appData.customer,
                        data: {
                            ...(state.appData.customer.data || {}),
                            phoneNumber
                        }
                    }
                }
            };
        }
        case UPDATE_CUSTOMER_BUSINESS_DATA: {
            const { businessDetails = [] } = state.loggedInCustomerData;
            const { accountNumber = '', data = {} } = action;
            const businessIndex = businessDetails.findIndex(item => item.accountNumber === accountNumber);
            if (businessIndex > -1) {
                businessDetails[businessIndex] = {
                    ...businessDetails[businessIndex],
                    ...data
                };
            }
            return {
                ...state,
                loggedInCustomerData: {
                    ...state.loggedInCustomerData,
                    businessDetails
                }
            };
        }
        case ADD_CUSTOMER_BUSINESS: {
            const { businessDetails = [] } = state.loggedInCustomerData;
            const { data = {} } = action;
            businessDetails.push(data);
            return {
                ...state,
                loggedInCustomerData: {
                    ...state.loggedInCustomerData,
                    businessDetails
                }
            };
        }
        case UPDATE_BIZ_CONTACT_MEDIUM_ERROR: {
            if (action.errorCode === errorCodeMeta.UPDATE_BIZ_CONTACT_MEDIUM_ERROR) {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, appLock: true }
                    },
                    hasErrorOccurred: true
                });
            }
            return state;
        }
        case ABN_ACN_VALIDATION_REQUEST_ERROR: {
            if (action.payload && action.payload.indexOf('SMB-FATAL-') !== -1) {
                return Object.assign({}, state, {
                    appData: {
                        ...state.appData,
                        uiState: { ...state.appData.uiState, appLock: true }
                    },
                    hasErrorOccurred: true
                });
            }
            return state;
        }
        case CREATE_BUSINESS_ACCOUNT_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: true }
                },
                hasErrorOccurred: true
            });
        }
        case ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: true }
                },
                hasErrorOccurred: true
            });
        }
        case SET_FULL_PAGE_ERROR_APP_FLAG: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: true }
                },
                hasErrorOccurred: true
            });
        }
        default:
            return esimActionHandler(state, action);
    }
};

export default reducer;
